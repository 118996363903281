<template>
  <div class="p">
    <!--=============== Navbar ===============-->
    <AdminNav />
    <!--=============== Navbar ===============-->
    <!--=============== Manage Category ===============-->
    <div class="manage">
      <h2>Manage Categories</h2>
      <button class="addBtn" v-on:click="showAddPopup()">Add Category</button>
    </div>
    <!--=============== Manage Category ===============-->
    <!--=============== Add Category ===============-->
    <div id="AddCategory" v-if="showAdd">
      <div class="dispage">
        <div class="AddC">
          <div class="close">
            <button class="exit_button" v-on:click="closeAddPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <form @submit.prevent="AddCategory()" method="post">
              <h3 style="color: #eee">Add Category</h3>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="CategoryNameEN"
                    class="name-input"
                    id="addc-name-en-input"
                    placeholder="Enter an English Category Name"
                  />
                  <br />
                  <label for="name" class="error_msg_1"></label>
                </div>
              </div>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="CategoryNameAR"
                    class="name-input"
                    id="addc-name-ar-input"
                    placeholder="Enter an Arabic Category Name"
                  />
                  <br />
                  <label for="name" class="error_msg_3"></label>
                </div>
              </div>
              <div class="input">
                <div class="showCities" v-on:click="openDropdown()">
                  Select City: {{ City.Name }}
                </div>
                <div class="cities_list" v-if="dropdown">
                  <div
                    class="city"
                    v-for="city in Cities"
                    v-bind:key="city._id"
                    v-on:click="selectCity(city)"
                  >
                    {{ city.Name }}
                  </div>
                </div>
              </div>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="CategoryImage"
                    class="name-input"
                    id="addc-CategoryImage-input"
                    placeholder="Enter a Category Image"
                  />
                  <br />
                  <label for="name" class="error_msg_2"></label>
                </div>
              </div>
              <div class="input">
                <button class="submit">Add a Category</button>
              </div>
              <div class="input" id="success_msg">
                <label for="input" class="success-msg-1"></label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Add Category ===============-->
    <!--=============== Edit Category ===============-->
    <div id="EditCategory" v-if="showEdit">
      <div class="dispage">
        <div class="AddC">
          <div class="close">
            <button class="exit_button" v-on:click="closeEdiPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <form method="post" @submit.prevent="EditCategory()">
              <h3 style="color: #eee">Edit Category</h3>
              <!--============== Category english name ==============-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="CategoryNameEN"
                    autocomplete="off"
                    class="name-input"
                    id="edit-name-en-input"
                    v-model="ECategoryNameEN"
                    placeholder="Enter a new Category enlish name"
                  />
                  <br />
                  <label
                    for="CategoryNameEN"
                    class="edit-name-en-input-label"
                  ></label>
                </div>
              </div>
              <!--============== Category arabic name ==============-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="CategoryNameAR"
                    autocomplete="off"
                    class="name-input"
                    id="edit-name-ar-input"
                    v-model="ECategoryNameAR"
                    placeholder="Enter a new Category arabic name"
                  />
                  <br />
                  <label
                    for="CategoryNameAR"
                    class="edit-name-ar-input-label"
                  ></label>
                </div>
              </div>
              <!--============== Category city ==============-->
              <div class="input">
                <div class="showECities" v-on:click="openEditDropdown()">
                  Select City: {{ ECity.Name }}
                </div>
                <div class="ecities_list" v-if="dropdown2">
                  <div
                    class="city"
                    v-for="city in Cities"
                    v-bind:key="city._id"
                    v-on:click="selectECity(city)"
                  >
                    {{ city.Name }}
                  </div>
                </div>
              </div>
              <!--============== Category Image ==============-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="ECategoryImage"
                    class="name-input"
                    id="editc-CategoryImage-input"
                    placeholder="Enter a Category Image"
                  />
                  <br />
                  <label
                    for="name"
                    class="editc-CategoryImage-input-label"
                  ></label>
                </div>
              </div>
              <!--============== Submit button ==============-->
              <div class="input">
                <button class="submit">Edit Category</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Edit Category ===============-->
    <!--=============== Display Categories ===============-->
    <div class="displayC" id="DisplayCategories">
      <div class="boxes">
        <div
          class="box"
          v-for="category in Categories"
          v-bind:key="category._id"
        >
          <div class="box-content">
            <router-link
              :to="`/${$i18n.locale}/Admin/ManageCategory/${category._id}`"
              class="a category_link"
            >
              <div class="box-icon" v-html="category.Image"></div>
              <span class="num">{{ category.numberOfPlaces }}</span>
              <p class="content">{{ category.NameEN }}</p>
            </router-link>
          </div>
          <button class="editBtn" v-on:click="showEdiPopup(category)">
            <i class="fa-regular fa-pen-to-square"></i>
          </button>
          <button class="deleteBtn" v-on:click="DeleteCategory(category._id)">
            <i class="fa-solid fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <!--=============== Display Categories ===============-->
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import axios from "axios";
import JQuery from "jquery";
import AdminNav from "@/components/AdminNav.vue";
let $ = JQuery;
export default {
  name: "ManageCategories",
  data() {
    return {
      Categories: [],
      showAdd: false,
      showEdit: false,
      dropdown: false,
      Cities: [],
      City: {},
      CategoryNameEN: "",
      CategoryNameAR: "",
      CategoryImage: "",
      isSelectedCity: false,
      dropdown2: false,
      isSelectedECity: false,
      ECategory: {},
      Eid: "",
      ECategoryNameEN: "", // E => for edit
      ECategoryNameAR: "", // E => for edit
      ECategoryImage: "", // E => for edit
      ECity: {},
    };
  },
  components: {
    AdminNav,
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    this.loadCategories();
    this.loadCities();
  },
  methods: {
    async loadCategories() {
      await axios
        .get(ApiUrl.url + "/api/Categories/Get", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.Categories = res.data;
        });
    },
    async loadCities() {
      await axios
        .get(ApiUrl.url + "/api/Cities/Get", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.Cities = res.data;
        });
    },
    showAddPopup() {
      this.showAdd = true;
    },
    closeAddPopup() {
      this.showAdd = false;
    },
    showEdiPopup(category) {
      this.showEdit = true;
      this.getSelectedCategory(category);
    },
    closeEdiPopup() {
      this.showEdit = false;
    },
    getSelectedCategory(Category) {
      this.ECategory = Category;
      axios
        .get(ApiUrl.url + `/api/Categories/Get/${this.ECategory._id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.ECategoryNameEN = res.data.category.NameEN;
          this.ECategoryNameAR = res.data.category.NameAR;
          this.ECategoryImage = res.data.category.Image;
          this.ECity = res.data.city;
          this.isSelectedECity = true;
        });
    },
    openDropdown() {
      if (this.dropdown == false) {
        this.dropdown = true;
      } else {
        this.dropdown = false;
      }
    },
    selectCity(City) {
      this.City = City;
      this.isSelectedCity = true;
      $(".showCities").css("border", "1.5px solid rgba(180, 180, 180, 0.507)");
      this.dropdown = false;
    },
    async AddCategory() {
      $("#addc-name-en-input").click(function () {
        window.onkeyup = function () {
          $("#addc-name-en-input").css("borderBottom", "3px solid #472bff");
          $(".error_msg_1").text("");
        };
      });

      $("#addc-name-ar-input").click(function () {
        window.onkeyup = function () {
          $("#addc-name-ar-input").css("borderBottom", "3px solid #472bff");
          $(".error_msg_3").text("");
        };
      });

      $("#addc-CategoryImage-input").click(function () {
        window.onkeyup = function () {
          $("#addc-CategoryImage-input").css(
            "borderBottom",
            "3px solid #472bff"
          );
          $(".error_msg_2").text("");
        };
      });

      if (
        this.CategoryNameAR == "" &&
        this.CategoryNameEN == "" &&
        this.CategoryImage == ""
      ) {
        $("#addc-name-en-input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".error_msg_1").text("Please Enter an english Category Name");

        $("#addc-name-ar-input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".error_msg_3").text("Please Enter an arabic Category Name");

        $("#addc-CategoryImage-input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".error_msg_2").text("Please Enter an Icon");

        return;
      }

      if (this.CategoryNameEN == "") {
        $("#addc-name-en-input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".error_msg_1").text("Please Enter an english Category Name");
        return;
      }

      if (this.CategoryNameAR == "") {
        $("#addc-name-ar-input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".error_msg_3").text("Please Enter an arabic Category Name");
        return;
      }

      if (this.CategoryImage == "") {
        $("#addc-CategoryImage-input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".error_msg_2").text("Please Enter an Icon");
        return;
      }

      if (this.isSelectedCity == false) {
        $(".showCities").css("border", "1.5px solid rgb(250,82,82)");
        return;
      }

      await axios
        .post(
          ApiUrl.url + "/api/Categories/Add",
          {
            CategoryNameEN: this.CategoryNameEN,
            CategoryNameAR: this.CategoryNameAR,
            CategoryImage: this.CategoryImage,
            CityID: this.City._id,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.msg == "Category Created") {
            $(".success-msg-1").text("Category Created");
            this.loadCategories();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditDropdown() {
      if (this.dropdown2 == false) {
        this.dropdown2 = true;
      } else {
        this.dropdown2 = false;
      }
    },
    selectECity(City) {
      this.ECity = City;
      this.isSelectedECity = true;
      $(".showECities").css("border", "1.5px solid rgba(180, 180, 180, 0.507)");
      this.dropdown2 = false;
    },
    async EditCategory() {
      $("#edit-name-en-input").click(function () {
        window.onkeyup = function () {
          $("#edit-name-en-input").css("borderBottom", "3px solid #472bff");
          $(".edit-name-en-input-label").text("");
        };
      });

      $("#edit-name-en-input").click(function () {
        window.onkeyup = function () {
          $("#edit-name-en-input").css("borderBottom", "3px solid #472bff");
          $(".edit-name-en-input-label").text("");
        };
      });

      $("#editc-CategoryImage-input").click(function () {
        window.onkeyup = function () {
          $("#editc-CategoryImage-input").css(
            "borderBottom",
            "3px solid #472bff"
          );
          $(".editc-CategoryImage-input-label").text("");
        };
      });

      if (
        this.ECategoryNameEN == "" &&
        this.ECategoryNameAR == "" &&
        this.isSelectedECity == false &&
        this.ECategoryImage == ""
      ) {
        $("#edit-name-en-input").css("borderBottom", "3px solid #472bff");
        $(".edit-name-en-input-label").text("Please Enter an english name");

        $("#edit-name-en-input").css("borderBottom", "3px solid #472bff");
        $(".edit-name-en-input-label").text("Please Enter an arabic name");

        $(".showECities").css("border", "1.5px solid rgb(250,82,82)");

        $("#editc-CategoryImage-input").css(
          "borderBottom",
          "3px solid #472bff"
        );
        $(".editc-CategoryImage-input-label").text("Please Enter an Icon");
        return;
      }

      if (this.ECategoryNameEN == "") {
        $("#edit-name-en-input").css("borderBottom", "3px solid #472bff");
        $(".edit-name-en-input-label").text("Please Enter an english name");
        return;
      }

      if (this.ECategoryNameAR == "") {
        $("#edit-name-en-input").css("borderBottom", "3px solid #472bff");
        $(".edit-name-en-input-label").text("Please Enter an arabic name");
        return;
      }

      if (this.isSelectedECity == false) {
        $(".showECities").css("border", "1.5px solid rgb(250,82,82)");
        return;
      }

      if (this.ECategoryImage == "") {
        $("#editc-CategoryImage-input").css(
          "borderBottom",
          "3px solid #472bff"
        );
        $(".editc-CategoryImage-input-label").text("Please Enter an Icon");
        return;
      }

      await axios
        .patch(
          ApiUrl.url + "/api/Categories/Edit",
          {
            id: this.ECategory._id,
            newCategoryNameEN: this.ECategoryNameEN,
            newCategoryNameAR: this.ECategoryNameAR,
            newCategoryImage: this.ECategoryImage,
            CityID: this.ECity._id,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.msg == "Category Updated") {
            this.showEdit = false;
            this.loadCategories();
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
    },
    async DeleteCategory(categoryId) {
      await axios
        .delete(ApiUrl.url + `/api/Categories/Delete/${categoryId}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Category Deleted") {
            $(".success-msg-3").text("Category Deleted");
            this.loadCategories();
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
/************************ Manage Categories ************************/
.manage {
  display: flex;
  justify-content: left;
  align-items: center;
  h2 {
    margin: 3rem;
  }
}
.addBtn {
  width: 120px;
  height: 40px;
  background-color: #472bff;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  margin-right: 1rem;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: #331ec0;
    cursor: pointer;
  }
}
/************************ Manage Categories ************************/
/************************ Add,Edit,Delete Category ************************/
#AddCategory,
#EditCategory,
#DeleteCategory {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 3500;
  transform: translate(-50%, -50%);
}
.dispage {
  width: 100%;
  height: 100vh;
  background-color: #1f283398;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddC {
  width: 500px;
  height: 600px;
  background: #1f2833;
  border-radius: 25px;
}
.close {
  text-align: right;
  width: 100%;
  height: 50px;
}
.exit_button {
  margin: 1rem;
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  color: #eeeeee;
  font-size: 24px;
  transition: all 300ms ease-in-out;
}
.exit_button:hover {
  cursor: pointer;
  color: #472bff;
}
.form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.input {
  padding: 20px;
  div {
    text-align: left;
  }
}
.error_msg_1,
.error_msg_2,
.error_msg_3,
.edit_error_msg {
  color: rgb(250, 82, 82);
}
.name-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  font-size: 15px;
  color: #eeeeee;
  border-bottom: 3px solid #472bff;
}
.selected_item {
  text-align: left;
  color: #eeeeee;
}
.drop {
  width: 365px;
  height: 140px;
  color: #eeeeee;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    width: 100%;
    height: 40px;
    transition: all 300ms ease-in-out;
  }
  .item:hover {
    cursor: pointer;
    color: #472bff;
  }
}
.submit {
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #472bff;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.submit:hover {
  cursor: pointer;
  background-color: #3721c5;
}
.success-msg-1 {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
.country_form {
  width: 335px;
}
.showCities,
.showECities {
  width: 335px;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}
.remove_bottom_border {
  border-bottom: none;
}
.cities_list,
.ecities_list {
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  width: 355px;
  height: 90px;
  overflow: auto;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}
.city {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #eeeeee;
    background-color: #472bff;
  }
}
.city:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
/************************ Add Category ************************/
/************************ Display Categories ************************/
.displayC {
  margin: 3rem;
  text-align: left;
  display: flex;
  justify-content: center;
  h3 {
    margin-left: 2rem;
    margin-bottom: 2rem;
    font-size: 22px;
  }
}
.boxes {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 1rem;
}
.box {
  margin: 1rem;
  width: 350px;
  height: 300px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  transition: all 300ms ease-in-out;
  text-align: center;
}
.active {
  opacity: 1;
}
.box-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  color: #eeeeee;
  background-color: #472bff;
  border-radius: 100px;
  transform: translateY(-30px);
  transition: all 300ms ease-in-out;
  font-size: 42px;
}
.num {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 30px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
  font-weight: 700;
  font-size: 22px;
}
.editBtn,
.deleteBtn {
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  background: none;
  color: #fff;
  background-color: #472bff;
  border-radius: 100px;
  margin: 1rem;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: #472bff;
  }
}
.box:hover {
  color: #eeeeee;
  background-color: #472bff;
  // transform: translateY(-15px);
  .box-icon {
    background-color: #fff;
    color: #472bff;
  }
  .category_link {
    color: #fff;
  }
}
@media screen and (max-width: 1536px) {
  .boxes {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1160px) {
  .boxes {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 786px) {
  .boxes {
    display: grid;
    grid-template-columns: auto;
  }
}
/************************ Display Categories ************************/
</style>
