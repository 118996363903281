<template>
  <div>
    <div class="footer-distributed">
      <div class="footer-left">
        <div>
          <p class="footer-company-name">على وين © 2024</p>
        </div>
      </div>

      <div class="footer-center">
        <div>
          <i class="fa fa-envelope"></i>
          <p>
            <router-link :to="{ name: 'Contact' }">{{
              $t("Footer.ContactUs")
            }}</router-link>
          </p>
        </div>
      </div>

      <div class="footer-right">
        <div class="footer-icons">
          <a href=""><i class="fa fa-facebook"></i></a>
          <a href=""><i class="fa fa-instagram"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterSide",
};
</script>

<style lang="scss" scoped>
.footer-distributed {
  display: flex;
  justify-content: space-around;
  background: #1f2833;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  color: #ffffff;
}
.footer-left {
  display: flex;
  align-items: center;
}
.footer-distributed h3 {
  color: #ffffff;
  font-weight: normal;
  font-size: 32px;
  margin: 0;
}
.footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}
.footer-company-name {
  color: #d8d0d0;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.footer-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}
.footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-center p {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-center p a {
  color: #472bff;
  text-decoration: none;
}
.footer-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: #472bff;
  }
}
</style>
