<template>
  <div class="Plan">
    <div class="nav">
      <NavBar />
    </div>
    <div>
      <div class="title">
        <h1 class="planName">{{ PlanName }}</h1>
      </div>
      <div class="plan_info">
        <div v-if="Data">
          <div
            class="sCats"
            v-for="cat in PlanCategories"
            v-bind:key="Math.random() * 10 * cat.length"
          >
            <div class="rCat" v-if="cat.length != 0">
              <div class="cat_title">
                <h1>
                  {{ cat[0].Category.NameAR }} {{ cat[0].Category.Image }}
                </h1>
              </div>
              <div class="places_in_cat">
                <div
                  v-for="place in cat"
                  v-bind:key="place._id"
                  class="pC"
                  :id="`place-${place._id}`"
                >
                  <div class="placeCat">
                    <div class="place_images">
                      <img
                        :src="`${url}/uploads/${place.Images[0]}`"
                        class="image"
                      />
                      <button
                        class="view-btn"
                        v-on:click="viewPlace(place._id)"
                      >
                        <i class="fa-regular fa-eye"></i>
                      </button>
                    </div>
                    <div class="info">
                      <div class="NameALikes">
                        <div class="Name">
                          {{ place.Name }}
                        </div>
                        <div class="likes">
                          <i class="fa-regular fa-heart"></i> :
                          {{ place.Likes }}
                        </div>
                      </div>
                      <div class="address">
                        {{ place.Address }}
                      </div>
                    </div>
                  </div>
                  <div class="move_btn">
                    <button
                      class="control-btn"
                      v-if="cat.length > 1"
                      v-on:click="nextPlace(place._id, cat)"
                    >
                      <i class="fa-solid fa-right-long"></i>
                    </button>
                    <button
                      class="control-btn"
                      v-if="cat.length > 1"
                      v-on:click="backPlace(place._id, cat)"
                    >
                      <i class="fa-solid fa-left-long"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="noPlaces" v-if="!Data">
        <h2>{{ $t("Plan.NoPlaces") }}</h2>
      </div>
    </div>
    <div class="footer">
      <FooterSide />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import ApiUrl from "@/ApiUrl";
import FooterSide from "@/components/FooterSide.vue";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      url: "",
      PlanCategories: [],
      Plan: {},
      PlanName: "",
      Data: false,
    };
  },
  components: {
    NavBar,
    FooterSide,
  },
  async mounted() {
    this.url = ApiUrl.url;
    let page = document.getElementsByClassName("Plan")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
    }
    await this.loadPlan();
  },
  methods: {
    async loadPlan() {
      await axios
        .get(ApiUrl.url + `/api/Plan/Get/${this.$route.params.id}`)
        .then(async (res) => {
          this.PlanName = res.data.planName;
          this.PlanCategories = await res.data.places;
          this.Data = true;
          let counter = 0;
          for (let i = 0; i < this.PlanCategories.length; i++) {
            if (this.PlanCategories[i].length == 0) {
              counter++;
            }
          }
          if (counter == this.PlanCategories.length) {
            this.Data = false;
          }
        });
    },
    nextPlace(id, places) {
      if (id != places[places.length - 1]._id) {
        let a = id;
        $(`#place-${id}`).fadeOut(0);
        for (let i = 0; i < places.length; i++) {
          if (id == places[i]._id) {
            let j = i + 1;
            if (j < places.length) {
              a = places[j]._id;
            }
            break;
          }
        }
        if (id != a) {
          $(`#place-${a}`).fadeIn(0);
        }
      }
    },
    backPlace(id, places) {
      if (id != places[0]._id) {
        let a = id;
        $(`#place-${id}`).fadeOut(0);
        for (let i = 0; i < places.length; i++) {
          if (id == places[i]._id) {
            let j = i - 1;
            if (j >= 0) {
              a = places[j]._id;
            }
            break;
          }
        }
        if (id != a) {
          $(`#place-${a}`).fadeIn(0);
        }
      }
    },
    viewPlace(id) {
      window.open(
        window.location.origin +
          "/" +
          this.$i18n.locale +
          "/" +
          "place" +
          `/${id}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.Plan {
  direction: rtl;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.nav {
  height: 200px;
}
.planName {
  font-weight: bold;
}
.footer {
  padding-top: 3rem;
  margin-top: auto;
}
.plan_info {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 3rem;
}
.sCats {
  width: 700px;
  border-bottom: 2px solid #bbb;
}
.rCat {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cat_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 600px;
  border-left: 2px solid #bbb;
  h1 {
    font-weight: bold;
  }
}
.places_in_cat {
  width: 480px;
  height: 480px;
  margin-right: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}
.pC {
  background-color: #fff;
  border-radius: 25px;
  height: 100%;
  display: none;
}
.pC:first-child {
  display: block;
}
.control-btn {
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  background-color: rgb(0, 186, 148);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  color: #fff;
  margin: 0.5rem;
  &:hover {
    cursor: pointer;
    background-color: rgb(0, 153, 122);
  }
}
.place_images {
  display: flex;
  height: 360px;
  .image {
    max-width: 480px;
    max-height: 360px;
    border-radius: 25px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.info {
  margin-top: 1rem;
}
.NameALikes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .Name {
    font-weight: bold;
    font-size: 1.5rem;
  }
  .likes {
    font-weight: bold;
    color: #472bff;
    font-size: 1.3rem;
  }
}
.address {
  padding-top: 1rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-weight: bold;
}
.move_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.view-btn {
  width: 30px;
  height: 30px;
  position: relative;
  top: 8%;
  left: 15%;
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 30px;
  height: 30px;
  background-color: rgb(0, 186, 148);
  border-radius: 360px;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: rgb(1, 128, 102);
  }
}
.noPlaces h2 {
  font-weight: bold;
}
</style>
