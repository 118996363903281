<template>
  <div>
    <div class="nav">
      <AdminNav />
    </div>
    <!--=============== Name and Search ===============-->
    <div class="intro">
      <div>
        <h2 class="categoryName">{{ CategoryName }}</h2>
      </div>
      <div class="search_place">
        <input
          type="text"
          placeholder="Search"
          class="search_input"
          name="search"
          v-model="search"
          autocomplete="off"
        />
        <button class="search_btn" v-on:click="SearchPlace()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
    <!--=============== Name and Search ===============-->
    <!--=============== Display Places ===============-->
    <div class="displayP" id="DisplayPlaces">
      <div class="places">
        <div class="place" v-for="place in Places" v-bind:key="place._id">
          <div>
            <div class="place_icon">
              <img
                :src="`${url}/uploads/${place.Images[0]}`"
                v-if="place.Images.length != 0"
              />
            </div>
            <div class="place_name">
              {{ place.Name }} <br />
              <button class="btn1" v-on:click="goToEdit(place._id)">
                Edit <i class="fa-regular fa-pen-to-square"></i>
              </button>
              <button v-on:click="DeletePlace(place._id)">
                Delete <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Display Places ===============-->
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import AdminNav from "@/components/AdminNav.vue";
import axios from "axios";
import i18n from "@/i18n";
export default {
  components: {
    AdminNav,
  },
  data() {
    return {
      CategoryName: "",
      Places: [],
      url: "",
      search: "",
    };
  },
  async mounted() {
    this.url = ApiUrl.url;
    this.loadPlaces();
  },
  methods: {
    async loadPlaces() {
      await axios
        .get(ApiUrl.url + `/api/Places/Get/Category/${this.$route.params.id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.Places = res.data.places;
          this.CategoryName = res.data.category.NameEN;
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
    },
    goToEdit(id) {
      this.$router.push(`/${i18n.locale}/Admin/EditPlace/${id}`);
    },
    async DeletePlace(id) {
      await axios
        .delete(ApiUrl.url + `/api/Places/DeletePlace/${id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "The place is removed") {
            this.loadPlaces();
          }
        });
    },
    SearchPlace() {
      axios
        .post(
          ApiUrl.url + "/Admin/searchPlaceINcategory",
          { SearchQuery: this.search, CategoryName: this.CategoryName },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Places = res.data.Places;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  height: 80px;
}
/************************ Name and Search ************************/
.intro {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2.5rem;
}
.search_place {
  display: flex;
  align-items: center;
}
.search_input {
  width: 365px;
  height: 40px;
  border: none;
  background: none;
  outline: none;
  padding-left: 10px;
  color: #1f2833;
  font-weight: 700;
  background-color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}
.search_input:focus {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}
.search_btn {
  width: 40px;
  height: 40px;
  margin: 1rem;
  background-color: #472bff;
  border: none;
  outline: none;
  border-radius: 10px;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
.search_input::placeholder {
  color: #1f2833;
}
/************************ Name and Search ************************/
/************************ Display Places ************************/
.displayP {
  margin: 3rem;
  text-align: left;
  display: flex;
  justify-content: center;
  h3 {
    margin-left: 2rem;
    margin-bottom: 2rem;
    font-size: 22px;
  }
}
.places {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.place {
  background-color: #1f2833;
  border-radius: 8px;
  width: 300px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}
.place_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  img {
    width: 300px;
    height: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.place_name {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 150px;
  color: #eeeeee;
  button {
    background: none;
    border: none;
    outline: none;
    background-color: #472bff;
    border-radius: 8px;
    padding: 10px;
    color: #eeeeee;
    font-size: 15px;
    width: 85px;
    font-weight: 500;
  }
  button:hover {
    cursor: pointer;
  }
}
@media screen and (max-width: 1420px) {
  .places {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1080px) {
  .places {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 700px) {
  .places {
    display: grid;
    grid-template-columns: auto;
  }
}
/************************ Display Places ************************/
</style>
