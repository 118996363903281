import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import ApiUrl from "@/ApiUrl";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || null,
    isAdmin: localStorage.getItem("#5s12") || null,
    Categories: [],
    Places: [],
  },
  getters: {
    isLoggedIn(state) {
      return state.token !== null;
    },
    isAdmin(state) {
      return state.isAdmin !== null;
    },
    getCategories(state) {
      return state.Categories;
    },
    getPlaces(state) {
      return state.Places;
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    admin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    getC(state, Categories) {
      state.Categories = Categories;
    },
    getP(state, Places) {
      state.Places = Places;
    },
  },
  actions: {
    Login(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(ApiUrl.url + "/api/Auth/Login", {
            email: data.email,
            password: data.password,
          })
          .then((res) => {
            const token = res.data.token;
            if (res.data.msg == "Admin LoggedIn") {
              localStorage.setItem("#5s12", true);
              context.commit("admin", true);
            }
            if (res.data.token != undefined) {
              localStorage.setItem("token", token);
              context.commit("login", token);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCategories(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(ApiUrl.url + "/api/Categories/Get", {
            headers: { token: localStorage.getItem("token") },
          })
          .then((res) => {
            context.commit("getC", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPlaces(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(ApiUrl.url + "/api/Places/Get", {
            headers: { token: localStorage.getItem("token") },
          })
          .then((res) => {
            context.commit("getP", res.data.Places);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    isUserLoggedIn() {
      return new Promise((resolve, reject) => {
        axios
          .get(ApiUrl.url + "/api/Auth/isUserLoggedIn", {
            headers: { token: localStorage.getItem("token") },
          })
          .then((res) => {
            if (res.data.msg == "Token expired") {
              localStorage.clear();
              location.replace("/");
              resolve(res);
            } else if (res.data.msg == "LoggedIn") {
              resolve(res);
            } else {
              localStorage.clear();
              location.replace("/");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    isAdminLoggedIn() {
      return new Promise((resolve, reject) => {
        axios
          .get(ApiUrl.url + "/api/Auth/isAdminLoggedIn", {
            headers: { token: localStorage.getItem("token") },
          })
          .then((res) => {
            if (res.data.msg == "Token expired") {
              localStorage.clear();
              location.replace("/");
              resolve(res);
            } else if (res.data.msg == "LoggedIn") {
              resolve(res);
            } else {
              localStorage.clear();
              location.replace("/");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    Logout() {
      localStorage.clear();
      location.replace("/");
    },
  },
  modules: {},
});
