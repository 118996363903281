<template>
  <div class="p">
    <!--=============== Navbar ===============-->
    <div class="nav">
      <NavBar />
    </div>
    <!--=============== Navbar ===============-->
    <!--=============== Place ===============-->
    <div class="place">
      <div class="image_slider">
        <img
          :src="`${url}/uploads/${current}`"
          class="place_image"
          v-if="Images.length != 0"
        />
      </div>
    </div>
    <div class="dots">
      <div
        class="dot"
        v-for="image in Images"
        v-bind:key="image"
        v-on:click="move(image)"
      ></div>
    </div>
    <div class="place_info">
      <div class="NameLikes">
        <div class="name_description">
          <h1 class="placeName">{{ PlaceName }}</h1>
          <p class="placeDescription">
            {{ Description }}
          </p>
        </div>
        <div class="linkBtn_likes">
          <div v-if="isLoggedIn" class="L">
            <button
              class="like_btn"
              v-on:click="likePlace()"
              v-if="!AlreadyLiked"
            >
              <i class="fa-regular fa-heart"></i>
            </button>
            <button
              class="like_btn"
              v-on:click="UnLikePlace()"
              v-if="AlreadyLiked"
            >
              <i class="fa-solid fa-heart"></i>
            </button>
          </div>
          <div class="Likes">
            <!-- <span><i class="fa-regular fa-heart"></i> </span> -->
            <span>{{ $t("Place.Likes") }}:</span>
            {{ Likes }}
          </div>
        </div>
      </div>
    </div>
    <div class="Address">
      <span class="Icon"><i class="fa-solid fa-location-pin"></i></span>
      <span class="title">{{ $t("Place.Address") }}</span>
      <span class="content">{{ Address }}</span>
    </div>
    <div class="Price_Range">
      <span class="Icon"><i class="fa-solid fa-dollar-sign"></i></span>
      <span class="title">{{ $t("Place.priceRange") }}</span>
      <span class="content">{{ PriceRange }}</span>
    </div>
    <div class="tags" v-if="!noTags">
      <div class="tag" v-for="tag in Tags" v-bind:key="tag.NameEn">
        <span v-if="$i18n.locale == 'en'">
          {{ tag.NameEN }}
        </span>
        <span v-if="$i18n.locale == 'ar'">
          {{ tag.NameAR }}
        </span>
      </div>
      <!-- 
        <div class="Address">
          <span><i class="fa-solid fa-location-pin"></i></span>
          <span>Location:</span>
          <a :href="location">Go...</a>
        </div>
 -->

      <!--  -->
    </div>
    <!--=============== Place ===============-->
    <!--=============== Working Hours ===============-->
    <div class="daysHours">
      <h2>{{ $t("Place.WorkingHours") }}</h2>
      <div class="day">
        {{ $t("Place.day1") }} :
        <span v-if="WorkHours.Day1 == true" class="time">{{
          WorkHours.Day1Hours
        }}</span>
        <span v-else>{{ $t("Place.Closed") }}</span>
      </div>
      <div class="day">
        {{ $t("Place.day2") }} :
        <span v-if="WorkHours.Day2 == true" class="time">{{
          WorkHours.Day2Hours
        }}</span>
        <span v-else>{{ $t("Place.Closed") }}</span>
      </div>
      <div class="day">
        {{ $t("Place.day3") }} :
        <span v-if="WorkHours.Day3 == true" class="time">{{
          WorkHours.Day3Hours
        }}</span>
        <span v-else>{{ $t("Place.Closed") }}</span>
      </div>
      <div class="day">
        {{ $t("Place.day4") }} :
        <span v-if="WorkHours.Day4 == true" class="time">{{
          WorkHours.Day4Hours
        }}</span>
        <span v-else>{{ $t("Place.Closed") }}</span>
      </div>
      <div class="day">
        {{ $t("Place.day5") }} :
        <span v-if="WorkHours.Day5 == true" class="time">{{
          WorkHours.Day5Hours
        }}</span>
        <span v-else>{{ $t("Place.Closed") }}</span>
      </div>
      <div class="day">
        {{ $t("Place.day6") }} :
        <span v-if="WorkHours.Day6 == true" class="time">{{
          WorkHours.Day6Hours
        }}</span>
        <span v-else>{{ $t("Place.Closed") }}</span>
      </div>
      <div class="day">
        {{ $t("Place.day7") }} :
        <span v-if="WorkHours.Day7 == true" class="time">{{
          WorkHours.Day7Hours
        }}</span>
        <span v-else>{{ $t("Place.Closed") }}</span>
      </div>
    </div>
    <!--=============== Working Hours ===============-->

    <!--=============== Comments ===============-->
    <div class="ReviewsSection">
      <h2 class="rev">{{ $t("Place.Reviews") }}</h2>
      <div class="comments">
        <input
          type="text"
          :placeholder="$t('Place.AddReview')"
          v-model="comment"
          @keydown.enter="postAReview"
          class="comment_input"
        />
        <button class="addComment_btn" v-on:click="postAReview()">
          <i class="fa-regular fa-paper-plane"></i>
        </button>
      </div>
      <div class="reviews" v-if="!noReviews">
        <div class="reviews_grid">
          <div class="review" v-for="review in Reviews" v-bind:key="review._id">
            <h2 class="review_username">{{ review.User.FullName }}</h2>
            <h3 class="review_date">{{ review.date.split("T")[0] }}</h3>
            <p class="review_content">
              {{ review.Comment }}
            </p>
          </div>
        </div>
      </div>
      <div v-else class="noReviews">
        {{ $t("Place.noReviews") }}
      </div>
    </div>
    <!--=============== Comments ===============-->

    <!--=============== Footer ===============-->
    <div class="footer">
      <FooterSide />
    </div>
    <!--=============== Footer ===============-->
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import FooterSide from "../components/FooterSide.vue";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
export default {
  name: "PlaceView",
  components: {
    NavBar,
    FooterSide,
  },
  data() {
    return {
      current: "",
      Reviews: [],
      comment: "",
      noReviews: false,
      id: "",
      PlaceName: "",
      Images: [],
      Address: "",
      Likes: 0,
      WorkHoursId: "",
      PriceRange: "",
      Description: "",
      location: "",
      CategoryName: "",
      Tags: [],
      CategoryId: "",
      isLoggedIn: false,
      isAdminLoggedIn: false,
      AlreadyLiked: false,
      url: "",
      noTags: false,
      WorkHours: {},
    };
  },
  async mounted() {
    this.url = ApiUrl.url;
    this.isLoggedIn = this.$store.getters.isLoggedIn;
    this.isAdminLoggedIn = this.$store.getters.isAdmin;
    let page = document.getElementsByClassName("p")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
    }
    await axios
      .get(ApiUrl.url + `/Community/getPlace/${this.$route.params.id}`)
      .then((res) => {
        this.id = res.data._id;
        this.PlaceName = res.data.Name;
        this.Address = res.data.Address;
        this.Likes = res.data.Likes;
        this.WorkHoursId = res.data.WorkHoursId;
        this.Tags = res.data.tags;
        this.Description = res.data.Description;
        this.location = res.data.location;
        this.PriceRange = res.data.PriceRange;
        this.CategoryName = res.data.CategoryName;
        this.CategoryId = res.data.CategoryId;
        this.WorkHours = res.data.WorkHours;
        this.Images = res.data.Images;
        this.current = this.Images[0];
        if (this.Tags.length == 0) {
          this.noTags = true;
        }
      });
    await axios
      .get(ApiUrl.url + `/Community/getReviews/${this.$route.params.id}`)
      .then((res) => {
        this.Reviews = res.data;
        if (this.Reviews.length == 0) {
          this.noReviews = true;
        }
      });

    await axios
      .post(ApiUrl.url + "/Community/Views", {
        Location: "Place",
        placeId: this.id,
      })
      .then(() => {})
      .catch(() => {});

    await axios
      .get(ApiUrl.url + `/Community/UserView/${this.$route.params.id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then(() => {})
      .catch(() => {});

    if (this.isLoggedIn && !this.isAdminLoggedIn) {
      await axios
        .get(ApiUrl.url + `/Community/isPlaceLiked/${this.$route.params.id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.isLiked) {
            this.AlreadyLiked = true;
          } else {
            this.AlreadyLiked = false;
          }
        });
    }

    let image = document.getElementsByClassName("place_image")[0];
    image.style.maxWidth = window.innerWidth + "px";
    window.onresize = function () {
      image.style.maxWidth = window.innerWidth + "px";
    };
  },
  methods: {
    move(image) {
      this.current = image;
    },
    async likePlace() {
      await axios
        .post(
          ApiUrl.url + "/Community/Like",
          { id: this.id },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Place Liked") {
            this.Likes += 1;
            this.AlreadyLiked = true;
          }
        });
    },
    async UnLikePlace() {
      await axios
        .post(
          ApiUrl.url + "/Community/Unlike",
          { id: this.$route.params.id },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.msg == "Unlike") {
            this.AlreadyLiked = false;
            this.Likes--;
          }
        });
    },
    async postAReview() {
      await axios
        .post(
          ApiUrl.url + "/Community/addReview",
          { PlaceId: this.$route.params.id, Comment: this.comment },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.msg == "Comment Added") {
            location.reload();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.p {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  direction: rtl;
  overflow: hidden;
}
.nav {
  height: 0px;
  z-index: 3000;
}
.footer {
  margin-top: auto;
}
/************************ Display Place ************************/
.place {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 100vh;
}
.image_slider {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .place_image {
    display: flex;
    align-items: center;
    border-radius: 0px;
    width: 100%;
    height: 100%;
  }
}
.dots {
  display: flex;
  justify-content: center;
  .dot {
    margin: 1rem;
    width: 15px;
    height: 15px;
    border-radius: 360px;
    background-color: #472bff;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #301dad;
    }
  }
}
.place_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  div {
    .Working_hours,
    .Address,
    .Price_Range,
    .Likes {
      width: 100%;
      margin: 18px;
      display: flex;
      align-items: center;
      overflow-wrap: break-word;
      span {
        display: flex;
        align-items: center;
        overflow-wrap: break-word;
        margin: 1rem;
      }
      i {
        font-size: 32px;
      }
    }
  }
}
.NameLikes {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 3rem;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}
.name_description {
  text-align: right;
}
.placeDescription {
  font-weight: bold;
  color: #bbbbbb;
}
.placeName {
  font-weight: bold;
}
.linkBtn_likes {
  display: flex;
  align-items: center;
}
.like_btn {
  background: none;
  outline: none;
  border: none;
  background-color: #472bff;
  width: 60px;
  height: 60px;
  border-radius: 15px;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  i {
    font-size: 18px;
  }
}
.like_btn:hover {
  cursor: pointer;
  background-color: #301dad;
}
.Address,
.Price_Range {
  text-align: right;
  margin: 3rem;
  margin-top: 2rem;
  margin-bottom: 0rem;
  .Icon,
  .title,
  .content {
    font-weight: 600;
    font-size: 22px;
    margin-left: 1rem;
  }
}
.Address {
  margin-top: 0rem;
}
.tags {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 2rem;
}
.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 1rem;
  color: #eeeeee;
  background-color: #472bff;
  border-radius: 10px;
  text-align: center;
}
@media screen and (max-width: 1270px) {
  .place {
    display: block;
    height: 100%;
  }
  .image_slider {
    justify-content: center;
  }
}

@media screen and (max-width: 580px) {
  .place {
    box-shadow: 0px 0px 0px 0px;
  }
}
@media screen and (max-width: 370px) {
  .place_info,
  .NameLikes {
    display: block;
  }
  .linkBtn_likes {
    justify-content: center;
  }
}
/************************ Display Place ************************/
.daysHours {
  text-align: right;
  margin-right: 3rem;
  margin-bottom: 3rem;
  h2 {
    font-weight: bold;
  }
}
.day {
  margin: 1rem;
  font-weight: 700;
}
.time {
  direction: ltr;
  unicode-bidi: bidi-override;
}

/************************ Reviews ************************/
.ReviewsSection {
  .rev {
    text-align: right;
    font-weight: bold;
    margin: 3rem;
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
  padding-bottom: 3rem;
}
.comment_input {
  padding: 1rem;
  width: 300px;
  border-radius: 10px;
  border: 2px solid #bbb;
  outline: none;
  font-size: 15px;
}
.comment_input::placeholder {
  font-weight: bold;
}
.addComment_btn {
  outline: none;
  padding: 1rem;
  border-radius: 10px;
  border: 2px solid #472bff;
  background-color: #472bff;
  color: #fff;
  margin: 1rem;
  width: 55px;
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: #301dad;
    cursor: pointer;
  }
}
.reviews {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.reviews_grid {
  display: grid;
  gap: 40px;
  grid-template-columns: auto auto auto;
}
.review {
  width: 470px;
  height: auto;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  text-align: right;
  .review_username,
  .review_date,
  .review_content {
    margin: 0.5rem;
  }
}
.review_username {
  font-weight: bold;
}
.review_date {
  font-weight: bold;
  color: #bbbbbb;
}
.review_content {
  text-align: right;
}
.noReviews {
  font-weight: bold;
  color: #bbb;
}
@media screen and (max-width: 1630px) {
  .reviews_grid {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1080px) {
  .reviews_grid {
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 470px) {
  .review {
    width: 300px;
  }
}
</style>
