var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AdminNav'),_c('div',[_c('h2',{staticClass:"section_title"}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"boxes"},[_c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"right_side"},[_c('div',{staticClass:"box_title"},[_vm._v("Likes")]),_c('div',{staticClass:"box_number"},[_vm._v(_vm._s(_vm.NumberOfLikes))])])]),_c('div',{staticClass:"box"},[_vm._m(1),_c('div',{staticClass:"right_side"},[_c('div',{staticClass:"box_title"},[_vm._v("Views")]),_c('div',{staticClass:"box_number"},[_vm._v(_vm._s(_vm.NumberOfViews))])])]),_c('div',{staticClass:"box"},[_vm._m(2),_c('div',{staticClass:"right_side"},[_c('div',{staticClass:"box_title"},[_vm._v("Reviews")]),_c('div',{staticClass:"box_number"},[_vm._v(_vm._s(_vm.NumberOfReviews))])])])])])]),_c('div',{staticClass:"tables"},[_c('div',{staticClass:"v_table"},[_c('h1',{staticClass:"title"},[_vm._v("Views")]),_c('div',{staticClass:"views_table"},[_vm._m(3),_vm._l((_vm.Views),function(view){return _c('div',{key:view._id,staticClass:"view_row"},[_c('div',{staticClass:"place_name"},[_vm._v(_vm._s(view.Place.Name))]),_c('div',{staticClass:"views"},[_vm._v(_vm._s(view.Number))])])})],2)]),_c('div',{staticClass:"l_table"},[_c('h1',{staticClass:"title"},[_vm._v("Likes")]),_c('div',{staticClass:"likes_table"},[_vm._m(4),_vm._l((_vm.Likes),function(like){return _c('div',{key:like._id,staticClass:"like_row"},[_c('div',{staticClass:"place_name"},[_vm._v(_vm._s(like.Place.Name))])])})],2)]),_c('div',{staticClass:"r_table"},[_c('h1',{staticClass:"title"},[_vm._v("Reviews")]),_c('div',{staticClass:"reviews_table"},[_vm._m(5),_vm._l((_vm.Reviews),function(review){return _c('div',{key:review._id,staticClass:"review_row"},[_c('div',{staticClass:"place_name"},[_vm._v(_vm._s(review.Place.Name))]),_c('div',{staticClass:"comment"},[_vm._v(_vm._s(review.Comment))])])})],2)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_side"},[_c('i',{staticClass:"fa-regular fa-heart"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_side"},[_c('i',{staticClass:"fa-regular fa-eye"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_side"},[_c('i',{staticClass:"fa-regular fa-comment"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"first_row"},[_c('div',{staticClass:"place_name"},[_vm._v("Place Name")]),_c('div',{staticClass:"views"},[_vm._v("Views")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"first_row"},[_c('div',{staticClass:"place_name"},[_vm._v("Place Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"first_row"},[_c('div',{staticClass:"place_name"},[_vm._v("Place Name")]),_c('div',{staticClass:"comment"},[_vm._v("Comment")])])
}]

export { render, staticRenderFns }