<template>
  <div>
    <div class="nav">
      <AdminNavbar />
    </div>
    <!--=============== Search Form ===============-->
    <div class="search_form">
      <div class="search">
        <div class="input">
          <input
            type="text"
            class="text-input"
            placeholder="Place Name"
            v-model="query"
            @keyup.enter="searchPlaces()"
          />
        </div>
        <div class="search_btn">
          <button class="btn" v-on:click="searchPlaces()">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </div>
    <!--=============== Search Form ===============-->

    <!--=============== display Palces ===============-->
    <div class="p">
      <div class="places">
        <div class="place" v-for="place in Places" v-bind:key="place._id">
          <router-link
            :to="`/${$i18n.locale}/Admin/ManageReview/${place._id}`"
            class="link"
          >
            {{ place.Name }}
          </router-link>
        </div>
      </div>
    </div>
    <!--=============== display Palces ===============-->
  </div>
</template>

<script>
import AdminNavbar from "../../components/AdminNav.vue";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
export default {
  components: {
    AdminNavbar,
  },
  data() {
    return {
      Places: [],
      query: "",
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    this.loadPlaces();
  },
  methods: {
    async loadPlaces() {
      await axios
        .get(ApiUrl.url + "/api/Places/Get", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.Places = res.data;
        });
    },
    async searchPlaces() {
      if (this.query == "") {
        await this.loadPlaces();
        return;
      }
      await axios
        .get(ApiUrl.url + `/Community/Places/Search/${this.query}`)
        .then((res) => {
          this.Places = res.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/*************** Search Form ***************/
.search_form {
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search {
  display: flex;
  justify-content: center;
  align-content: center;
}
.text-input {
  width: 365px;
  height: 40px;
  border: none;
  outline: none;
  background: none;
  border: 2px solid #bbb;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn {
  color: #fff;
  outline: none;
  border: none;
  background: none;
  width: 45px;
  margin-left: 1rem;
  height: 42px;
  border-radius: 5px;
  background-color: #472bff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3422aa;
  }
}
/*************** Search Form ***************/

/*************** display Places ***************/
.p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.places {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
}
.place {
  width: 300px;
  height: 120px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}
.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
}
/*************** display Places ***************/
</style>
