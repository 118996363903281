<template>
  <div class="sign">
    <div class="form">
      <div class="left">
        <div class="top">
          <router-link to="/">
            <i class="fa-solid fa-arrow-right"></i>
            {{ $t("SignPage.BackLink") }}
          </router-link>
        </div>
        <div class="mid">
          <form @submit.prevent="signUp()">
            <h1 class="ti">{{ $t("SignPage.Title") }}</h1>
            <div class="input">
              <div>
                <input
                  type="text"
                  name="FullName"
                  autocomplete="off"
                  v-model="FullName"
                  class="fullname-input"
                  id="FullName_input"
                  :placeholder="$t('SignPage.input1')"
                />
                <br />
                <label for="FullName_input" class="error_msg_1"></label>
              </div>
            </div>
            <div class="input">
              <div>
                <input
                  type="text"
                  name="email"
                  autocomplete="off"
                  v-model="email"
                  class="email-input"
                  id="email_input"
                  :placeholder="$t('SignPage.input2')"
                />
                <br />
                <label for="email_input" class="error_msg_2"></label>
              </div>
            </div>
            <div class="input">
              <div>
                <input
                  type="password"
                  name="password"
                  autocomplete="off"
                  v-model="password"
                  class="password-input"
                  id="password_input"
                  :placeholder="$t('SignPage.input3')"
                />
                <br />
                <label for="password_input" class="error_msg_3"></label>
              </div>
            </div>
            <div class="links">
              <router-link :to="{ name: 'Login' }">{{
                $t("SignPage.haveAccount")
              }}</router-link>
            </div>
            <div class="input">
              <button class="submit">{{ $t("SignPage.singup") }}</button>
            </div>
            <div class="input">
              <label class="success_msg"></label>
            </div>
          </form>
        </div>
        <div class="bottom">
          <ul>
            <li class="link1">
              <router-link to="/">{{ $t("SignPage.Link1") }}</router-link>
            </li>
            <li><div class="dot"></div></li>
            <li class="link2">
              <router-link to="about">{{ $t("SignPage.Link2") }}</router-link>
            </li>
            <li><div class="dot"></div></li>
            <li class="link3">
              <router-link to="PrivacyPolicy">{{
                $t("SignPage.Link3")
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="one"></div>
        <div class="one"></div>
        <div class="one"></div>
        <div class="one"></div>
        <div class="one"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import axios from "axios";
import JQuery from "jquery";
let $ = JQuery;
export default {
  name: "SignupView",
  data() {
    return {
      FullName: "",
      email: "",
      password: "",
    };
  },
  async mounted() {
    let page = document.getElementsByClassName("sign")[0];
    if (this.$i18n.locale == "ar") {
      page.style.direction = "rtl";
    }
    await axios
      .post(ApiUrl.url + "/Community/Views", {
        Location: "Signup",
        placeId: "",
      })
      .then(() => {})
      .catch(() => {});
  },
  methods: {
    async signUp() {
      $(".fullname-input").click(function () {
        window.onkeydown = function () {
          // remove border color
          $(".fullname-input").css("borderBottom", "3px solid #472bff");
          // set text for label
          $(".error_msg_1").text("");
        };
      });
      $(".email-input").click(function () {
        window.onkeydown = function () {
          // remove border color
          $(".email-input").css("borderBottom", "3px solid #472bff");
          // set text for label
          $(".error_msg_2").text("");
        };
      });
      $(".password-input").click(function () {
        window.onkeydown = function () {
          $(".password-input").css("borderBottom", "3px solid #472bff");
          $(".error_msg_3").text("");
        };
      });
      if (this.FullName == "" && this.email == "" && this.password == "") {
        $(".fullname-input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_1").text(this.$i18n.t("SignPage.err1"));
        $(".email-input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_2").text(this.$i18n.t("SignPage.err2"));
        $(".password-input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_3").text(this.$i18n.t("SignPage.err3"));
        return;
      }
      if (this.FullName == "") {
        $(".fullname-input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_2").text(this.$i18n.t("SignPage.err1"));
        return;
      }
      if (this.email == "") {
        $(".email-input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_2").text(this.$i18n.t("SignPage.err2"));
        return;
      }
      if (this.password == "") {
        $(".password-input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_3").text(this.$i18n.t("SignPage.err3"));
        return;
      }
      await axios
        .post(ApiUrl.url + "/api/Auth/register", {
          FullName: this.FullName,
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          if (res.data.err == "The Email is Already used") {
            $(".email-input").css("borderBottom", "3px solid rgb(250,82,82)");
            $(".error_msg_2").text(this.$i18n.t("SignPage.err4"));
          }
          if (res.data.msg == "User Created") {
            $(".success_msg").text(this.$i18n.t("SignPage.success"));
            setTimeout(() => {
              location.replace(`/${this.$i18n.locale}/Login`);
            }, 1250);
          }
        })
        .catch(() => {
          location.replace("/");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  font-weight: bold;
  font-size: 14px;
  margin: 20px;
  transition: all 200ms ease-in-out;
}
a:hover {
  color: #3721c5;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.205);
}
.form {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.562);
}
.ti {
  font-weight: bold;
}
/********************* Left side *********************/
.left {
  width: 600px;
  height: 100%;
}
.top {
  display: flex;
  align-items: center;
  height: 10vh;
}
.mid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.input {
  padding: 20px;
  div {
    text-align: right;
  }
}
.fullname-input,
.email-input,
.password-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  border-bottom: 3px solid #472bff;
  transition: all 200ms ease-in-out;
  color: #1f2833;
  font-weight: 700;
}
::placeholder {
  color: #1f2833;
  font-weight: 700;
}
.error_msg_1,
.error_msg_2,
.error_msg_3 {
  color: rgb(250, 82, 82);
  font-weight: 700;
}
.success_msg {
  font-weight: 700;
}
.links {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.submit {
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #472bff;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.submit:hover {
  cursor: pointer;
  background-color: #3721c5;
}
.bottom {
  height: 10vh;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      display: inline;
      margin: 1.2rem;
    }
  }
}
.dot {
  color: #1f2833;
  background-color: #1f2833;
  width: 5px;
  height: 5px;
  border-radius: 360px;
}
/********************* Left side *********************/
/********************* right side *********************/
.right {
  display: flex;
  justify-content: space-between;
  width: 600px;
}
.one {
  background-color: #472bff;
  height: 5000px;
  width: 130px;
  margin: 1rem;
  transform: rotate(-30deg);
}
/********************* right side *********************/
@media screen and (max-width: 1410px) {
  .right {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .left {
    width: 300px;
  }
  .fullname-input,
  .email-input,
  .password-input {
    width: 265px;
  }
  .submit {
    width: 265px;
  }
  .dot {
    display: none;
  }
}
</style>
