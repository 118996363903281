<template>
  <div>
    <!--=============== Navbar ===============-->
    <AdminNav />
    <!--=============== Navbar ===============-->
    <!--=============== Image Edit ===============-->
    <div class="wrapper">
      <div class="images">
        <div class="image" v-for="image in Images" v-bind:key="image.filename">
          <img :src="`${url}/uploads/${image}`" class="img" />
          <button class="del_image_btn" v-on:click="delImage(image)">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
    <!--=============== Edit Place ===============-->
    <div class="EditPlace">
      <!--=========== Name Input ===========-->
      <div class="input">
        <input
          type="text"
          placeholder="Place Name"
          class="text-input"
          autocomplete="off"
          id="Name_input"
          v-model="PlaceName"
        />
        <br />
        <label for="Name_input" class="name_input_label err"></label>
      </div>

      <!--=========== Address Input ===========-->
      <div class="input">
        <input
          type="text"
          placeholder="Address"
          class="text-input"
          autocomplete="off"
          id="Address_input"
          v-model="Address"
        />
        <br />
        <label for="Address_input" class="Address_input_label err"></label>
      </div>

      <!--=========== Description Input ===========-->
      <div class="input">
        <input
          type="text"
          placeholder="Description"
          class="text-input"
          autocomplete="off"
          id="Description_input"
          v-model="Description"
        />
        <br />
        <label
          for="Description_input"
          class="Description_input_label err"
        ></label>
      </div>

      <!--=========== Images Input ===========-->
      <div class="input">
        <div class="choose_file">
          <label for="choose_file">
            <input
              type="file"
              name="Images"
              multiple
              ref="file"
              @change="onFileSelected"
              id="choose_file"
            />
            <span class="file_err">Choose Files</span>
          </label>
        </div>
      </div>

      <!--=========== Location input ===========-->
      <div class="input">
        <input
          type="text"
          name="location"
          autocomplete="off"
          class="text-input"
          placeholder="Location Link"
          id="location_input"
          v-model="location"
        />
        <br />
        <label for="location_input" class="location_input_label"></label>
      </div>

      <!--=========== Tags Input ===========-->
      <div class="input">
        <div>
          <input
            type="text"
            name="tags"
            autocomplete="off"
            class="text-input"
            v-model="tags"
            @keyup="searchTag()"
            id="tags_input"
            placeholder="Enter Tags"
          />
          <br />
          <label for="tags_input" class="tags_input_label err"></label>
          <ul class="list_tags">
            <li
              v-for="tag in Tags"
              v-bind:key="tag._id"
              v-on:click="selectTag(tag.NameEN, tag.NameAR)"
            >
              {{ tag.NameEN }}
            </li>
          </ul>
        </div>
        <div class="tags">
          <div class="tag" v-for="tag in SelectTags" :key="tag._id">
            {{ tag.NameEN }}
            <div class="remove_tag_btn">
              <i
                class="fa-solid fa-xmark"
                v-on:click="deSelectTag(tag.NameEN)"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <!--=========== Price Range Input ===========-->
      <div class="input">
        <div>
          <input
            type="text"
            name="PriceRange"
            placeholder="Choose Price Range (Low, Mid, High)"
            autocomplete="off"
            class="text-input"
            id="PriceRange_input"
            v-model="PriceRange"
          />
          <br />
          <label
            for="PriceRange_input"
            class="PriceRange_input_label err"
          ></label>
        </div>
      </div>

      <!--=========== Category Input ===========-->
      <div class="input">
        <h4 class="selected_item">
          Choose a Category: {{ CategoryName }}
          <br />
          <label class="error_msg_7 err"></label>
        </h4>
        <div class="drop">
          <div class="menu">
            <div
              class="item"
              v-for="category in Categories"
              v-bind:key="category._id"
              v-on:click="getSelectedCategory(category._id, category.Name)"
            >
              {{ category.NameEN }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Edit Place ===============-->
    <div>
      <div>
        <h2>Select Working Days</h2>
        <div class="a">
          <div class="days">
            <div class="day" id="sunday" v-on:click="selectDays(1, 'sunday')">
              Sunday
            </div>
            <div class="day" id="monday" v-on:click="selectDays(2, 'monday')">
              Monday
            </div>
            <div class="day" id="tuesday" v-on:click="selectDays(3, 'tuesday')">
              Tuesday
            </div>
            <div
              class="day"
              id="wednesday"
              v-on:click="selectDays(4, 'wednesday')"
            >
              Wednesday
            </div>
            <div
              class="day"
              id="thursday"
              v-on:click="selectDays(5, 'thursday')"
            >
              Thursday
            </div>
            <div class="day" id="friday" v-on:click="selectDays(6, 'friday')">
              Friday
            </div>
            <div
              class="day"
              id="saturday"
              v-on:click="selectDays(7, 'saturday')"
            >
              Saturday
            </div>
          </div>
        </div>
        <div class="manageHours">
          <div v-if="Day1">
            <h3>Sunday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day1F"
                    id="day1_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day1_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(1, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(1, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day1T"
                    id="day1_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day1_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(1, 'am')">am</div>
                    <div v-on:click="changeToTimeType(1, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day2">
            <h3>Monday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day2F"
                    id="day2_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day2_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(2, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(2, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day2T"
                    id="day2_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day2_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(2, 'am')">am</div>
                    <div v-on:click="changeToTimeType(2, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day3">
            <h3>Tuesday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day3F"
                    id="day3_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day3_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(3, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(3, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day3T"
                    id="day3_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day3_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(3, 'am')">am</div>
                    <div v-on:click="changeToTimeType(3, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day4">
            <h3>Wednesday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day4F"
                    id="day4_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day4_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(4, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(4, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day4T"
                    id="day4_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day4_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(4, 'am')">am</div>
                    <div v-on:click="changeToTimeType(4, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day5">
            <h3>Thursday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day5F"
                    id="day5_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day5_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(5, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(5, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day5T"
                    id="day5_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day5_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(5, 'am')">am</div>
                    <div v-on:click="changeToTimeType(5, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day6">
            <h3>Friday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day6F"
                    id="day6_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day6_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(6, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(6, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day6T"
                    id="day6_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day6_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(6, 'am')">am</div>
                    <div v-on:click="changeToTimeType(6, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day7">
            <h3>Saturday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day7F"
                    id="day7_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day7_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(7, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(7, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day7T"
                    id="day7_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day7_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(7, 'am')">am</div>
                    <div v-on:click="changeToTimeType(7, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input">
      <button class="submit" v-on:click="editPlace()">Edit a Place</button>
    </div>
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import axios from "axios";
import jquery from "jquery";
import AdminNav from "@/components/AdminNav.vue";
let $ = jquery;
export default {
  name: "EditPlace",
  data() {
    return {
      Categories: [],
      Tags: [],
      SelectTags: [],
      id: "",
      PlaceName: "",
      WorkHours: {},
      Address: "",
      Images: [],
      Description: "",
      location: "",
      tags: "",
      PriceRange: "",
      CategoryId: "",
      CategoryName: "",
      PlaceImages: [],
      url: "",
      Day1: false,
      Day2: false,
      Day3: false,
      Day4: false,
      Day5: false,
      Day6: false,
      Day7: false,
      Day1Hours: "",
      Day2Hours: "",
      Day3Hours: "",
      Day4Hours: "",
      Day5Hours: "",
      Day6Hours: "",
      Day7Hours: "",
      day1F: "",
      day1T: "",
      Day1_from_timeType: "am",
      Day1_to_timeType: "pm",
      day2F: "",
      day2T: "",
      Day2_from_timeType: "am",
      Day2_to_timeType: "pm",
      day3F: "",
      day3T: "",
      Day3_from_timeType: "am",
      Day3_to_timeType: "pm",
      day4F: "",
      day4T: "",
      Day4_from_timeType: "am",
      Day4_to_timeType: "pm",
      day5F: "",
      day5T: "",
      Day5_from_timeType: "am",
      Day5_to_timeType: "pm",
      day6F: "",
      day6T: "",
      Day6_from_timeType: "am",
      Day6_to_timeType: "pm",
      day7F: "",
      day7T: "",
      Day7_from_timeType: "am",
      Day7_to_timeType: "pm",
    };
  },
  components: {
    AdminNav,
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    this.url = ApiUrl.url;
    this.loadCategories();
    axios
      .get(ApiUrl.url + `/api/Places/Get/${this.$route.params.id}`, {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.id = res.data.place._id;
        this.PlaceName = res.data.place.Name;
        this.WorkHours = res.data.place.WorkHours;
        this.Address = res.data.place.Address;
        this.Description = res.data.place.Description;
        this.location = res.data.place.location;
        this.SelectTags = res.data.place.tags;
        this.PriceRange = res.data.place.PriceRange;
        this.CategoryId = res.data.place.CategoryId;
        this.CategoryName = res.data.place.CategoryName;
        this.Images = res.data.place.Images;
        this.WorkHours = res.data.workHours;
        this.Day1 = res.data.workHours.Day1;
        this.Day2 = res.data.workHours.Day2;
        this.Day3 = res.data.workHours.Day3;
        this.Day4 = res.data.workHours.Day4;
        this.Day5 = res.data.workHours.Day5;
        this.Day6 = res.data.workHours.Day6;
        this.Day7 = res.data.workHours.Day7;
        this.Day1Hours = res.data.workHours.Day1Hours;
        this.Day2Hours = res.data.workHours.Day2Hours;
        this.Day3Hours = res.data.workHours.Day3Hours;
        this.Day4Hours = res.data.workHours.Day4Hours;
        this.Day5Hours = res.data.workHours.Day5Hours;
        this.Day6Hours = res.data.workHours.Day6Hours;
        this.Day7Hours = res.data.workHours.Day7Hours;

        if (this.Day1) {
          $("#sunday").css("backgroundColor", "#472bff");
          $("#sunday").css("color", "#fff");
          this.getDayHours(1, this.Day1Hours);
        }
        if (this.Day2) {
          $("#monday").css("backgroundColor", "#472bff");
          $("#monday").css("color", "#fff");
          this.getDayHours(2, this.Day2Hours);
        }
        if (this.Day3) {
          $("#tuesday").css("backgroundColor", "#472bff");
          $("#tuesday").css("color", "#fff");
          this.getDayHours(3, this.Day3Hours);
        }
        if (this.Day4) {
          $("#wednesday").css("backgroundColor", "#472bff");
          $("#wednesday").css("color", "#fff");
          this.getDayHours(4, this.Day4Hours);
        }
        if (this.Day5) {
          $("#thursday").css("backgroundColor", "#472bff");
          $("#thursday").css("color", "#fff");
          this.getDayHours(5, this.Day5Hours);
        }
        if (this.Day6) {
          $("#friday").css("backgroundColor", "#472bff");
          $("#friday").css("color", "#fff");
          this.getDayHours(6, this.Day6Hours);
        }
        if (this.Day7) {
          $("#saturday").css("backgroundColor", "#472bff");
          $("#saturday").css("color", "#fff");
          this.getDayHours(7, this.Day7Hours);
        }
      });
  },
  methods: {
    async loadCategories() {
      await axios
        .get(ApiUrl.url + "/api/Categories/Get", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.Categories = res.data;
        });
    },
    onFileSelected() {
      this.PlaceImages = this.$refs.file.files;
    },
    getSelectedCategory(CategoryId, CategoryName) {
      this.CategoryId = CategoryId;
      this.CategoryName = CategoryName;
    },
    getDayHours(DayNum, dayHours) {
      let a = dayHours.split(" ");
      if (DayNum == 1) {
        this.day1F = a[0];
        this.Day1_from_timeType = a[1];
        this.day1T = a[3];
        this.Day1_to_timeType = a[4];
      } else if (DayNum == 2) {
        this.day2F = a[0];
        this.Day2_from_timeType = a[1];
        this.day2T = a[3];
        this.Day2_to_timeType = a[4];
      } else if (DayNum == 3) {
        this.day3F = a[0];
        this.Day3_from_timeType = a[1];
        this.day3T = a[3];
        this.Day3_to_timeType = a[4];
      } else if (DayNum == 4) {
        this.day4F = a[0];
        this.Day4_from_timeType = a[1];
        this.day4T = a[3];
        this.Day4_to_timeType = a[4];
      } else if (DayNum == 5) {
        this.day5F = a[0];
        this.Day5_from_timeType = a[1];
        this.day5T = a[3];
        this.Day5_to_timeType = a[4];
      } else if (DayNum == 6) {
        this.day6F = a[0];
        this.Day6_from_timeType = a[1];
        this.day6T = a[3];
        this.Day6_to_timeType = a[4];
      } else if (DayNum == 7) {
        this.day7F = a[0];
        this.Day7_from_timeType = a[1];
        this.day7T = a[3];
        this.Day7_to_timeType = a[4];
      }
    },
    selectDays(DayNum, DayName) {
      if (DayNum == 1 && this.Day1 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day1 = true;
      } else if (DayNum == 1 && this.Day1 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day1 = false;
      }

      if (DayNum == 2 && this.Day2 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day2 = true;
      } else if (DayNum == 2 && this.Day2 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day2 = false;
      }

      if (DayNum == 3 && this.Day3 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day3 = true;
      } else if (DayNum == 3 && this.Day3 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day3 = false;
      }

      if (DayNum == 4 && this.Day4 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day4 = true;
      } else if (DayNum == 4 && this.Day4 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day4 = false;
      }

      if (DayNum == 5 && this.Day5 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day5 = true;
      } else if (DayNum == 5 && this.Day5 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day5 = false;
      }

      if (DayNum == 6 && this.Day6 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day6 = true;
      } else if (DayNum == 6 && this.Day6 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day6 = false;
      }

      if (DayNum == 7 && this.Day7 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day7 = true;
      } else if (DayNum == 7 && this.Day7 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day7 = false;
      }
    },
    changeFromTimeType(DayNum, time) {
      if (DayNum == 1) {
        this.Day1_from_timeType = time;
      } else if (DayNum == 2) {
        this.Day2_from_timeType = time;
      } else if (DayNum == 3) {
        this.Day3_from_timeType = time;
      } else if (DayNum == 4) {
        this.Day4_from_timeType = time;
      } else if (DayNum == 5) {
        this.Day5_from_timeType = time;
      } else if (DayNum == 6) {
        this.Day6_from_timeType = time;
      } else if (DayNum == 7) {
        this.Day7_from_timeType = time;
      }
    },
    changeToTimeType(DayNum, time) {
      if (DayNum == 1) {
        this.Day1_to_timeType = time;
      } else if (DayNum == 2) {
        this.Day2_to_timeType = time;
      } else if (DayNum == 3) {
        this.Day3_to_timeType = time;
      } else if (DayNum == 4) {
        this.Day4_to_timeType = time;
      } else if (DayNum == 5) {
        this.Day5_to_timeType = time;
      } else if (DayNum == 6) {
        this.Day6_to_timeType = time;
      } else if (DayNum == 7) {
        this.Day7_to_timeType = time;
      }
    },
    searchTag() {
      if (this.tags == "") {
        this.Tags = [];
      } else {
        axios
          .post(
            ApiUrl.url + "/api/Tags/Search",
            { query: this.tags },
            { headers: { token: localStorage.getItem("token") } }
          )
          .then((res) => {
            this.Tags = res.data;
          });
      }
    },
    selectTag(tagNameEN, tagNameAR) {
      this.SelectTags.push({ NameEN: tagNameEN, NameAR: tagNameAR });

      for (let i = 0; i < this.Tags.length; i++) {
        if (tagNameEN == this.Tags[i].NameEN) {
          this.Tags.splice(i, 1);
        }
      }
    },
    deSelectTag(tagNameEN) {
      for (let i = 0; i < this.SelectTags.length; i++) {
        if (tagNameEN == this.SelectTags[i].NameEN) {
          this.SelectTags.splice(i, 1);
        }
      }
    },
    async editPlace() {
      $("#Name_input").click(function () {
        window.onkeydown = function () {
          $("#Name_input").css("borderBottom", "3px solid #472bff");
          $(".name_input_label").text("");
        };
      });

      $("#Address_input").click(function () {
        window.onkeydown = function () {
          $("#Address_input").css("borderBottom", "3px solid #472bff");
          $(".Address_input_label").text("");
        };
      });

      $("#Description_input").click(function () {
        window.onkeydown = function () {
          $("#Description_input").css("borderBottom", "3px solid #472bff");
          $(".Description_input_label").text("");
        };
      });

      $("#PriceRange_input").click(function () {
        window.onkeydown = function () {
          $("#PriceRange_input").css("borderBottom", "3px solid #472bff");
          $(".PriceRange_input_label").text("");
        };
      });

      if (
        this.PlaceName == "" &&
        this.Address == "" &&
        this.Description == "" &&
        this.PriceRange == "" &&
        this.CategoryId == ""
      ) {
        $("#Name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".name_input_label").text("Please Enter a Name");

        $("#Address_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Address_input_label").text("Please Enter an Address");

        $("#Description_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Description_input_label").text("Please Enter a Description");

        $("#tags_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".tags_input_label").text("Please Enter Tags");

        $("#PriceRange_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".PriceRange_input_label").text("Please Enter Choose a Price Range");

        $(".error_msg_7").text("Please Choose a Category");
      }
      if (this.PlaceName == "") {
        $("#Name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".name_input_label").text("Please Enter a Name");
        return;
      }

      if (this.Address == "") {
        $("#Address_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Address_input_label").text("Please Enter an Address");
        return;
      }

      if (this.Description == "") {
        $("#Description_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Description_input_label").text("Please Enter a Description");
        return;
      }

      if (this.CategoryId == "") {
        $(".error_msg_7").text("Please Choose a Category");
        return;
      }

      /*************** Day1 Inputs ***************/
      // Day 1 From Input
      $("#day1_from").click(function () {
        window.onkeydown = function () {
          $("#day1_from").css("border", "2px solid #472bff");
        };
      });

      // Day 1 To Input
      $("#day1_to").click(function () {
        window.onkeydown = function () {
          $("#day1_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day1 Inputs ***************/

      /*************** Day2 Inputs ***************/
      // Day 2 From Input
      $("#day2_from").click(function () {
        window.onkeydown = function () {
          $("#day2_from").css("border", "2px solid #472bff");
        };
      });

      // Day 2 To Input
      $("#day2_to").click(function () {
        window.onkeydown = function () {
          $("#day2_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day2 Inputs ***************/

      /*************** Day3 Inputs ***************/
      // Day 3 From Input
      $("#day3_from").click(function () {
        window.onkeydown = function () {
          $("#day3_from").css("border", "2px solid #472bff");
        };
      });

      // Day 3 To Input
      $("#day3_to").click(function () {
        window.onkeydown = function () {
          $("#day3_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day3 Inputs ***************/

      /*************** Day4 Inputs ***************/
      // Day 4 From Input
      $("#day4_from").click(function () {
        window.onkeydown = function () {
          $("#day4_from").css("border", "2px solid #472bff");
        };
      });

      // Day 4 To Input
      $("#day4_to").click(function () {
        window.onkeydown = function () {
          $("#day4_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day4 Inputs ***************/

      /*************** Day5 Inputs ***************/
      // Day 5 From Input
      $("#day5_from").click(function () {
        window.onkeydown = function () {
          $("#day5_from").css("border", "2px solid #472bff");
        };
      });

      // Day 5 To Input
      $("#day5_to").click(function () {
        window.onkeydown = function () {
          $("#day5_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day5 Inputs ***************/

      /*************** Day6 Inputs ***************/
      // Day 6 From Input
      $("#day6_from").click(function () {
        window.onkeydown = function () {
          $("#day6_from").css("border", "2px solid #472bff");
        };
      });

      // Day 6 To Input
      $("#day6_to").click(function () {
        window.onkeydown = function () {
          $("#day6_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day6 Inputs ***************/

      /*************** Day7 Inputs ***************/
      // Day 7 From Input
      $("#day7_from").click(function () {
        window.onkeydown = function () {
          $("#day7_from").css("border", "2px solid #472bff");
        };
      });

      // Day 7 To Input
      $("#day7_to").click(function () {
        window.onkeydown = function () {
          $("#day7_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day7 Inputs ***************/

      if (this.Day1 == true) {
        if (this.day1F == "" && this.day1T == "") {
          $("#day1_from").css("border", "2px solid rgb(250,82,82)");
          $("#day1_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day1F == "") {
          $("#day1_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day1T == "") {
          $("#day1_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day2 == true) {
        if (this.day2F == "" && this.day2T == "") {
          $("#day2_from").css("border", "2px solid rgb(250,82,82)");
          $("#day2_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day2F == "") {
          $("#day2_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day2T == "") {
          $("#day2_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }
      console.log(this.Day3);
      if (this.Day3 == true) {
        if (this.day3F == "" && this.day3T == "") {
          $("#day3_from").css("border", "2px solid rgb(250,82,82)");
          $("#day3_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day3F == "") {
          $("#day3_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day3T == "") {
          $("#day3_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day4 == true) {
        if (this.day4F == "" && this.day4T == "") {
          $("#day4_from").css("border", "2px solid rgb(250,82,82)");
          $("#day4_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day4F == "") {
          $("#day4_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day4T == "") {
          $("#day4_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day5 == true) {
        if (this.day5F == "" && this.day5T == "") {
          $("#day5_from").css("border", "2px solid rgb(250,82,82)");
          $("#day5_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day5F == "") {
          $("#day5_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day5T == "") {
          $("#day5_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day6 == true) {
        if (this.day6F == "" && this.day6T == "") {
          $("#day6_from").css("border", "2px solid rgb(250,82,82)");
          $("#day6_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day6F == "") {
          $("#day6_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day6T == "") {
          $("#day6_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day7 == true) {
        if (this.day7F == "" && this.day7T == "") {
          $("#day7_from").css("border", "2px solid rgb(250,82,82)");
          $("#day7_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day7F == "") {
          $("#day7_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day7T == "") {
          $("#day7_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      const fd = new FormData();
      fd.append("id", this.id);
      fd.append("Name", this.PlaceName);
      fd.append("Address", this.Address);
      for (let i = 0; i < this.PlaceImages.length; i++) {
        fd.append("Images", this.PlaceImages[i]);
      }
      fd.append("Description", this.Description);
      fd.append("location", this.location);
      fd.append("tags", JSON.stringify(this.SelectTags));
      fd.append("PriceRange", this.PriceRange);
      fd.append("CategoryId", this.CategoryId);
      fd.append("CategoryName", this.CategoryName);
      fd.append("WorkHoursId", this.WorkHours._id);
      fd.append("Day1", this.Day1);
      fd.append("Day2", this.Day2);
      fd.append("Day3", this.Day3);
      fd.append("Day4", this.Day4);
      fd.append("Day5", this.Day5);
      fd.append("Day6", this.Day6);
      fd.append("Day7", this.Day7);

      // Sent Day 1
      if (this.Day1) {
        fd.append(
          "Day1Hours",
          this.day1F +
            " " +
            this.Day1_from_timeType +
            " - " +
            this.day1T +
            " " +
            this.Day1_to_timeType
        );
      } else {
        fd.append("Day1Hours", "");
      }

      // Sent Day 2
      if (this.Day2) {
        fd.append(
          "Day2Hours",
          this.day2F +
            " " +
            this.Day2_from_timeType +
            " - " +
            this.day2T +
            " " +
            this.Day2_to_timeType
        );
      } else {
        fd.append("Day2Hours", "");
      }

      // Sent Day 3
      if (this.Day3) {
        fd.append(
          "Day3Hours",
          this.day3F +
            " " +
            this.Day3_from_timeType +
            " - " +
            this.day3T +
            " " +
            this.Day3_to_timeType
        );
      } else {
        fd.append("Day3Hours", "");
      }

      // Sent Day 4
      if (this.Day4) {
        fd.append(
          "Day4Hours",
          this.day4F +
            " " +
            this.Day4_from_timeType +
            " - " +
            this.day4T +
            " " +
            this.Day4_to_timeType
        );
      } else {
        fd.append("Day4Hours", "");
      }

      // Sent Day 5
      if (this.Day5) {
        fd.append(
          "Day5Hours",
          this.day5F +
            " " +
            this.Day5_from_timeType +
            " - " +
            this.day5T +
            " " +
            this.Day5_to_timeType
        );
      } else {
        fd.append("Day5Hours", "");
      }

      // Sent Day 6
      if (this.Day6) {
        fd.append(
          "Day6Hours",
          this.day6F +
            " " +
            this.Day6_from_timeType +
            " - " +
            this.day6T +
            " " +
            this.Day6_to_timeType
        );
      } else {
        fd.append("Day6Hours", "");
      }

      // Sent Day 7
      if (this.Day7) {
        fd.append(
          "Day7Hours",
          this.day7F +
            " " +
            this.Day7_from_timeType +
            " - " +
            this.day7T +
            " " +
            this.Day7_to_timeType
        );
      } else {
        fd.append("Day7Hours", "");
      }
      await axios
        .patch(ApiUrl.url + "/api/Places/Edit", fd, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Place Updated") {
            location.reload();
          }
        });
    },
    async delImage(Image) {
      let img = Image.replace(".", "+");
      await axios
        .delete(
          ApiUrl.url +
            `/api/Places/DeleteImage/${this.$route.params.id}/${img}`,
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.msg == "Image Deleted") {
            location.reload();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/************************ Image Edit ************************/
.wrapper {
  display: flex;
  justify-content: center;
}
.images {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 2rem;
}
.image {
  width: 450px;
  height: 400px;
  display: flex;
  justify-content: center;
  img {
    width: 80%;
    height: 80%;
    border-radius: 10px;
  }
}
.del_image_btn {
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 360px;
  transition: all 300ms ease-in-out;
  background-color: rgb(250, 82, 82);
  transform: translate(-20px, -10px);
  i {
    font-size: 16px;
  }
}
.del_image_btn:hover {
  cursor: pointer;
  color: rgb(250, 82, 82);
  background-color: #fff;
}
@media screen and (max-width: 1650px) {
  .images {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1322px) {
  .images {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 917px) {
  .images {
    grid-template-columns: auto;
  }
}
/************************ Image Edit ************************/
/************************ Edit Place ************************/
.EditPlace {
  width: 400px;
  margin-left: 4rem;
}
.input {
  padding: 1rem;
}
.text-input {
  background: none;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  width: 365px;
  height: 40px;
  border-bottom: 3px solid #472bff;
}
.list_tags {
  width: 365px;
  height: auto;
  list-style: none;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  li {
    padding: 20px;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      color: #fff;
      background-color: #472bff;
    }
  }
}
.tags {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  .tag {
    margin: 5px;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #472bff;
    border-radius: 5px;
    .remove_tag_btn {
      border: none;
      background: none;
      outline: none;
      color: #fff;
      font-size: 18px;
      transition: all 300ms ease-in-out;
      &:hover {
        cursor: pointer;
        color: rgb(250, 82, 82);
      }
    }
  }
}
.choose_file label {
  display: block;
  border: 2px dashed #472bff;
  padding: 15px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.choose_file #choose_file {
  outline: none;
  opacity: 0;
  width: 0;
}
.choose_file span {
  font-size: 14px;
  color: #472bff;
}
.choose_file label:hover span {
  text-decoration: underline;
}
.drop {
  width: 365px;
  height: 100px;
  color: #1f2833;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    width: 100%;
    height: 40px;
    transition: all 300ms ease-in-out;
  }
  .item:hover {
    cursor: pointer;
    color: #472bff;
  }
}
.err,
.location_input_label {
  color: rgb(250, 82, 82);
}
.submit {
  width: 365px;
  height: 40px;
  background: none;
  border: none;
  outline: none;
  background-color: #472bff;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
/************************ Manage Working Hours ************************/
.a {
  display: flex;
  justify-content: center;
}
.days {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
}
.day {
  width: 100px;
  height: 40px;
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: 600;
  transition: all 300ms ease;
}
.day:hover {
  cursor: pointer;
}
.time_input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.b {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}
.time {
  width: 70px;
  height: 40px;
  background: none;
  border: none;
  outline: none;
  background-color: #fff;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  font-size: 17px;
  border-radius: 10px;
  border: 2px solid #472bff;
  transition: all 300ms ease-in-out;
}

.dropbtn {
  background-color: #fff;
  color: #1f2833;
  width: 50px;
  height: 50px;
  font-size: 16px;
  border: none;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: all 300ms ease-in-out;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.dropdown-content div:hover {
  cursor: pointer;
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: #fff;
  background-color: #472bff;
}
/************************ Manage Working Hours ************************/
</style>
