<template>
  <div class="p">
    <!--=============== Navbar ===============-->
    <div class="nav">
      <NavBar />
    </div>
    <!--=============== Navbar ===============-->
    <!--=============== Trending Places ===============-->
    <h1 class="section_title">{{ $t("placesPage.Title1") }}</h1>
    <div class="trend">
      <div class="places">
        <div class="place" v-for="place in Trending" v-bind:key="place._id">
          <router-link :to="`/${$i18n.locale}/Place/${place._id}`">
            <div class="place_image">
              <img
                :src="`${url}/uploads/${place.Images[0]}`"
                v-if="place.Images.length != 0"
              />
            </div>
            <div class="place_content">
              <div class="name">{{ place.Name }}</div>
              <div class="Likes">
                <i class="fa-regular fa-heart"></i> : {{ place.Likes }}
              </div>
            </div>
            <div class="place_content2">
              <div>
                {{ $t("placesPage.priceRange") }} {{ place.PriceRange }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!--=============== Trending Places ===============-->

    <!--=============== Places ===============-->
    <div class="places_system">
      <div class="menus_side">
        <div class="center_menus">
          <h2 class="section_title1">{{ $t("placesPage.searchInput") }}</h2>
          <div class="d">
            <div class="dropdown">
              <div class="showCities" v-on:click="openDropdown()">
                {{ $t("placesPage.selectCity") }} {{ City.Name }}
              </div>
              <div class="cities_list" v-if="Citydropdown">
                <div
                  class="city"
                  v-for="city in Cities"
                  v-bind:key="city._id"
                  v-on:click="slectCity(city)"
                >
                  {{ city.Name }}
                </div>
              </div>
            </div>
          </div>
          <div class="d">
            <div class="dropdown">
              <div class="showCategories" v-on:click="openCategoriesDropdown()">
                {{ $t("placesPage.selectCategory") }}
                <span v-if="$i18n.locale == 'ar'">{{ Category.NameAR }}</span>
                <span v-else>{{ Category.NameEN }}</span>
              </div>
              <div class="Categories_list" v-if="Categorydropdown">
                <div
                  class="category"
                  v-for="category in Categories"
                  v-bind:key="category._id"
                  v-on:click="slectCategory(category)"
                >
                  <span v-if="$i18n.locale == 'ar'">{{ category.NameAR }}</span>
                  <span v-if="$i18n.locale == 'en'">{{ category.NameEN }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="d">
            <div class="dropdown">
              <div class="showTags" v-on:click="openTagsDropdown()">
                {{ $t("placesPage.selectTag") }}
              </div>
              <div class="Tags_list" v-if="Tagsdropdown">
                <div
                  class="Tag"
                  v-for="Tag in Tags"
                  v-bind:key="Tag._id"
                  v-on:click="slectTag(Tag.NameEN, Tag.NameAR)"
                >
                  <span v-if="$i18n.locale == 'ar'">{{ Tag.NameAR }}</span>
                  <span v-if="$i18n.locale == 'en'">{{ Tag.NameEN }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="dropdown">
            <button class="filterBtn" v-on:click="filter()">ابحث</button>
          </div>
        </div>
      </div>
      <div class="places_side">
        <div class="info">
          <h2 class="section_title1">{{ $t("placesPage.Places") }}</h2>
          <h4 class="section_title1">
            {{ $t("placesPage.Places") }} : {{ Places.length }}
          </h4>
          <h4 class="section_title1">
            {{ $t("placesPage.city") }} {{ City.Name }}
            <span v-if="City.Name != undefined">
              <button class="deselectCategory" v-on:click="deSelectCity()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </span>
            {{ $t("placesPage.categories") }}
            <span
              v-for="category in FilterCategories"
              v-bind:key="category._id"
            >
              <span v-if="$i18n.locale == 'en'">
                {{ category.NameEN }}
                <button
                  class="deselectCategory"
                  v-on:click="deSelectCategory(category._id)"
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </span>
              <span v-else>
                {{ category.NameAR }}
                <button
                  class="deselectCategory"
                  v-on:click="deSelectCategory(category._id)"
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </span>
            </span>
            {{ $t("placesPage.tags") }}
            <span v-for="tag in SelectedTags" v-bind:key="tag._id">
              <span v-if="$i18n.locale == 'en'">{{ tag.NameEN }}</span>
              <span v-if="$i18n.locale == 'ar'">{{ tag.NameAR }}</span>
              <button
                class="deselectCategory"
                v-on:click="deSelectTag(tag.NameEN)"
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </span>
          </h4>
        </div>
        <div class="places">
          <div class="place" v-for="place in Places" v-bind:key="place._id">
            <router-link :to="`/${$i18n.locale}/Place/${place._id}`">
              <div class="place_image">
                <img
                  :src="`${url}/uploads/${place.Images[0]}`"
                  v-if="place.Images.length != 0"
                />
              </div>
              <div class="place_content">
                <div class="name">{{ place.Name }}</div>
                <div class="Likes">
                  <i class="fa-regular fa-heart"></i> : {{ place.Likes }}
                </div>
              </div>
              <div class="place_content2">
                <div>
                  {{ $t("placesPage.priceRange") }} {{ place.PriceRange }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Places ===============-->

    <!--=============== Footer ===============-->
    <div class="footer">
      <FooterSide />
    </div>
    <!--=============== Footer ===============-->
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import FooterSide from "../components/FooterSide.vue";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
import jquery from "jquery";
let $ = jquery;
export default {
  name: "PlacesView",
  components: {
    NavBar,
    FooterSide,
  },
  data() {
    return {
      Trending: [],
      Places: [],
      Tags: [],
      Cities: [],
      City: {},
      Citydropdown: false,
      Categories: [],
      Category: {},
      Categorydropdown: false,
      FilterCategories: [],
      SelectedTags: [],
      Tagsdropdown: false,
      query: "",
      url: "",
    };
  },
  async mounted() {
    let page = document.getElementsByClassName("p")[0];
    let title = document.getElementsByClassName("section_title");
    let title2 = document.getElementsByClassName("section_title1");
    if (this.$i18n.locale == "ar") {
      page.style.direction = "rtl";
      for (let i = 0; i < title.length; i++) {
        title[i].classList.add("right_text");
      }
      for (let i = 0; i < title2.length; i++) {
        title2[i].classList.add("right_text");
      }
    }
    this.url = ApiUrl.url;

    this.loadPlaces();

    await axios.get(ApiUrl.url + "/Community/Places/Trending").then((res) => {
      this.Trending = res.data;
    });

    await axios.get(ApiUrl.url + "/Community/Cities/Get").then((res) => {
      this.Cities = res.data;
    });

    await axios.get(ApiUrl.url + "/Community/Categories/Get").then((res) => {
      this.Categories = res.data;
    });

    await axios.get(ApiUrl.url + "/Community/Tags/Get").then((res) => {
      this.Tags = res.data;
    });

    await axios
      .post(ApiUrl.url + "/Community/Views", {
        Location: "Places",
        placeId: "",
      })
      .then(() => {})
      .catch(() => {});
  },
  methods: {
    async loadPlaces() {
      await axios.get(ApiUrl.url + "/Community/Places/Get").then((res) => {
        this.Places = res.data;
      });
    },
    openDropdown() {
      if (this.Citydropdown == false) {
        this.Citydropdown = true;
        $(".showCities").addClass("remove_bottom_border");
      } else {
        this.Citydropdown = false;
        $(".showCities").removeClass("remove_bottom_border");
      }
    },
    slectCity(City) {
      this.City = City;
      this.Citydropdown = false;
      $(".showCities").removeClass("remove_bottom_border");
      this.filterByCity();
    },
    deSelectCity() {
      this.City = {};
      this.loadPlaces();
    },
    openCategoriesDropdown() {
      if (this.Categorydropdown == false) {
        this.Categorydropdown = true;
        $(".showCategories").addClass("remove_bottom_border");
      } else {
        this.Categorydropdown = false;
        $(".showCategories").removeClass("remove_bottom_border");
      }
    },
    slectCategory(Category) {
      this.Category = Category;
      this.Categorydropdown = false;
      $(".showCategories").removeClass("remove_bottom_border");
      if (this.searchC(Category) == -1) {
        this.FilterCategories.push(Category);
        this.filterByCats();
      }
    },
    searchC(Category) {
      for (let i = 0; i < this.FilterCategories.length; i++) {
        if (this.FilterCategories[i].NameEN == Category.NameEN) {
          return 1;
        }
      }
      return -1;
    },
    deSelectCategory(id) {
      if (this.FilterCategories.length == 1) {
        this.loadPlaces();
        for (let i = 0; i < this.FilterCategories.length; i++) {
          if (this.FilterCategories[i]._id == id) {
            this.FilterCategories.splice(i, 1);
          }
        }
        return;
      }
      for (let i = 0; i < this.FilterCategories.length; i++) {
        if (this.FilterCategories[i]._id == id) {
          this.FilterCategories.splice(i, 1);
          this.filterByCats();
        }
      }
    },
    openTagsDropdown() {
      if (this.Tagsdropdown == false) {
        this.Tagsdropdown = true;
        $(".showTags").addClass("remove_bottom_border");
      } else {
        this.Tagsdropdown = false;
        $(".showTags").removeClass("remove_bottom_border");
      }
    },
    slectTag(TagNameEN, TagNameAR) {
      let obj = { NameEN: TagNameEN, NameAR: TagNameAR };
      if (this.searchT(obj) == -1) {
        this.SelectedTags.push(obj);
        this.filterByTags();
      }
    },
    searchT(Tag) {
      for (let i = 0; i < this.SelectedTags.length; i++) {
        if (this.SelectedTags[i].NameEN == Tag.NameEN) {
          return 1;
        }
      }
      return -1;
    },
    deSelectTag(tagNameEN) {
      for (let i = 0; i < this.SelectedTags.length; i++) {
        if (this.SelectedTags[i].NameEN == tagNameEN) {
          this.SelectedTags.splice(i, 1);
          this.filterByTags();
        }
      }
    },
    async filterByCity() {
      await axios
        .get(
          ApiUrl.url +
            `/Community/Places/filter?cityId=${this.City._id}&ByCities=true`
        )
        .then((res) => {
          console.log(res);
          this.Places = res.data;
        });
    },
    async filterByCats() {
      let catsId = "";
      for (let i = 0; i < this.FilterCategories.length; i++) {
        catsId += this.FilterCategories[i]._id + ",";
      }
      await axios
        .get(ApiUrl.url + `/Community/Places/filter?cats=${catsId}&ByCats=true`)
        .then((res) => {
          this.Places = res.data;
        });
    },
    async filterByTags() {
      let tags = "";
      for (let i = 0; i < this.SelectedTags.length; i++) {
        if (i == this.SelectedTags.length - 1) {
          tags += this.SelectedTags[i].NameEN;
        } else {
          tags += this.SelectedTags[i].NameEN + ",";
        }
      }
      await axios
        .get(ApiUrl.url + `/Community/Places/filter?tags=${tags}&ByTags=true`)
        .then((res) => {
          this.Places = res.data;
        });
    },
    async removeTag() {
      axios.get(ApiUrl.url + "/Community/getPlaces").then((res) => {
        this.Places = res.data.Places;
      });
    },
    async search() {
      await axios
        .get(ApiUrl.url + `/Community/searchPlaces/${this.query}`)
        .then((res) => {
          this.Places = res.data;
        });
    },
    async getPlaceFromTags(Tag) {
      await axios
        .get(ApiUrl.url + `/Community/getPlaceTag/${Tag}`)
        .then((res) => {
          this.Places = res.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.p {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.nav {
  height: 80px;
  z-index: 3000;
}
.footer {
  margin-top: auto;
}
.section_title1 {
  font-weight: 700;
  padding-bottom: 1rem;
}
/************************ Trending places ************************/
.section_title {
  font-weight: 700;
  padding: 3rem;
  padding-bottom: 1rem;
}
.trend {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}
.places {
  display: grid;
  grid-template-columns: auto;
}
/************************ Trending places ************************/
.places_system {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  .menus_side {
    width: 20%;
    height: 400px;
  }
  .places_side {
    width: 80%;
    border-right: 1px solid #1f2833;
    min-height: 70vh;
  }
}
.menus_side {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.dropdown {
  padding-bottom: 1rem;
}
.showCities,
.showCategories,
.showTags {
  width: 300px;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}
.remove_bottom_border {
  border-bottom: none;
}
.cities_list,
.Categories_list,
.Tags_list {
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  width: 320px;
  height: 90px;
  overflow: auto;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}
.city,
.category,
.Tag {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #eeeeee;
    background-color: #472bff;
  }
}
.city:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.category:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.info {
  padding-right: 2rem;
}
.deselectCategory {
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #472bff;
  }
}
.filterBtn {
  width: 320px;
  height: 45px;
  background: none;
  border: none;
  outline: none;
  background-color: #472bff;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
@media screen and (max-width: 450px) {
  .places_system {
    .menus_side {
      width: 100%;
    }
    .places_side {
      width: 100%;
    }
  }
  .d {
    display: flex;
    justify-content: center;
  }
}
/************************ Search for a Place ************************/
.searchP {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/welcome.jpg);
  background-position: center;
}
.sea {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(16, 9, 143, 0.603) 0%,
    rgba(13, 13, 203, 0.397) 55%,
    rgba(0, 106, 255, 0.164) 100%
  );
}
.search_title {
  padding: 20px;
  font-weight: 700;
  font-size: 22px;
  color: #eeeeee;
}
.search_engine {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_input {
  border: none;
  background: none;
  outline: none;
  width: 300px;
  height: 45px;
  background-color: #eeeeee;
  color: #1f2833;
  font-weight: 700;
  font-size: 16px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
::placeholder {
  color: #1f2833;
  font-weight: 700;
}
.search_button {
  background-color: #472bff; // ffff15
  color: #eeeeee;
  border: none;
  outline: none;
  width: 40px;
  height: 45px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 220ms ease-in-out;
}
.search_button:hover {
  cursor: pointer;
  background-color: #3721c5; // e2e211
}
/************************ Places ************************/
.Dtitle {
  margin: 4rem;
  margin-bottom: 0;
  text-align: left;
}
.t {
  display: flex;
  justify-content: center;
}
.tags {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 40px;
}
.tag {
  display: inline-block;
  background-color: #472bff;
  padding: 15px;
  border-radius: 5px;
  color: #fff;
}
.displayP {
  margin: 3rem;
  text-align: left;
  display: flex;
  justify-content: center;
}
.places {
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto auto;
  margin-top: 2rem;
  gap: 40px;
}
.place {
  width: 350px;
  height: 400px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 300ms ease-out;
  border-bottom: 4px solid #472bff;
}
.place_image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  img {
    border-radius: 40px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    height: 100%;
  }
}
.place_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem;
  font-weight: 800;
}
.place_content2 {
  display: flex;
  justify-content: space-around;
  font-weight: 800;
}
.place:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 1543px) {
  .places {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1160px) {
  .places {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 786px) {
  .places {
    display: grid;
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 430px) {
  .search_input {
    width: 200px;
  }
}
/************************ Places ************************/
@media screen and (max-width: 1620px) {
  .places_system {
    display: block;
    margin: 0;
  }
  .menus_side {
    display: block;
  }
  .places_side,
  .menus_side {
    width: 100%;
  }
}
/************************ lang class ************************/
.right_text {
  text-align: right;
}
/************************ lang class ************************/
</style>
