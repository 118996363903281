<template>
  <div class="p">
    <div class="nav">
      <NavBar />
    </div>
    <!--=============== Search for a Category ===============-->
    <div class="searchC" id="SearchCategories">
      <div class="sea">
        <div class="search_title">{{ $t("CategoriesPage.searchTitle") }}</div>
        <div class="search_engine">
          <input
            type="text"
            :placeholder="$i18n.t('CategoriesPage.searchInput')"
            v-model="query"
            class="search_input"
          />
          <button class="search_button" v-on:click="search()">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </div>
    <!--=============== Display Categories ===============-->
    <h1 class="Dtitle">{{ $t("CategoriesPage.Title") }}</h1>
    <div class="displayC" id="DisplayCategories">
      <div class="boxes">
        <div
          class="box"
          v-for="category in Categories"
          v-bind:key="category._id"
        >
          <div class="box-content">
            <router-link
              :to="`Category/${category._id}`"
              class="a category_link"
            >
              <div class="box-icon" v-html="category.Image"></div>
              <span class="num">{{ category.numberOfPlaces }}</span>
              <p class="content" v-if="$i18n.locale == 'en'">
                {{ category.NameEN }}
              </p>
              <p class="content" v-else>{{ category.NameAR }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Footer ===============-->
    <div class="footer">
      <FooterSide />
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import FooterSide from "../components/FooterSide.vue";
import axios from "axios";
import ApiUrl from "@/ApiUrl";

export default {
  name: "CategoriesView",
  data() {
    return {
      Categories: [],
      query: "",
    };
  },
  components: {
    NavBar,
    FooterSide,
  },
  async mounted() {
    let page = document.getElementsByClassName("p")[0];
    let title = document.getElementsByClassName("Dtitle")[0];
    if (this.$i18n.locale == "ar") {
      page.style.direction = "rtl";
      title.classList.add("right_text");
    }
    this.loadCategories();
  },
  methods: {
    async loadCategories() {
      await axios
        .get(ApiUrl.url + "/Community/Categories/Get")
        .then((res) => {
          this.Categories = res.data;
        })
        .catch((err) => {
          if (err) {
            location.replace("/");
          }
        });
    },
    async search() {
      if (this.query != "") {
        await axios
          .get(ApiUrl.url + `/Community/searchCategories/${this.query}`)
          .then((res) => {
            this.Categories = res.data;
          });
      } else {
        this.loadCategories();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.nav {
  height: 80px;
  z-index: 3000;
}
.footer {
  margin-top: auto;
}

/************************ Search for a Category ************************/
.searchC {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/welcome.jpg);
  background-position: center;
}
.sea {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(16, 9, 143, 0.603) 0%,
    rgba(13, 13, 203, 0.397) 55%,
    rgba(0, 106, 255, 0.164) 100%
  );
}
.search_title {
  padding: 20px;
  font-weight: 700;
  font-size: 22px;
  color: #eeeeee;
}
.search_engine {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_input {
  border: none;
  background: none;
  outline: none;
  width: 300px;
  height: 45px;
  background-color: #eeeeee;
  color: #1f2833;
  font-weight: 700;
  font-size: 16px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
::placeholder {
  color: #1f2833;
  font-weight: 700;
}
.search_button {
  background-color: #472bff; // ffff15
  color: #eeeeee;
  border: none;
  outline: none;
  width: 40px;
  height: 45px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 220ms ease-in-out;
}
.search_button:hover {
  cursor: pointer;
  background-color: #3721c5; // e2e211
}
/************************ Display Categories ************************/
.Dtitle {
  text-align: left;
  margin: 3rem;
  margin-bottom: 1.2rem;
}
.displayC {
  margin: 3rem;
  margin-top: 0rem;

  display: flex;
  justify-content: center;
}
.boxes {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 1rem;
}
.box {
  margin: 1rem;
  width: 350px;
  height: 300px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  transition: all 300ms ease-in-out;
}
.box-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  color: #eeeeee;
  background-color: #472bff;
  border-radius: 100px;
  transform: translateY(-30px);
  transition: all 300ms ease-in-out;
  font-size: 42px;
}
.num {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 30px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
  font-weight: 700;
  font-size: 22px;
}
.box:hover {
  cursor: pointer;
  color: #eeeeee;
  background-color: #472bff;
  transform: translateY(-15px);
  .box-icon {
    background-color: #fff;
    color: #472bff;
  }
  .category_link {
    color: #fff;
  }
}
@media screen and (max-width: 1536px) {
  .boxes {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1160px) {
  .boxes {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 786px) {
  .boxes {
    display: grid;
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 430px) {
  .search_input {
    width: 200px;
  }
}
.right_text {
  text-align: right;
}
/************************ Display Categories ************************/
</style>
