<template>
  <div class="p">
    <!--=============== Navbar ===============-->
    <div class="nav">
      <NavBar />
    </div>
    <!--=============== Navbar ===============-->
    <!--=============== Category Info ===============-->
    <div class="page_header">
      <div class="category_info">
        <h1 class="DTitle">{{ CategoryName }}</h1>
        <h2 v-html="CategoryImage" class="cIcon"></h2>
      </div>
      <div class="search_engine">
        <input
          type="text"
          :placeholder="$t('Category.search')"
          class="search_input"
        />
        <button class="search_btn">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
    <!--=============== Category Info ===============-->
    <!--=============== Display Places ===============-->
    <div class="displayP" id="DisplayPlaces">
      <div class="places">
        <div class="place" v-for="place in Places" v-bind:key="place._id">
          <router-link :to="`/${$i18n.locale}/Place/${place._id}`">
            <div class="place_image">
              <img
                :src="`${url}/uploads/${place.Images[0]}`"
                v-if="place.Images.length != 0"
              />
            </div>
            <div class="place_content">
              <div class="name">{{ place.Name }}</div>
              <div class="Likes">
                <i class="fa-regular fa-heart"></i> : {{ place.Likes }}
              </div>
            </div>
            <div class="place_content2">
              <div>
                {{ $t("HomePage.Trending.priceRange") }} :
                {{ place.PriceRange }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!--=============== Display Places ===============-->
    <!--=============== Footer ===============-->
    <div class="footer">
      <FooterSide />
    </div>
    <!--=============== Footer ===============-->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterSide from "@/components/FooterSide.vue";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
export default {
  name: "CategoryView",
  components: {
    NavBar,
    FooterSide,
  },
  metaInfo: {
    meta: [{ charset: "utf-8" }, { name: "description", content: "TEST META" }],
  },
  data() {
    return {
      CategoryName: "",
      CategoryImage: "",
      Places: [],
      currentindex: 0,
      url: "",
    };
  },
  async mounted() {
    this.url = ApiUrl.url;
    let page = document.getElementsByClassName("p")[0];
    let title = document.getElementsByClassName("category_info")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      title.classList.add("left_text");
    }
    await axios
      .get(ApiUrl.url + `/Community/Get/Places/${this.$route.params.id}`)
      .then((res) => {
        if (this.$i18n.locale == "en") {
          this.CategoryName = res.data.category.NameEN;
        } else {
          this.CategoryName = res.data.category.NameAR;
        }
        this.CategoryImage = res.data.category.Image;
        this.Places = res.data.places;
      });
    await axios
      .post(ApiUrl.url + "/Community/Views", {
        Location: "Category",
        placeId: "",
      })
      .then(() => {})
      .catch(() => {});
  },
};
</script>

<style lang="scss" scoped>
.p {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  direction: rtl;
}
.nav {
  height: 80px;
  z-index: 3000;
}
.footer {
  margin-top: auto;
}
/************************ Category Info ************************/
.page_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
}
.category_info {
  display: flex;
  justify-content: right;
  align-items: center;
}
.left_text {
  justify-content: left;
}
.cIcon {
  font-size: 38px;
}
.search_engine {
  display: flex;
  justify-content: left;
  align-items: center;
}
.search_input {
  outline: none;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1.2px solid rgb(162, 162, 162);
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  outline: none;
}
.search_btn {
  border: none;
  outline: none;
  background: none;
  width: 40px;
  height: 42px;
  background-color: #472bff;
  color: #fff;
  border-radius: 5px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3c25cf;
  }
}
.search_input::placeholder {
  font-weight: bold;
}
@media screen and (max-width: 585px) {
  .page_header {
    display: block;
  }
  .category_info {
    justify-content: center;
  }
  .search_engine {
    justify-content: center;
  }
}
/************************ Category Info ************************/
/************************ Display Places ************************/
.DTitle {
  font-weight: bold;
  margin: 4rem;
}
.displayP {
  margin: 3rem;
  text-align: left;
  display: flex;
  justify-content: center;
}
.places {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 2rem;
}
.place {
  margin: 1rem;
  width: 350px;
  height: 400px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 300ms ease-out;
  border-bottom: 4px solid #472bff;
}
.place_image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  img {
    border-radius: 40px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    height: 100%;
  }
}
.place_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem;
}
.place_content2 {
  display: flex;
  justify-content: space-around;
}
.place:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 1543px) {
  .places {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1160px) {
  .places {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 786px) {
  .places {
    display: grid;
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 390px) {
  .place {
    transform: scale(0.9);
  }
}
/************************ Display Places ************************/
</style>
