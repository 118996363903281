<template>
  <div class="home">
    <!--============== Header ==============-->
    <div class="nav">
      <NavBar />
    </div>
    <!--============== Welcome Section ==============-->
    <div class="welcome">
      <div class="left_part">
        <div class="left_part_content">
          <div class="ll">
            <div class="title1">
              <h1>
                {{ $t("HomePage.welcome.LeftPart.Title1") }}
              </h1>
            </div>
            <div class="title1_description">
              {{ $t("HomePage.welcome.LeftPart.content1") }}
            </div>
            <div class="plan_btn">
              <button class="make_plan_btn" v-on:click="CreateAplan()">
                {{ $t("HomePage.welcome.LeftPart.btn") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="right_part">
        <div class="right_part_content">
          <div class="cont">
            <h1>{{ $t("HomePage.welcome.rightPart.Title1") }}</h1>
          </div>
        </div>
      </div>
    </div>
    <!--============== Numbers Section ==============-->
    <div class="numbers_section" id="numbers">
      <h2>{{ $t("HomePage.Numbers.Title") }}</h2>
      <div class="boxes">
        <div
          class="box"
          v-for="category in Categories"
          v-bind:key="category._id"
        >
          <div class="box-content">
            <router-link
              :to="`/${$i18n.locale}/Category/${category._id}`"
              class="a category_link"
            >
              <div class="box-icon" v-html="category.Image"></div>
              <span class="num">{{ category.numberOfPlaces }}</span>
              <p class="content" v-if="$i18n.locale == 'en'">
                {{ category.NameEN }}
              </p>
              <p class="content" v-else>{{ category.NameAR }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!--============== About us Section ==============-->
    <h1 class="section_title">{{ $t("HomePage.aboutus.title") }}</h1>
    <div class="aboutus_section" id="aboutus">
      <div>
        <div class="sec1">
          <div class="image">
            <img src="../assets/Vision.svg" />
          </div>
          <div class="aboutus-content">
            <h2 class="sub_title">{{ $t("HomePage.aboutus.ourService") }}</h2>
            <article>
              <p>
                {{ $t("HomePage.aboutus.OurServiceDescription") }}
                <!-- available both as a user-friendly website and a convenient app,
                is your ultimate destination for seamless location discovery and
                stress-free outings. On our website, you'll have effortless
                access to a diverse array of places, allowing you to search for
                your favorites or stumble upon hidden gems. Meanwhile, our app
                takes the hassle out of planning outings with friends. It crafts
                personalized outing plans, ensuring you never have to worry
                about deciding where to dine or find entertainment. With us,
                every adventure is a well-organized, memorable experience. Join
                us on a journey of exploration, one click away. -->
              </p>
            </article>
          </div>
        </div>
        <div class="sec1">
          <div class="aboutus-content">
            <h2 class="sub_title">{{ $t("HomePage.aboutus.Goals") }}</h2>
            <article>
              <p>
                {{ $t("HomePage.aboutus.GoalsDescription") }}
                <!-- Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam
                fugit aliquid dignissimos architecto in quibusdam maxime, sed ab
                assumenda vero. -->
              </p>
            </article>
          </div>
          <div class="image">
            <img src="../assets/Goals.svg" />
          </div>
        </div>
      </div>
    </div>
    <!--============== Trending Section ==============-->
    <div class="trending_section" id="trending">
      <h2>{{ $t("HomePage.Trending.TrendingPlaces") }}</h2>
      <div class="places">
        <div class="place" v-for="place in Places" v-bind:key="place._id">
          <router-link :to="`/${$i18n.locale}/Place/${place._id}`">
            <div class="place_image">
              <img
                :src="`${url}/uploads/${place.Images[0]}`"
                v-if="place.Images.length > 0"
              />
            </div>
            <div class="place_content">
              <div class="name">{{ place.Name }}</div>
              <div class="Likes">
                <i class="fa-regular fa-heart"></i> : {{ place.Likes }}
              </div>
            </div>
            <div class="place_content2">
              <div>
                {{ $t("HomePage.Trending.priceRange") }} :
                {{ place.PriceRange }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="footer_section" id="footer">
      <FooterSide />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import FooterSide from "../components/FooterSide.vue";
import ApiUrl from "@/ApiUrl";
// import jquery from "jquery";
// let $ = jquery;
export default {
  name: "HomeView",
  data() {
    return {
      Categories: [],
      Places: [],
      isLoggedIn: false,
      isAdminLoggedIn: false,
      url: "",
    };
  },
  async mounted() {
    this.isLoggedIn = await this.$store.getters.isLoggedIn;
    this.isAdminLoggedIn = await this.$store.getters.isAdmin;
    this.url = ApiUrl.url;
    let page = document.getElementsByClassName("home")[0];
    let title = document.getElementsByClassName("title1")[0];
    let content = document.getElementsByClassName("title1_description")[0];
    let section_title = document.getElementsByClassName("sub_title");
    let check_tag = document.getElementsByClassName("fa-check");
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      for (let i = 0; i < check_tag.length; i++) {
        check_tag[i].classList.add("check_margin");
      }
    } else {
      title.classList.add("right_text");
      content.classList.add("right_text");
      for (let i = 0; i < section_title.length; i++) {
        section_title[i].classList.add("right_text");
      }
    }

    await axios
      .post(ApiUrl.url + "/Community/Views", { Location: "Home", placeId: "" })
      .then(() => {})
      .catch(() => {});

    await axios.get(ApiUrl.url + "/Community/Categories/Get").then((res) => {
      this.Categories = res.data;
    });
    await axios.get(ApiUrl.url + "/Community/Places/Trending").then((res) => {
      this.Places = res.data;
    });
  },
  components: { NavBar, FooterSide },
  methods: {
    goToRegister() {
      if (this.isLoggedIn == false) {
        this.$router.push({ name: "Signup" });
      }
    },
    CreateAplan() {
      this.$router.push({ name: `CreatePlan` });
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
function reveal1() {
  var reveals = document.querySelectorAll(".box");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
function reveal2() {
  var reveals = document.querySelectorAll(".sec1");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active1");
    } else {
      reveals[i].classList.remove("active1");
    }
  }
}
window.addEventListener("scroll", reveal1);
window.addEventListener("scroll", reveal2);
</script>

<style lang="scss" scoped>
* {
  font-weight: 700;
}
h2 {
  color: #000;
}
a {
  transition: all 300ms ease-in-out;
}
.home {
  direction: rtl;
}
.section_title {
  padding-top: 4rem;
}
/******************* Welcome Section *******************/
.welcome {
  display: flex;
  align-items: center;
  width: 100%;
  // min-height: 100vh;
  .left_part,
  .right_part {
    width: 50%;
  }
}
.left_part {
  background-color: #472bff;
  height: 100vh;
}
.left_part_content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title1 {
  width: 330px;
  h1 {
    text-align: left;
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
  }
}
.title1_description {
  width: 330px;
  text-align: left;
  color: rgb(168, 168, 168);
  margin-top: 1rem;
}
.plan_btn {
  margin-top: 2rem;
}
.make_plan_btn {
  background-color: none;
  border: none;
  width: 330px;
  height: 50px;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  background: rgb(0, 186, 148);
  border-radius: 10px;
  transition: all 250ms ease-in-out;
  &:hover {
    cursor: pointer;
    background: rgb(0, 153, 122);
  }
}
.right_text {
  text-align: right;
}
.right_part_content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.cont {
  height: 193px;
}
@media screen and (max-width: 675px) {
  .welcome {
    display: block;
    .left_part,
    .right_part {
      width: 100%;
    }
  }
  .left_part {
    height: 60vh;
  }
  .cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/******************* Numbers Section *******************/
.numbers_section {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.boxes {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 2.5rem;
}
.box {
  margin: 1rem;
  width: 350px;
  height: 300px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  opacity: 0;
  display: flex;
  justify-content: center;
  transition: all 300ms ease-in-out;
}
.active {
  opacity: 1;
}
.box-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  color: #eeeeee;
  background-color: #472bff;
  border-radius: 100px;
  transform: translateY(-30px);
  transition: all 300ms ease-in-out;
  font-size: 42px;
}
.num {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 30px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
  font-weight: 700;
  font-size: 22px;
}
.box:hover {
  cursor: pointer;
  color: #eeeeee;
  background-color: #472bff;
  transform: translateY(-15px);
  .box-icon {
    background-color: #fff;
    color: #472bff;
  }
  .category_link {
    color: #fff;
  }
}
@media screen and (max-width: 1536px) {
  .boxes {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1160px) {
  .boxes {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 786px) {
  .boxes {
    display: grid;
    grid-template-columns: auto;
  }
  .box {
    transform: scale(0.9);
  }
}
/******************* About us Section *******************/
.aboutus_section {
  margin-top: 2rem;
}
.sec1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  opacity: 0;
  transition: all 300ms ease-in-out;
}
.active1 {
  opacity: 1;
}
.image {
  width: 400px;
  height: 400px;
}
.aboutus-content {
  width: 400px;
  text-align: left;

  article {
    text-align: justify;
    color: #bbbbbb;
  }
}
@media screen and (max-width: 920px) {
  .aboutus_section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sec1 {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .image {
    width: 300px;
    height: 300px;
  }
  .aboutus-content {
    width: 300px;
    text-align: left;
  }
}
/******************* Trending Section *******************/
.trending_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    margin-top: 2rem;
  }
}
.places {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 2rem;
}
.place {
  margin: 1rem;
  width: 350px;
  height: 400px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  transition: all 300ms ease-out;
}
.place_image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  img {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    width: 100%;
    height: 100%;
  }
}
.place_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem;
}
.place_content2 {
  display: flex;
  justify-content: space-around;
}
.place:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 1543px) {
  .places {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1160px) {
  .places {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 786px) {
  .places {
    display: grid;
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 400px) {
  .place {
    transform: scale(0.9);
  }
}
/******************* Plans Section *******************/
.plans_section {
  margin-top: 2rem;
  flex-direction: column;
}
.plans {
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.plan {
  width: 400px;
  height: 500px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}
.plan-title {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscribe {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign_up_button_plan {
  width: 265px;
  height: 60px;
  background: none;
  outline: none;
  border: none;
  background: #472bff;
  color: #eeeeee;
  font-size: 18px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-in-out;
}
.sign_up_button_plan:hover {
  cursor: pointer;
  background-color: #3721c5;
}
.features {
  height: 330px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    list-style: none;
    li {
      display: flex;
      align-items: center;
      margin: 2rem;
      i {
        margin-left: 20px;
        color: #3fff05;
      }
    }
    li:last-child {
      justify-content: center;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.check_margin {
  margin-right: 20px;
  margin-left: 0px;
}
@media screen and (max-width: 860px) {
  .plans_section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .plans {
    display: block;
  }
  .plan {
    margin: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .plan {
    width: 300px;
  }
}
/******************* footer Section *******************/
.footer_section {
  margin-top: 3rem;
}
</style>
