<template>
  <div>
    <div class="nav">
      <AdminNavbar />
    </div>

    <!--======================= Display Reviews =======================-->
    <div class="r">
      <div class="reviews">
        <div class="review" v-for="review in Reviews" v-bind:key="review._id">
          {{ review.Comment }}
          <button class="btn" v-on:click="deleteReview(review._id)">
            <i class="fa-solid fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <!--======================= Display Reviews =======================-->
  </div>
</template>

<script>
import AdminNavbar from "../../components/AdminNav.vue";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
export default {
  components: {
    AdminNavbar,
  },
  data() {
    return {
      Reviews: [],
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    await axios
      .get(ApiUrl.url + `/Admin/Get/Reviews/${this.$route.params.id}`, {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.Reviews = res.data;
      });
  },
  methods: {
    async deleteReview(id) {
      await axios
        .delete(ApiUrl.url + `/Admin/Del/Review/${id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Review Deleted") {
            for (let i = 0; i < this.Reviews.length; i++) {
              if (id == this.Reviews[i]._id) {
                this.Reviews.splice(i, 1);
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.r {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviews {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
}
.review {
  width: 300px;
  padding: 15px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}
.btn {
  background: none;
  outline: none;
  border: none;
  background-color: #472bff;
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 120px;
  transition: all 300ms ease-in-out;
  margin: 1rem;
  &:hover {
    cursor: pointer;
    background-color: #2f1da8;
  }
}
</style>
