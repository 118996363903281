<template>
  <div>
    <AdminNav />

    <!--==================== Numbers and Statistics ====================-->
    <div>
      <h2 class="section_title"></h2>
      <div class="wrapper">
        <div class="boxes">
          <div class="box">
            <div class="left_side">
              <i class="fa-regular fa-heart"></i>
            </div>
            <div class="right_side">
              <div class="box_title">Likes</div>
              <div class="box_number">{{ NumberOfLikes }}</div>
            </div>
          </div>
          <div class="box">
            <div class="left_side">
              <i class="fa-regular fa-eye"></i>
            </div>
            <div class="right_side">
              <div class="box_title">Views</div>
              <div class="box_number">{{ NumberOfViews }}</div>
            </div>
          </div>
          <div class="box">
            <div class="left_side">
              <i class="fa-regular fa-comment"></i>
            </div>
            <div class="right_side">
              <div class="box_title">Reviews</div>
              <div class="box_number">{{ NumberOfReviews }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================== Numbers and Statistics ====================-->

    <div class="tables">
      <!--==================== Views details ====================-->
      <div class="v_table">
        <h1 class="title">Views</h1>
        <div class="views_table">
          <div class="first_row">
            <div class="place_name">Place Name</div>
            <div class="views">Views</div>
          </div>
          <div class="view_row" v-for="view in Views" v-bind:key="view._id">
            <div class="place_name">{{ view.Place.Name }}</div>
            <div class="views">{{ view.Number }}</div>
          </div>
        </div>
      </div>
      <!--==================== Views details ====================-->

      <!--==================== Liked Places ====================-->
      <div class="l_table">
        <h1 class="title">Likes</h1>
        <div class="likes_table">
          <div class="first_row">
            <div class="place_name">Place Name</div>
          </div>
          <div class="like_row" v-for="like in Likes" v-bind:key="like._id">
            <div class="place_name">{{ like.Place.Name }}</div>
          </div>
        </div>
      </div>
      <!--==================== Liked Places ====================-->
      <!--==================== Reviews ====================-->
      <div class="r_table">
        <h1 class="title">Reviews</h1>
        <div class="reviews_table">
          <div class="first_row">
            <div class="place_name">Place Name</div>
            <div class="comment">Comment</div>
          </div>
          <div
            class="review_row"
            v-for="review in Reviews"
            v-bind:key="review._id"
          >
            <div class="place_name">{{ review.Place.Name }}</div>
            <div class="comment">{{ review.Comment }}</div>
          </div>
        </div>
      </div>
      <!--==================== Reviews ====================-->
    </div>
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import AdminNav from "@/components/AdminNav.vue";
import axios from "axios";
export default {
  components: {
    AdminNav,
  },
  data() {
    return {
      NumberOfViews: 0,
      NumberOfLikes: 0,
      NumberOfReviews: 0,
      Views: [],
      Reviews: [],
      Likes: [],
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    await axios
      .get(ApiUrl.url + `/api/User/Get/Views/${this.$route.params.id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        this.Views = await res.data;
        for (let i = 0; i < this.Views.length; i++) {
          this.NumberOfViews += this.Views[i].Number;
        }
      });

    await axios
      .get(ApiUrl.url + `/api/User/Get/Likes/${this.$route.params.id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.NumberOfLikes = res.data.length;
        this.Likes = res.data;
      });

    await axios
      .get(ApiUrl.url + `/api/User/Get/Reviews/${this.$route.params.id}`, {
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.NumberOfReviews = res.data.length;
        this.Reviews = res.data;
      });
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
}
.boxes {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 40px;
}
.section_title {
  text-align: right;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.box {
  width: 320px;
  height: 120px;
  background-color: #1f2833;
  display: flex;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 5px solid #472bff;
}
.left_side {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100%;
  i {
    font-size: 32px;
    color: #472bff;
  }
}
.right_side {
  width: 200px;
  height: 100%;
  color: #eeeeee;
}
.box_title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  font-size: 28px;
}
.box_number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  font-size: 28px;
  color: rgb(170, 170, 170);
}
.tables {
  margin-top: 4rem;
  display: flex;
}
/******************** Views Table ********************/
.v_table {
  text-align: left;
  margin: 1rem;
}
.title {
  margin-left: 3.5rem;
  font-weight: bold;
}
.views_table {
  width: 460px;
  height: 500px;
  margin: 1rem;
  margin-left: 3.5rem;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  background-color: #1f2833;
  overflow-y: auto;
  .first_row {
    height: 80px;
    display: flex;
    border-bottom: 2px solid #bbb;
    color: #bbb;
    .place_name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
      border-right: 2px solid #bbb;
      font-weight: bold;
    }
    .views {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      font-weight: bold;
    }
  }

  .view_row {
    height: 80px;
    display: flex;
    border-bottom: 2px solid #bbb;
    color: #fff;
    .place_name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
      border-right: 2px solid #bbb;
      font-weight: bold;
      color: rgb(250, 82, 82);
    }
    .views {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      color: rgb(250, 82, 82);
      font-weight: bold;
    }
  }
}
/******************** Views Table ********************/
/******************** Likes Table ********************/
.l_table {
  text-align: left;
  margin: 1rem;
}
.title {
  margin-left: 3.5rem;
  font-weight: bold;
}
.likes_table {
  width: 360px;
  height: 500px;
  margin: 1rem;
  margin-left: 3.5rem;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  background-color: #1f2833;
  overflow-y: auto;
  .first_row {
    height: 80px;
    display: flex;
    border-bottom: 2px solid #bbb;
    color: #bbb;
    .place_name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-weight: bold;
    }
  }

  .like_row {
    height: 80px;
    display: flex;
    border-bottom: 2px solid #bbb;
    color: #fff;
    .place_name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-weight: bold;
      color: rgb(250, 82, 82);
    }
  }
}
/******************** Likes Table ********************/
/******************** Reviews Table ********************/
.r_table {
  text-align: left;
  margin: 1rem;
}
.title {
  margin-left: 3.5rem;
  font-weight: bold;
}
.reviews_table {
  width: 660px;
  height: 500px;
  margin: 1rem;
  margin-left: 3.5rem;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  background-color: #1f2833;
  overflow-y: auto;
  .first_row {
    height: 80px;
    display: flex;
    border-bottom: 2px solid #bbb;
    color: #bbb;
    .place_name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      font-weight: bold;
      border-right: 2px solid #bbb;
    }
    .comment {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      font-weight: bold;
    }
  }

  .review_row {
    height: 80px;
    display: flex;
    border-bottom: 2px solid #bbb;
    color: #fff;
    .place_name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      font-weight: bold;
      color: rgb(250, 82, 82);
      border-right: 2px solid #bbb;
    }
    .comment {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      font-weight: bold;
      height: auto;
    }
  }
}
/******************** Reviews Table ********************/
</style>
