import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import i18n from "@/i18n";
// Main ages
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ContactView from "@/views/ContactView.vue";
import MessageUs from "@/views/MessageUs.vue";
// Auth pages
import LoginView from "../views/LoginView.vue";
import SignupView from "../views/SignupView.vue";

// Admin pages
import AdminDashboardView from "../views/Admin/AdminDashboardView.vue";
import ManageCategories from "../views/Admin/ManageCategories.vue";
import ManageCategory from "../views/Admin/ManageCategory.vue";
import ManagePlaces from "../views/Admin/ManagePlaces.vue";
import AcceptOrReject from "../views/Admin/AcceptOrReject.vue";
import AddPlace from "../views/Admin/AddPlace.vue";
import EditPlace from "../views/Admin/EditPlace.vue";
import ManageTags from "../views/Admin/ManageTags.vue";
import AdminSettings from "../views/Admin/AdminSettings.vue";
import ManageReviews from "@/views/Admin/ManageReviews.vue";
import ManageReview from "@/views/Admin/ManageReview.vue";
import ManageUser from "@/views/Admin/ManageUser.vue";
import ManageUsers from "@/views/Admin/ManageUsers.vue";
import ManagePlans from "@/views/Admin/ManagePlans.vue";
import ManagePlan from "@/views/Admin/ManagePlan.vue";

// Admin - Countries
import ManageCountries from "../views/Admin/Countries/ManageCountry.vue";
import ManageCities from "../views/Admin/Cities/ManageCities.vue";

// Community pages
import CategoriesView from "../views/CategoriesView.vue";
import CategoryView from "../views/CategoryView.vue";
import PlaceView from "../views/PlaceView.vue";
import PlacesView from "../views/PlacesView.vue";
import AddNewPlace from "../views/AddNewPlace.vue";

// User pages
import ProfileView from "../views/ProfileView.vue";

// Plan page
import CreatePlan from "@/views/Plans/CreatePlan.vue";
import PlanView from "@/views/PlanView.vue";
import PrivacyView from "@/views/PrivacyView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
      },
      {
        path: "about",
        name: "about",
        component: AboutView,
      },
      {
        path: "PrivacyPolicy",
        name: "Privacy",
        component: PrivacyView,
      },
      {
        path: "Login",
        name: "Login",
        component: LoginView,
        meta: {
          requiresVisitor: true,
        },
      },
      {
        path: "Signup",
        name: "Signup",
        component: SignupView,
        meta: {
          requiresVisitor: true,
        },
      },
      {
        path: "Admin",
        name: "Dashboard",
        component: AdminDashboardView,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManageCategories",
        name: "ManageCategories",
        component: ManageCategories,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManagePlaces",
        name: "ManagePlaces",
        component: ManagePlaces,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/AddPlace",
        name: "AddPlace",
        component: AddPlace,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "AcceptOrReject/:id",
        name: "AcceptOrReject",
        component: AcceptOrReject,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/EditPlace/:id",
        name: "EditPlace",
        component: EditPlace,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManageTags",
        name: "ManageTags",
        component: ManageTags,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManageCategory/:id",
        name: "ManageCategory",
        component: ManageCategory,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/Settings",
        name: "Settings",
        component: AdminSettings,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManageCountries",
        name: "ManageCountries",
        component: ManageCountries,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManageCities",
        name: "ManageCities",
        component: ManageCities,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManageReviews",
        name: "ManageReviews",
        component: ManageReviews,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManageReview/:id",
        name: "ManageReview",
        component: ManageReview,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManageUsers",
        name: "ManageUsers",
        component: ManageUsers,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManageUser/:id",
        name: "ManageUser",
        component: ManageUser,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManagePlans",
        name: "ManagePlans",
        component: ManagePlans,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "Admin/ManagePlan/:id",
        name: "ManagePlan",
        component: ManagePlan,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: ProfileView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "Categories",
        name: "Categories",
        component: CategoriesView,
      },
      {
        path: "Places",
        name: "Places",
        component: PlacesView,
      },
      {
        path: "Category/:id",
        name: "Category",
        component: CategoryView,
      },
      {
        path: "Place/:id",
        name: "Place",
        component: PlaceView,
      },
      {
        path: "AddNewPlace",
        name: "AddNewPlace",
        component: AddNewPlace,
      },
      {
        path: "CreatePlan",
        name: "CreatePlan",
        component: CreatePlan,
      },
      {
        path: "Contact",
        name: "Contact",
        component: ContactView,
      },
      {
        path: "Message",
        name: "Message",
        component: MessageUs,
      },
      {
        path: "Plan/:id",
        name: "Plan",
        component: PlanView,
      },
      {
        path: "*",
        redirect: "/",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const isLoggedIn = store.getters.isLoggedIn;
const isAdmin = store.getters.isAdmin;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (isLoggedIn || isAdmin) {
      next(`/${i18n.locale}`);
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (isAdmin) {
      next();
    } else if (isLoggedIn) {
      next(`/${i18n.locale}/profile`);
    } else {
      next(`/${i18n.locale}`);
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAdmin || isLoggedIn) {
      next();
    } else {
      next(`/${i18n.locale}/Login`);
    }
  } else {
    next();
  }
});

export default router;
