<template>
  <div>
    <div class="nav">
      <AdminNavbar />
    </div>

    <div class="search_engine">
      <div class="search">
        <div class="input">
          <input
            type="text"
            class="text-input"
            placeholder="Full Name or Email"
            v-model="query"
            @keyup.enter="searchUser()"
          />
        </div>
        <div class="search_btn">
          <button class="btn" v-on:click="searchUser()">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="table">
      <div class="table_of_users">
        <div class="first_row">
          <div class="ID">ID</div>
          <div class="FullName">Full Name</div>
          <div class="email">E-mail</div>
          <div class="date">Date</div>
          <div class="del">Delete</div>
          <div class="view">View</div>
        </div>
        <div class="row" v-for="user in Users" v-bind:key="user._id">
          <div class="ID">{{ user._id }}</div>
          <div class="FullName">{{ user.FullName }}</div>
          <div class="email">{{ user.email }}</div>
          <div class="date">{{ user.date.split("T")[0] }}</div>
          <div class="del">
            <button class="btn" v-on:click="deleteUser(user._id)">
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
          <div class="view">
            <button class="btn" v-on:click="viewUser(user._id)">
              <i class="fa-regular fa-eye"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="popup" v-if="!hidden">
      Are you sure ?
      <button class="yes_btn" v-on:click="confirmDelete()">Yes</button>
    </div>
  </div>
</template>

<script>
import AdminNavbar from "../../components/AdminNav.vue";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
export default {
  components: {
    AdminNavbar,
  },
  data() {
    return {
      Users: [],
      query: "",
      hidden: true,
      userId: "",
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      await axios
        .get(ApiUrl.url + "/api/User/Get/All", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.Users = res.data;
        });
    },
    viewUser(id) {
      this.$router.push(`/${this.$i18n.locale}/Admin/ManageUser/${id}`);
    },
    async searchUser() {
      if (this.query == "") {
        this.loadUsers();
        return;
      }
      await axios
        .post(
          ApiUrl.url + "/api/User/Search",
          { query: this.query },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          this.Users = res.data;
        });
    },
    async deleteUser(id) {
      this.hidden = false;
      setTimeout(() => {
        this.hidden = true;
      }, 2500);
      this.userId = id;
    },
    async confirmDelete() {
      await axios
        .delete(ApiUrl.url + `/api/User/Delete/${this.userId}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "User Deleted") {
            this.loadUsers();
            this.hidden = true;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search_engine {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search {
  display: flex;
  justify-content: center;
  align-content: center;
}
.text-input {
  width: 365px;
  height: 40px;
  border: none;
  outline: none;
  background: none;
  border: 2px solid #bbb;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn {
  color: #fff;
  outline: none;
  border: none;
  background: none;
  width: 45px;
  margin-left: 1rem;
  height: 42px;
  border-radius: 5px;
  background-color: #472bff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3422aa;
  }
}
.table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.table_of_users {
  width: 1350px;
  background-color: #1f2833;
  border-radius: 10px;
}
.first_row {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #bbb;
  .ID,
  .FullName,
  .email,
  .date,
  .del,
  .view {
    font-weight: bold;
    color: #bbb;
  }
  .ID {
    width: 20%;
  }
  .FullName {
    width: 25%;
  }
  .email {
    width: 25%;
  }
  .date {
    width: 10%;
  }
  .del {
    width: 10%;
  }
  .view {
    width: 10%;
  }
}
.row {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #bbb;
  .ID,
  .FullName,
  .email,
  .date,
  .del,
  .view {
    font-weight: bold;
    color: #fff;
  }
  .ID {
    width: 20%;
  }
  .FullName {
    width: 25%;
  }
  .email {
    width: 25%;
  }
  .date {
    width: 10%;
  }
  .del {
    width: 10%;
  }
  .view {
    width: 10%;
  }
}
.row {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid #bbb;
  }
  div:last-child {
    border-right: none;
  }
}
.row:last-child {
  border-bottom: none;
}
.btn {
  border: none;
  background: none;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  background-color: #472bff;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #2b1b94;
  }
}
.popup {
  position: absolute;
  bottom: 10%;
  left: 5%;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 320px;
  height: 60px;
  background-color: #eee;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: bold;
}
.yes_btn {
  width: 45px;
  height: 45px;
  border: none;
  background: none;
  outline: none;
  background-color: rgba(29, 226, 29, 0.651);
  color: #fff;
  border-radius: 10px;
  margin-left: 1rem;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: rgba(29, 226, 29, 0.877);
  }
}
</style>
