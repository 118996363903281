<template>
  <div class="createAplan">
    <div class="nav">
      <div class="controls">
        <div class="left">
          <button class="exit-button">
            <!-- <div class="line3"></div> -->
            <!-- <div class="line3"></div> -->
            <!-- <div class="line3"></div> -->
          </button>
        </div>
        <div class="mid">
          <h1 class="title">{{ $t("CreatePlan.Title") }}</h1>
        </div>
        <div class="right">
          <button class="exit-button" v-on:click="backToHome()">
            <div class="line1"></div>
            <div class="line2"></div>
          </button>
        </div>
      </div>
    </div>
    <div class="All_Plan_info"></div>
    <div class="workload">
      <div class="zero" v-if="partZero">
        <div class="title">
          <h1>{{ $t("CreatePlan.zeroQ") }}</h1>
        </div>
        <div class="planNameForm">
          <input
            type="text"
            :placeholder="$t('CreatePlan.PlanName')"
            class="plan_name_input"
            v-model="planName"
          />
        </div>
        <div class="err">
          <h3 class="plan_name_err"></h3>
        </div>
      </div>
      <div class="first" v-if="partOne">
        <div class="firstQ">
          <div class="title">
            <h1>{{ $t("CreatePlan.firstQ_title") }}</h1>
          </div>
        </div>
        <div class="firstQ2">
          <div class="selectCategories">
            <div>
              <input
                type="text"
                :placeholder="$t('CreatePlan.firstQ_title')"
                v-on:keyup="searchCategory()"
                v-model="categoryName"
                class="selectCategoryInput"
                v-on:click="showCategories()"
              />
              <div class="list_categories">
                <div
                  class="category"
                  v-for="category in Categories"
                  v-bind:key="category._id"
                  v-on:click="selectCategory(category)"
                >
                  <span v-if="$i18n.locale == 'ar'">{{ category.NameAR }}</span>
                  <span v-else>{{ category.NameEN }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="firstQ3">
          <div class="categories">
            <div
              class="cat"
              v-for="category in this.SelectedCategories"
              v-bind:key="category._id"
            >
              <div class="catIcon">{{ category.Image }}</div>
              <div class="catName">
                <span v-if="$i18n.locale == 'ar'">{{ category.NameAR }}</span>
                <span v-else>{{ category.NameEN }}</span>
              </div>
              <div class="removeBtn">
                <button v-on:click="removeFromSelected(category)">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="err">
          <h3 id="categories_err"></h3>
        </div>
      </div>
      <div class="second" v-if="partTwo">
        <div class="title">
          <h1>{{ $t("CreatePlan.secondQ_title") }}</h1>
        </div>
        <div class="a">
          <div class="days">
            <div
              class="day"
              id="sunday"
              v-on:click="selectDays('sunday', 1, $t('CreatePlan.day1'))"
            >
              {{ $t("CreatePlan.day1") }}
            </div>
            <div
              class="day"
              id="monday"
              v-on:click="selectDays('monday', 2, $t('CreatePlan.day2'))"
            >
              {{ $t("CreatePlan.day2") }}
            </div>
            <div
              class="day"
              id="tuesday"
              v-on:click="selectDays('tuesday', 3, $t('CreatePlan.day3'))"
            >
              {{ $t("CreatePlan.day3") }}
            </div>
            <div
              class="day"
              id="wednesday"
              v-on:click="selectDays('wednesday', 4, $t('CreatePlan.day4'))"
            >
              {{ $t("CreatePlan.day4") }}
            </div>
            <div
              class="day"
              id="thursday"
              v-on:click="selectDays('thursday', 5, $t('CreatePlan.day5'))"
            >
              {{ $t("CreatePlan.day5") }}
            </div>
            <div
              class="day"
              id="friday"
              v-on:click="selectDays('friday', 6, $t('CreatePlan.day6'))"
            >
              {{ $t("CreatePlan.day6") }}
            </div>
            <div
              class="day"
              id="saturday"
              v-on:click="selectDays('saturday', 7, $t('CreatePlan.day7'))"
            >
              {{ $t("CreatePlan.day7") }}
            </div>
          </div>
        </div>
        <div class="err">
          <h3 id="days_err"></h3>
        </div>
      </div>
      <div class="third" v-if="partThree">
        <div class="from_inputs">
          <div>
            <div>
              <div class="tut">
                <h1>اختار الوقت</h1>
              </div>
              <!--====================== From Input ======================-->
              <div class="from_section">
                <div class="label">
                  <h2>{{ $t("CreatePlan.fromLabel") }}</h2>
                </div>
                <div class="input">
                  <div class="in">
                    <div
                      class="showFromTime"
                      v-on:click="openFromTimeDropdown()"
                    >
                      {{ dfrom }}
                    </div>
                    <div class="from_time_list" v-if="fromTimeDropdown">
                      <div
                        class="time"
                        v-on:click="selectFromTime('1', '1:00')"
                      >
                        1:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('2', '2:00')"
                      >
                        2:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('3', '3:00')"
                      >
                        3:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('4', '4:00')"
                      >
                        4:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('5', '5:00')"
                      >
                        5:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('6', '6:00')"
                      >
                        6:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('7', '7:00')"
                      >
                        7:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('8', '8:00')"
                      >
                        8:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('9', '9:00')"
                      >
                        9:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('10', '10:00')"
                      >
                        10:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('11', '11:00')"
                      >
                        11:00
                      </div>
                      <div
                        class="time"
                        v-on:click="selectFromTime('12', '12:00')"
                      >
                        12:00
                      </div>
                    </div>
                  </div>
                  <div class="dropdown">
                    <button class="dropbtn">{{ dfromTimeType }}</button>
                    <div class="dropdown-content">
                      <div
                        v-on:click="
                          getFromTimeType('am', $t('CreatePlan.timeTypeAM'))
                        "
                      >
                        {{ $t("CreatePlan.timeTypeAM") }}
                      </div>
                      <div
                        v-on:click="
                          getFromTimeType('pm', $t('CreatePlan.timeTypePM'))
                        "
                      >
                        {{ $t("CreatePlan.timeTypePM") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--====================== To Input ======================-->
            <div class="from_section">
              <div class="label">
                <h2>{{ $t("CreatePlan.toLabel") }}</h2>
              </div>
              <div class="input">
                <div class="in">
                  <div class="showToTime" v-on:click="openToTimeDropdown()">
                    {{ dto }}
                  </div>
                  <div class="to_time_list" v-if="toTimeDropdown">
                    <div class="time" v-on:click="selectToTime('1', '1:00')">
                      1:00
                    </div>
                    <div class="time" v-on:click="selectToTime('2', '2:00')">
                      2:00
                    </div>
                    <div class="time" v-on:click="selectToTime('3', '3:00')">
                      3:00
                    </div>
                    <div class="time" v-on:click="selectToTime('4', '4:00')">
                      4:00
                    </div>
                    <div class="time" v-on:click="selectToTime('5', '5:00')">
                      5:00
                    </div>
                    <div class="time" v-on:click="selectToTime('6', '6:00')">
                      6:00
                    </div>
                    <div class="time" v-on:click="selectToTime('7', '7:00')">
                      7:00
                    </div>
                    <div class="time" v-on:click="selectToTime('8', '8:00')">
                      8:00
                    </div>
                    <div class="time" v-on:click="selectToTime('9', '9:00')">
                      9:00
                    </div>
                    <div class="time" v-on:click="selectToTime('10', '10:00')">
                      10:00
                    </div>
                    <div class="time" v-on:click="selectToTime('11', '11:00')">
                      11:00
                    </div>
                    <div class="time" v-on:click="selectToTime('12', '12:00')">
                      12:00
                    </div>
                  </div>
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ dtoTimeType }}</button>
                  <div class="dropdown-content">
                    <div
                      v-on:click="
                        getToTimeType('am', $t('CreatePlan.timeTypeAM'))
                      "
                    >
                      {{ $t("CreatePlan.timeTypeAM") }}
                    </div>
                    <div
                      v-on:click="
                        getToTimeType('pm', $t('CreatePlan.timeTypePM'))
                      "
                    >
                      {{ $t("CreatePlan.timeTypePM") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plan_time">
          <h3>{{ $t("CreatePlan.time") }} {{ displayDay }}</h3>
          <h3>
            ({{ dfrom + " " }}
            <span v-if="from != ''">{{ dfromTimeType }}</span> -
            {{ dto + " " }} <span v-if="to != ''">{{ dtoTimeType }}</span
            >)
          </h3>
          <h3>
            {{ $t("CreatePlan.PlanLength") }} {{ PlanLength }}
            {{ $t("CreatePlan.Hours") }}
            {{ calculateTime() }}
          </h3>
        </div>
        <div class="err">
          <h3 id="time_err"></h3>
        </div>
      </div>
      <div class="fourth" v-if="partFour">
        <div class="tags">
          <div>
            <div class="title">
              <h1>{{ $t("CreatePlan.SelectTag") }}</h1>
            </div>
            <div class="selectTags">
              <div class="showTags" v-on:click="openTagsDropdown()">
                {{ $t("CreatePlan.SelectTag") }}
              </div>
              <div class="Tags_list" v-if="Tagsdropdown">
                <div
                  class="Tag"
                  v-for="Tag in Tags"
                  v-bind:key="Tag._id"
                  v-on:click="selectTag(Tag)"
                >
                  <span v-if="$i18n.locale == 'ar'">{{ Tag.NameAR }}</span>
                  <span v-if="$i18n.locale == 'en'">{{ Tag.NameEN }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sTags">
          <div class="selectedTags">
            <div class="tg" v-for="tag in SelectedTags" v-bind:key="tag._id">
              <span v-if="$i18n.locale == 'ar'">{{ tag.NameAR }}</span>
              <span v-if="$i18n.locale == 'en'">{{ tag.NameEN }}</span>
              <button class="removeTag" v-on:click="removeTag(tag)">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="err">
          <h3 id="tags_err"></h3>
        </div>
      </div>
      <div class="fifth" v-if="partFive">
        <div>
          <div class="timeLine"></div>
          <div
            class="sCats"
            v-for="cat in PlanCategories"
            v-bind:key="Math.random() * 10 * cat.length"
          >
            <div class="rCat" v-if="cat.length != 0">
              <div class="cat_title">
                <h1>
                  {{ cat[0].Category.NameAR }} {{ cat[0].Category.Image }}
                </h1>
              </div>
              <div class="places_in_cat">
                <div
                  v-for="place in cat"
                  v-bind:key="place._id"
                  class="pC"
                  :id="`place-${place._id}`"
                >
                  <div class="placeCat">
                    <div class="place_images">
                      <img
                        :src="`${url}/uploads/${place.Images[0]}`"
                        class="image"
                      />
                      <button
                        class="view-btn"
                        v-on:click="viewPlace(place._id)"
                      >
                        <i class="fa-regular fa-eye"></i>
                      </button>
                    </div>
                    <div class="info">
                      <div class="NameALikes">
                        <div class="Name">
                          {{ place.Name }}
                        </div>
                        <div class="likes">
                          <i class="fa-regular fa-heart"></i> :
                          {{ place.Likes }}
                        </div>
                      </div>
                      <div class="address">
                        {{ place.Address }}
                      </div>
                    </div>
                  </div>
                  <div class="move_btn">
                    <button
                      class="control-btn"
                      v-if="cat.length > 1"
                      v-on:click="nextPlace(place._id, cat)"
                    >
                      <i class="fa-solid fa-right-long"></i>
                    </button>
                    <button
                      class="control-btn"
                      v-if="cat.length > 1"
                      v-on:click="backPlace(place._id, cat)"
                    >
                      <i class="fa-solid fa-left-long"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="noPlaces" v-if="!Data">
            <h2>{{ $t("Plan.NoPlaces") }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="move">
      <div class="notification">{{ $t("CreatePlan.copy") }}</div>
      <button class="nextButton" v-on:click="move()" v-if="!showShare">
        {{ $t("CreatePlan.next") }} <i class="fa-solid fa-arrow-left-long"></i>
      </button>
      <button class="nextButton" v-on:click="share()" v-if="showShare">
        {{ $t("CreatePlan.share") }} <i class="fa-solid fa-share-nodes"></i>
      </button>
    </div>
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import i18n from "@/i18n";
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      url: "",
      categoryName: "",
      Categories: [],
      SelectedCategories: [],
      partZero: true,
      partOne: false,
      partTwo: false,
      partThree: false,
      partFour: false,
      partFive: false,
      counter: 0,
      planName: "",
      fromTimeDropdown: false,
      toTimeDropdown: false,
      from: "",
      to: "",
      dfrom: "",
      dto: "",
      fromTimeType: "pm",
      toTimeType: "pm",
      displayDay: "",
      Day: 0,
      dfromTimeType: this.$i18n.t("CreatePlan.timeTypePM"),
      dtoTimeType: this.$i18n.t("CreatePlan.timeTypePM"),
      Time: "",
      PlanLength: 0,
      Tags: [],
      Tagsdropdown: false,
      SelectedTags: [],
      PlanCategories: [],
      Plan: {},
      PlanId: "",
      showShare: false,
      Data: true,
    };
  },
  async mounted() {
    this.url = ApiUrl.url;
    let page = document.getElementsByClassName("createAplan")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
    }
    await axios
      .get(ApiUrl.url + "/Community/Tags/Get/")
      .then((res) => {
        this.Tags = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    backToHome() {
      this.$router.push(`/${i18n.locale}/`);
    },
    async move() {
      if (this.counter == 0) {
        if (this.planName == "") {
          // empty name handle here
          $(".plan_name_err").text("Please enter name");
          return;
        } else {
          $(".zero").fadeOut(200);
          setTimeout(() => {
            this.partZero = false;
          }, 200);
          this.counter++;
          setTimeout(() => {
            this.partOne = true;
          }, 200);
          $(".first").fadeIn(200);
        }
      }
      if (this.counter == 1) {
        if (this.SelectedCategories.length == 0) {
          $("#categories_err").text(this.$i18n.t("CreatePlan.err1"));
          return;
        } else {
          $(".first").fadeOut(200);
          setTimeout(() => {
            this.partOne = false;
          }, 200);
          this.counter++;
          setTimeout(() => {
            this.partTwo = true;
          }, 200);
          $(".second").fadeIn(200);
        }
      }

      if (this.counter == 2) {
        if (this.Day == 0) {
          $("#days_err").text(this.$i18n.t("CreatePlan.err2"));
          return;
        } else {
          $(".second").fadeOut(200);
          setTimeout(() => {
            this.partTwo = false;
          }, 200);

          setTimeout(() => {
            this.partThree = true;
          }, 200);
          $(".third").fadeIn(200);
          this.counter++;
        }
      }

      if (this.counter == 3) {
        if (this.from == "") {
          $("#time_err").text(this.$i18n.t("CreatePlan.err3"));
          return;
        }
        if (this.to == "") {
          $("#time_err").text(this.$i18n.t("CreatePlan.err4"));
          return;
        }
        if (this.from != "" && this.to != "") {
          $(".third").fadeOut(200);
          setTimeout(() => {
            this.partThree = false;
          }, 200);

          setTimeout(() => {
            this.partFour = true;
          }, 200);
          $(".fourth").fadeIn(200);
          this.counter++;
        }
      }

      if (this.counter == 4) {
        if (this.SelectedTags.length == 0) {
          $("#tags_err").text(this.$i18n.t("CreatePlan.err5"));
          return;
        } else {
          $(".fourth").fadeOut(200);
          setTimeout(() => {
            this.partFour = false;
          }, 200);

          setTimeout(() => {
            this.partFive = true;
          }, 200);
          $(".fifth").fadeIn(200);
          this.counter++;
        }
      }

      if (this.counter == 5) {
        // console.log("Call Api here");
        await this.createPlan();

        // for (let i = 0; i < this.Plan.SelectedCategories.length; i++) {
        //   this.PlanCategories[i] = [];
        //   for (let j = 0; j < this.Plan.result.length; j++) {
        //     if (
        //       this.Plan.SelectedCategories[i]._id ==
        //       this.Plan.result[j].CategoryId
        //     ) {
        //       this.PlanCategories[i].push(this.Plan.result[j]);
        //     }
        //   }
        // }
        let counter = 0;
        for (let i = 0; i < this.PlanCategories.length; i++) {
          if (this.PlanCategories[i].length == 0) {
            counter++;
          }
        }
        if (counter == this.PlanCategories.length) {
          this.Data = false;
        }
        this.showShare = true;
      }
    },
    async showCategories() {
      await axios
        .get(ApiUrl.url + "/Community/Categories/Get")
        .then(async (res) => {
          this.Categories = [];
          let cats = await res.data;
          for (let i = 0; i < cats.length; i++) {
            if (!this.isInSelected(cats[i])) {
              this.Categories.push(cats[i]);
            }
          }
        });
    },
    /************ Methods for First Section (Category Section) ************/
    async searchCategory() {
      if (this.categoryName.trim() == "") {
        await axios
          .get(ApiUrl.url + "/Community/Categories/Get")
          .then(async (res) => {
            this.Categories = [];
            let cats = await res.data;
            for (let i = 0; i < cats.length; i++) {
              if (!this.isInSelected(cats[i])) {
                this.Categories.push(cats[i]);
              }
            }
          });
      } else {
        await axios
          .get(ApiUrl.url + `/Community/searchCategories/${this.categoryName}`)
          .then(async (res) => {
            this.Categories = [];
            let cats = await res.data;
            for (let i = 0; i < cats.length; i++) {
              if (!this.isInSelected(cats[i])) {
                this.Categories.push(cats[i]);
              }
            }
          });
      }
    },
    selectCategory(Category) {
      for (let i = 0; i < this.Categories.length; i++) {
        if (
          this.Categories[i].NameAR == Category.NameAR &&
          !this.isInSelected(Category)
        ) {
          this.Categories.splice(i, 1);
        }
      }

      let counter = 0;
      for (let i = 0; i < this.SelectedCategories.length; i++) {
        if (this.SelectedCategories[i].NameAR != Category.NameAR) {
          counter++;
        }
      }

      if (counter == this.SelectedCategories.length) {
        console.log(Category);
        this.SelectedCategories.push(Category);
        $("#categories_err").text("");
      }
    },
    isInSelected(Category) {
      for (let i = 0; i < this.SelectedCategories.length; i++) {
        if (this.SelectedCategories[i].NameAR == Category.NameAR) {
          return true;
        }
      }
      return false;
    },
    removeFromSelected(Category) {
      for (let i = 0; i < this.SelectedCategories.length; i++) {
        if (this.SelectedCategories[i].NameAR == Category.NameAR) {
          this.SelectedCategories.splice(i, 1);
        }
      }
    },
    /************ Methods for First Section (Category Section) ************/
    /************ Methods for Second Section (time Section) ************/
    openFromTimeDropdown() {
      if (this.fromTimeDropdown == false) {
        this.fromTimeDropdown = true;
        $(".showFromTime").addClass("remove_bottom_border");
      } else {
        this.fromTimeDropdown = false;
        $(".showFromTime").removeClass("remove_bottom_border");
      }
    },
    openToTimeDropdown() {
      if (this.toTimeDropdown == false) {
        this.toTimeDropdown = true;
        $(".showToTime").addClass("remove_bottom_border");
      } else {
        this.toTimeDropdown = false;
        $(".showToTime").removeClass("remove_bottom_border");
      }
    },
    getFromTimeType(type, d) {
      this.fromTimeType = type;
      this.dfromTimeType = d;
    },
    getToTimeType(type, d) {
      this.toTimeType = type;
      this.dtoTimeType = d;
    },
    selectFromTime(from, dfrom) {
      this.from = from;
      this.dfrom = dfrom;
      this.fromTimeDropdown = false;
      $(".showFromTime").removeClass("remove_bottom_border");
    },
    selectToTime(to, dto) {
      this.to = to;
      this.dto = dto;
      this.toTimeDropdown = false;
      $(".showToTime").removeClass("remove_bottom_border");
    },
    calculateTime() {
      this.Time =
        this.from +
        " " +
        this.fromTimeType +
        " - " +
        this.to +
        " " +
        this.toTimeType;

      let partOne = this.Time.split("-")[0].trim();
      let partTwo = this.Time.split("-")[1].trim();

      let currentHoursPartOne = parseInt(partOne.split(" ")[0]);
      let currentHoursPartTwo = parseInt(partTwo.split(" ")[0]);

      if (
        Number.isInteger(currentHoursPartOne) &&
        Number.isInteger(currentHoursPartTwo)
      ) {
        let currentTypeTimePartOne = partOne.split(" ")[1];
        let currentTypeTimePartTwo = partTwo.split(" ")[1];

        if (currentTypeTimePartOne == "pm" && currentHoursPartOne != 12) {
          currentHoursPartOne += 12;
        }
        if (currentTypeTimePartTwo == "pm" && currentHoursPartTwo != 12) {
          currentHoursPartTwo += 12;
        }

        //   console.log(currentHoursPartOne + " " + currentHoursPartTwo);
        if (currentHoursPartOne == 12 && currentTypeTimePartOne == "am") {
          currentHoursPartOne = 24;
        }
        if (currentHoursPartTwo == 12 && currentTypeTimePartTwo == "am") {
          currentHoursPartTwo = 24;
        }

        if (
          currentTypeTimePartOne == "am" &&
          currentTypeTimePartTwo == "am" &&
          currentHoursPartOne > currentHoursPartTwo
        ) {
          let to12AM = 24 - currentHoursPartOne;
          this.PlanLength = to12AM + currentHoursPartTwo;
          return;
        }

        if (currentTypeTimePartOne == "pm" && currentTypeTimePartTwo == "am") {
          let to12AM = 24 - currentHoursPartOne;
          this.PlanLength = to12AM + currentHoursPartTwo;
          return;
        }

        if (currentHoursPartOne > currentHoursPartTwo) {
          this.PlanLength = currentHoursPartOne - currentHoursPartTwo;
          return;
        } else if (currentHoursPartTwo > currentHoursPartOne) {
          this.PlanLength = currentHoursPartTwo - currentHoursPartOne;
          return;
        } else {
          this.PlanLength = 0;
          return;
        }

        // if (currentHoursPartOne < currentHoursPartTwo) {
        //   this.PlanLength = currentHoursPartTwo - currentHoursPartOne;
        // } else {
        //   this.PlanLength = 0;
        // }
      } else {
        this.PlanLength = 0;
      }
    },
    selectDays(DayName, number, n) {
      this.displayDay = n;
      $(".day").css("backgroundColor", "#fff");
      $(".day").css("color", "black");
      this.Day = number;
      $("#days_err").text("");
      $(`#${DayName}`).css("backgroundColor", "rgb(0, 186, 148)");
      $(`#${DayName}`).css("color", "#fff");
    },
    /************ Methods for Second Section (time Section) ************/
    /************ Methods for Third Section (Tags Section) ************/
    openTagsDropdown() {
      if (this.Tagsdropdown == false) {
        this.Tagsdropdown = true;
        $(".showTags").addClass("remove_bottom_border");
      } else {
        this.Tagsdropdown = false;
        $(".showTags").removeClass("remove_bottom_border");
      }
    },
    selectTag(tag) {
      for (let i = 0; i < this.Tags.length; i++) {
        if (this.Tags[i]._id == tag._id) {
          this.Tags.splice(i, 1);
        }
      }
      $("#tags_err").text("");
      this.SelectedTags.push(tag);
    },
    removeTag(tag) {
      for (let i = 0; i < this.SelectedTags.length; i++) {
        if (this.SelectedTags[i]._id == tag._id) {
          this.SelectedTags.splice(i, 1);
        }
      }
      this.Tags.push(tag);
    },
    /************ Methods for Third Section (Tags Section) ************/
    async createPlan() {
      this.Time =
        this.from +
        " " +
        this.fromTimeType +
        " - " +
        this.to +
        " " +
        this.toTimeType;

      let tgs = [];
      for (let i = 0; i < this.SelectedTags.length; i++) {
        tgs.push({
          NameEN: this.SelectedTags[i].NameEN,
          NameAR: this.SelectedTags[i].NameAR,
        });
      }

      await axios
        .post(
          ApiUrl.url + "/api/Plan/Create",
          {
            PlanName: this.planName,
            Categories: this.SelectedCategories,
            Day: this.Day,
            Time: this.Time,
            Tags: JSON.stringify(tgs),
            PriceRange1: "Low",
            PriceRange2: "High",
          },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          this.PlanId = res.data.id;
          this.PlanCategories = res.data.p;
          this.Data = true;
        });
    },
    nextPlace(id, places) {
      if (id != places[places.length - 1]._id) {
        let a = id;
        $(`#place-${id}`).fadeOut(0);
        for (let i = 0; i < places.length; i++) {
          if (id == places[i]._id) {
            let j = i + 1;
            if (j < places.length) {
              a = places[j]._id;
            }
            break;
          }
        }
        if (id != a) {
          $(`#place-${a}`).fadeIn(0);
        }
      }
    },
    backPlace(id, places) {
      if (id != places[0]._id) {
        let a = id;
        $(`#place-${id}`).fadeOut(0);
        for (let i = 0; i < places.length; i++) {
          if (id == places[i]._id) {
            let j = i - 1;
            if (j >= 0) {
              a = places[j]._id;
            }
            break;
          }
        }
        if (id != a) {
          $(`#place-${a}`).fadeIn(0);
        }
      }
    },
    viewPlace(id) {
      window.open(
        window.location.origin +
          "/" +
          this.$i18n.locale +
          "/" +
          "place" +
          `/${id}`
      );
    },
    share() {
      $(".notification").fadeIn(200);
      setTimeout(() => {
        $(".notification").fadeOut(200);
      }, 1000);
      navigator.clipboard.writeText(
        `${window.location.origin}/${this.$i18n.locale}/Plan/${this.PlanId}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
/****************** Header, sidebar and Footer ******************/
.createAplan {
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  direction: rtl;
  background-color: #472bff;
}
.a {
  flex: 1;
}
.move {
  margin-top: auto;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.nav {
  width: 100%;
}
.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  padding-bottom: 0;
}
.exit-button {
  outline: none;
  background: none;
  border: none;
  width: 50px;
  height: 50px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    .line1,
    .line2 {
      background-color: rgb(0, 186, 148);
    }
  }
}
.line1,
.line2 {
  width: 45px;
  height: 3px;
  background-color: #fff;
  transition: all 300ms ease-in-out;
}
.line1 {
  translate: 5px 1px;
  transform: rotate(-45deg);
}
.line2 {
  translate: 5px -1px;
  transform: rotate(45deg);
}
.line3 {
  width: 45px;
  height: 3px;
  background-color: #fff;
  transition: all 300ms ease-in-out;
  margin: 10px;
}
.title {
  color: #fff;
}
.workload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 200px);
}
.nextButton,
.finishButton {
  width: 365px;
  height: 45px;
  background: none;
  outline: none;
  border: none;
  background-color: rgb(0, 186, 148);
  color: #fff;
  font-size: 17px;
  border-radius: 5px;
  transition: all 250ms ease-in-out;
  &:hover {
    cursor: pointer;
    background: rgb(0, 153, 122);
  }
}
/****************** Header, sidebar and Footer ******************/
/****************** Zero Section ******************/
.planNameForm {
  margin-top: 2rem;
}
.plan_name_input {
  border: none;
  background: none;
  outline: none;
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.7rem;
  width: 365px;
  font-size: 1.4rem;
  border-bottom: 1.5px solid rgb(168, 168, 168);
}
.plan_name_input::selection {
  background-color: rgb(0, 186, 148);
}
.plan_name_input::placeholder {
  color: #fff;
}
.plan_name_err {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  color: rgb(168, 168, 168);
}
@media screen and (max-width: 400px) {
  .plan_name_input {
    width: 320px;
  }
  .notification,
  .nextButton {
    width: 352px;
  }
}
/****************** First Section ******************/
.firstQ {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 4rem;
}
.firstQ2 {
  padding-top: 2rem;
}
.selectCategories {
  display: flex;
  justify-content: center;
}
.selectCategoryInput {
  border: none;
  background: none;
  outline: none;
  border-bottom: 1px solid rgb(168, 168, 168);
  width: 620px;
  padding-bottom: 10px;
  color: rgb(168, 168, 168);
}
.selectCategoryInput::placeholder {
  font-size: 18px;
  color: #fff;
}
.list_categories {
  width: 620px;
  height: auto;
  overflow-y: auto;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}
.category {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;
  &:hover {
    cursor: pointer;
    background-color: rgb(0, 186, 148);
    color: #fff;
  }
}
.firstQ3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}
.categories {
  display: grid;
  gap: 30px;
  grid-template-columns: auto auto auto auto;
}
.cat {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.removeBtn {
  width: 50px;
  button {
    background: none;
    outline: none;
    border: none;
    font-size: 22px;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      color: rgb(250, 82, 82);
    }
  }
}
.catIcon {
  width: 50px;
  font-size: 18px;
}
.catName {
  font-size: 18px;
}
@media screen and (max-width: 400px) {
  .controls {
    padding: 0.5rem;
  }
  .selectCategoryInput,
  .list_categories,
  .nextButton,
  .notification {
    width: 320px;
  }
  .categories {
    grid-template-columns: auto;
  }
}
/****************** First Section ******************/
/****************** Second Section ******************/
.from_inputs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tut {
  h1 {
    color: #fff;
  }
}
.from_section {
  width: 400px;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.label {
  color: #fff;
  padding-bottom: 0.8rem;
  text-align: right;
}
.showFromTime,
.showToTime {
  width: 300px;
  height: 45px;
  display: flex;
  align-items: center;
  color: #1f2833;
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}
.remove_bottom_border {
  border-bottom: none;
}
.from_time_list,
.to_time_list {
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  width: 320px;
  height: 90px;
  overflow: auto;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}
.time {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #eeeeee;
    background-color: rgb(0, 186, 148);
  }
}
.time {
  color: #1f2833;
}
.time:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.dropbtn {
  background-color: #fff;
  color: #1f2833;
  width: 50px;
  height: 45px;
  font-size: 16px;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 300ms ease-in-out;
}
.dropdown {
  position: relative;
  margin: 1rem;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content div {
  color: black;
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
}
.dropdown-content div:hover {
  cursor: pointer;
  background-color: #ddd;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.a {
  display: flex;
  justify-content: center;
}
.from_section {
  margin-top: 1rem;
}
.days {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.day {
  width: 100px;
  height: 40px;
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: 600;
  transition: all 300ms ease;
}
.day:hover {
  cursor: pointer;
}
.plan_time {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 2rem;
  h3 {
    color: rgb(168, 168, 168);
    margin: 1rem;
  }
}
@media screen and (max-width: 400px) {
  .days {
    grid-template-columns: auto auto;
  }
  .from_section {
    width: 300px;
  }
  .showFromTime,
  .showToTime {
    width: 180px;
  }
  .from_time_list,
  .to_time_list {
    width: 200px;
  }
}
/****************** Second Section ******************/

.selectTags {
  padding-top: 2rem;
}
.tags {
  display: flex;
  justify-content: center;
  align-items: center;
}
.showTags {
  width: 300px;
  height: 45px;
  display: flex;
  align-items: center;
  color: #1f2833;
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}
.remove_bottom_border {
  border-bottom: none;
}
.Tags_list {
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  width: 320px;
  height: 90px;
  overflow: auto;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}
.Tag {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #eeeeee;
    background-color: rgb(0, 186, 148);
  }
}
.Tag:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sTags {
  display: flex;
  justify-content: center;
  padding-top: 80px;
}
.selectedTags {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 30px;
}
.tg {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}
.removeTag {
  background: none;
  outline: none;
  border: none;
  transition: all 300ms ease-in-out;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    color: rgb(250, 82, 82);
  }
}
@media screen and (max-width: 400px) {
  .selectedTags {
    flex-wrap: wrap;
    grid-template-columns: auto auto;
  }
}
/**********************************************************/
.fifth {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}
.sCats {
  width: 700px;
  border-bottom: 2px solid #bbb;
}
.rCat {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cat_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 600px;
  border-left: 2px solid #bbb;
  h1 {
    color: #fff;
  }
}
.places_in_cat {
  width: 480px;
  height: 480px;
  margin-right: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}
.pC {
  background-color: #fff;
  border-radius: 25px;
  height: 100%;
  display: none;
}
.pC:first-child {
  display: block;
}
.control-btn {
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  background-color: rgb(0, 186, 148);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  color: #fff;
  margin: 0.5rem;
  &:hover {
    cursor: pointer;
    background-color: rgb(0, 153, 122);
  }
}
.place_images {
  display: flex;
  height: 360px;
  .image {
    max-width: 480px;
    max-height: 360px;
    border-radius: 25px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.info {
  margin-top: 1rem;
}
.NameALikes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .Name {
    font-weight: bold;
    font-size: 1.5rem;
  }
  .likes {
    font-weight: bold;
    color: #472bff;
    font-size: 1.3rem;
  }
}
.address {
  padding-top: 1rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-weight: bold;
}
.move_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.view-btn {
  width: 30px;
  height: 30px;
  position: relative;
  top: 8%;
  left: 15%;
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 30px;
  height: 30px;
  background-color: rgb(0, 186, 148);
  border-radius: 360px;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: rgb(1, 128, 102);
  }
}
.err {
  color: #bbb;
  h3 {
    font-weight: bold;
  }
}
.notification {
  display: none;
  width: 320px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
  margin: 1rem;
  font-weight: bold;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}
.noPlaces h2 {
  margin-top: 1rem;
  color: #fff;
  font-weight: bold;
}
</style>
