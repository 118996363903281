<template>
  <div class="aboutus">
    <div class="a">
      <!--============== Header ==============-->
      <NavBar />
      <!--============== About us Section ==============-->
      <div class="content">
        <div class="wrapper">
          <div class="aboutus_section" id="aboutus">
            <div>
              <div class="sec1">
                <div class="image">
                  <img src="../assets/Vision.svg" />
                </div>
                <div class="aboutus-content">
                  <h2 class="sub_title">
                    {{ $t("HomePage.aboutus.ourService") }}
                  </h2>
                  <article>
                    <p>
                      {{ $t("HomePage.aboutus.OurServiceDescription") }}
                    </p>
                  </article>
                </div>
              </div>
              <div class="sec1">
                <div class="aboutus-content">
                  <h2 class="sub_title">{{ $t("HomePage.aboutus.Goals") }}</h2>
                  <article>
                    <p>
                      {{ $t("HomePage.aboutus.GoalsDescription") }}
                    </p>
                  </article>
                </div>
                <div class="image">
                  <img src="../assets/Goals.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterSide />
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import FooterSide from "../components/FooterSide.vue";
import ApiUrl from "@/ApiUrl";
export default {
  data() {
    return {
      isLoggedIn: false,
      isAdminLoggedIn: false,
    };
  },
  components: {
    NavBar,
    FooterSide,
  },
  async mounted() {
    this.isLoggedIn = await this.$store.getters.isLoggedIn;
    this.isAdminLoggedIn = await this.$store.getters.isAdmin;
    let page = document.getElementsByClassName("aboutus")[0];
    let section_title = document.getElementsByClassName("sub_title");
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      for (let i = 0; i < section_title.length; i++) {
        section_title[i].classList.add("left_text");
      }
    }
    await axios
      .post(ApiUrl.url + "/Community/Views", { Location: "About", placeId: "" })
      .then(() => {})
      .catch(() => {});
  },
  methods: {
    openNav() {
      let bar = document.getElementById("head");
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
        bar.classList.add("border");
      } else {
        nav.classList.remove("show");
        bar.classList.remove("border");
      }
      window.onresize = function () {
        if (window.innerWidth >= 1057) {
          nav.classList.remove("show");
          bar.classList.remove("border");
        }
      };
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-weight: 700;
}
.aboutus {
  display: flex;
  min-height: 100vh;
  direction: rtl;
  flex-direction: column;
}
.a {
  flex: 1;
}
.left_text {
  text-align: left;
}
/******************* Header *******************/
header {
  z-index: 3000;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  height: 80px;
  margin-top: 2rem;
  background-color: #1f2833;
  border-radius: 360px;
  color: #eeeeee;
  box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.1);
}
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
}
.title {
  font-size: 20px;
  margin: 1rem;
}
.list {
  width: 100%;
}
.nav-item {
  display: inline;
  margin: 2rem;
}
.nav-link {
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  &:hover {
    color: #472bff;
  }
}
.logout_link {
  background: none;
  border: none;
  outline: none;
  font-weight: 800;
  font-size: 16px;
  color: #eeeeee;
  transition: all 0.35s ease-out;
  &:hover {
    cursor: pointer;
    color: #472bff;
  }
}
.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
.line1,
.line3 {
  width: 30px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.line2 {
  width: 25px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.show_nav:hover {
  cursor: pointer;
}
.mobile-nav {
  ul {
    display: none;
  }
  ul li {
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #472bff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  ul {
    display: block;
  }
  ul li {
    text-align: center;
    opacity: 1;
  }
  ul li a {
    display: block;
  }
  ul li button {
    text-align: center;
    display: block;
  }
}

.border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media screen and (max-width: 1057px) {
  header {
    width: 900px;
  }
  .list li {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (max-width: 932px) {
  header {
    width: 700px;
  }
}
@media screen and (max-width: 736px) {
  header {
    width: 500px;
  }
}
@media screen and (min-width: 1057px) {
  header {
    border-radius: 360px;
  }
  .mobile-nav {
    display: none;
  }
}
.mb {
  display: flex;
  justify-content: center;
  margin-top: 0rem;
}
/******************* About us Section *******************/
.content {
  margin-top: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title1 {
  width: 330px;
  h1 {
    text-align: left;
    color: #fff;
    font-weight: bold;
    font-size: 32px;
  }
}
.aboutus_section {
  margin-top: 2rem;
}
.sec1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  transition: all 300ms ease-in-out;
}
.image {
  width: 400px;
  height: 400px;
}
.sub_title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;
}
.aboutus-content {
  width: 400px;
  text-align: left;

  article {
    text-align: justify;
    color: #bbbbbb;
    p {
      font-weight: bold;
    }
  }
}
@media screen and (max-width: 920px) {
  .aboutus_section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sec1 {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .image {
    width: 300px;
    height: 300px;
  }
  .aboutus-content {
    width: 300px;
    text-align: left;
  }
}
</style>
