<template>
  <div>
    <div class="nav">
      <AdminNav />
    </div>

    <div class="table">
      <div class="table_of_plans">
        <div class="first_row">
          <div class="ID">ID</div>
          <div class="PlanName">Plan Name</div>
          <div class="view">View</div>
          <div class="del">Delete</div>
        </div>
        <div class="row" v-for="plan in Plans" v-bind:key="plan._id">
          <div class="ID">{{ plan._id }}</div>
          <div class="PlanName">{{ plan.PlanName }}</div>
          <div class="view">
            <button class="btn" v-on:click="viewPlan(plan._id)">
              <i class="fa-regular fa-eye"></i>
            </button>
          </div>
          <div class="del">
            <button class="btn" v-on:click="deletePlan(plan._id)">
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import AdminNav from "@/components/AdminNav.vue";
import axios from "axios";

export default {
  components: {
    AdminNav,
  },
  data() {
    return {
      Plans: [],
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    this.loadPlans();
  },
  methods: {
    async loadPlans() {
      await axios
        .get(ApiUrl.url + "/api/Plan/Get/All", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.Plans = res.data;
        });
    },
    viewPlan(id) {
      this.$router.push(`/${this.$i18n.locale}/Admin/ManagePlan/${id}`);
    },
    async deletePlan(id) {
      await axios
        .delete(ApiUrl.url + `/api/Plan/Delete/${id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Plan Deleted") {
            this.loadPlans();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.table_of_plans {
  width: 950px;
  background-color: #1f2833;
  border-radius: 10px;
}
.first_row {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #bbb;
  .ID,
  .PlanName,
  .del,
  .view {
    font-weight: bold;
    color: #bbb;
  }
  .ID {
    width: 30%;
  }
  .PlanName {
    width: 40%;
  }
  .del {
    width: 15%;
  }
  .view {
    width: 15%;
  }
}
.row {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #bbb;
  .ID,
  .PlanName,
  .del,
  .view {
    font-weight: bold;
    color: #fff;
  }
  .ID {
    width: 30%;
  }
  .PlanName {
    width: 40%;
  }
  .del {
    width: 15%;
  }
  .view {
    width: 15%;
  }
}
.row {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid #bbb;
  }
  div:last-child {
    border-right: none;
  }
}
.row:last-child {
  border-bottom: none;
}
.btn {
  border: none;
  background: none;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  background-color: #472bff;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #2b1b94;
  }
}
</style>
