<template>
  <div>
    <!--=============== Navbar ===============-->
    <div class="nav">
      <AdminNav />
    </div>
    <!--=============== Navbar ===============-->
    <!--=============== Manage  ===============-->
    <div class="manage">
      <h2>Manage Cities</h2>
      <button class="addBtn" v-on:click="showAddPopup()">Add City</button>
    </div>
    <!--=============== Manage ===============-->
    <!--=============== Add City Popup ===============-->
    <div id="AddCity" v-if="showAdd">
      <div class="dispage">
        <div class="AddC">
          <div class="close">
            <button class="exit_button" v-on:click="closeAddPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <form method="post" @submit.prevent="addCity()">
              <h2 style="color: #eee; text-align: center">Add City</h2>
              <div class="input">
                <div class="in">
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="CityName"
                    class="name-input"
                    id="Add_City_input"
                    placeholder="City Name"
                  />
                  <br />
                  <label for="name" class="Add_City_input_label"></label>
                </div>
              </div>
              <div class="input">
                <div class="showCountries" v-on:click="openDropdown()">
                  Select Country: {{ Country.Name }}
                </div>
                <div class="countries_list" v-if="dropdown">
                  <div
                    class="country"
                    v-for="country in Countries"
                    v-bind:key="country._id"
                    v-on:click="selectCountry(country)"
                  >
                    {{ country.Name }}
                  </div>
                </div>
              </div>
              <div class="input">
                <button class="submit">Add City</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Add City Popup ===============-->
    <!--=============== Edit City Popup ===============-->
    <div id="EditCity" v-if="showEdit">
      <div class="dispage">
        <div class="AddC">
          <div class="close">
            <button class="exit_button" v-on:click="closeEditPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <form method="post" @submit.prevent="editCity()">
              <h2 style="color: #eee; text-align: center">Edit City</h2>
              <div class="input">
                <div class="in">
                  <input
                    type="text"
                    autocomplete="off"
                    class="name-input"
                    id="EAdd_City_input"
                    v-model="ECityName"
                    placeholder="City Name"
                  />
                  <br />
                  <label for="name" class="EAdd_City_input_label"></label>
                </div>
              </div>
              <div class="input">
                <div class="showCountries2" v-on:click="openDropdown2()">
                  Select Country: {{ Country2.Name }}
                </div>
                <div class="countries_list2" v-if="dropdown2">
                  <div
                    class="country2"
                    v-for="country in Countries"
                    v-bind:key="country._id"
                    v-on:click="selectCountry2(country)"
                  >
                    {{ country.Name }}
                  </div>
                </div>
              </div>
              <div class="input">
                <button class="submit">Edit City</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Edit City Popup ===============-->
    <!--=============== Display Cities ===============-->
    <div class="displayCategories">
      <div class="categories">
        <div class="category" v-for="city in Cities" v-bind:key="city._id">
          <div class="category_icon">
            <div class="c_n">
              <h3>{{ city.Name }}</h3>
            </div>
          </div>
          <div class="category_info">
            <div class="category_info_box">
              <button
                class="view_category_btn"
                v-on:click="showEditPopup(city)"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
              <button
                class="view_category_btn"
                v-on:click="deleteCity(city._id)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Display Cities ===============-->
    <!--=============== Message Box ===============-->
    <div class="Message1" id="MessageBox">
      <div class="message_contaier">
        <div class="MessContent">
          <p id="msg"></p>
        </div>
        <div class="closeMsg">
          <button class="closeBtn" v-on:click="closeMsg()">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
    <!--=============== Message Box ===============-->
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import AdminNav from "@/components/AdminNav.vue";
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  components: {
    AdminNav,
  },
  data() {
    return {
      showAdd: false,
      showEdit: false,
      Countries: [],
      Cities: [],
      CityName: "",
      Country: {},
      Country2: {},
      ECityId: "",
      dropdown: false,
      dropdown2: false,
      isCountrySelected: false,
      isCountrySelected2: false,
      ECityName: "",
    };
  },
  async mounted() {
    this.loadCities();
    this.loadCountries();
  },
  methods: {
    async loadCities() {
      await axios
        .get(ApiUrl.url + "/api/Cities/Get", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.Cities = res.data;
        });
    },
    async loadCountries() {
      await axios
        .get(ApiUrl.url + "/api/Countries/Get", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.Countries = res.data;
        });
    },
    openDropdown() {
      if (this.dropdown == false) {
        this.dropdown = true;
        $(".showCountries").addClass("remove_bottom_border");
      } else {
        this.dropdown = false;
        $(".showCountries").removeClass("remove_bottom_border");
      }
    },
    openDropdown2() {
      if (this.dropdown2 == false) {
        this.dropdown2 = true;
      } else {
        this.dropdown2 = false;
      }
    },
    showAddPopup() {
      this.showAdd = true;
      setTimeout(() => {
        $("#AddCity").fadeIn(200);
      }, 0);
    },
    closeAddPopup() {
      $("#AddCity").fadeOut(200);
      setTimeout(() => {
        this.showAdd = false;
      }, 1000);
    },
    selectCountry(Country) {
      this.Country = Country;
      this.isCountrySelected = true;
      $(".showCountries").css(
        "border",
        "1.5px solid rgba(180, 180, 180, 0.507)"
      );
      this.dropdown = false;
    },
    async addCity() {
      $("#Add_City_input").click(function () {
        window.onkeydown = function () {
          $("#Add_City_input").css("borderBottom", "3px solid #472bff");
          $(".Add_City_input_label").text("");
        };
      });

      if (this.CityName == "") {
        $("#Add_City_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Add_City_input_label").text("Please enter city name.");
        return;
      }
      if (!this.isCountrySelected) {
        $(".showCountries").css("border", "2px solid rgb(250,82,82)");
        return;
      }
      await axios
        .post(
          ApiUrl.url + "/api/Cities/Add",
          { Name: this.CityName, CountryId: this.Country._id },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.msg == "City Added") {
            this.showAdd = false;
            this.loadCities();
          }
        });
    },
    async showEditPopup(city) {
      this.showEdit = true;
      await axios
        .get(ApiUrl.url + `/api/Cities/Get/City/${city._id}`, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then(async (res) => {
          this.ECityId = res.data.city._id;
          this.ECityName = await res.data.city.Name;
          this.Country2 = await res.data.country;
          this.isCountrySelected2 = true;
        });
      setTimeout(() => {
        $("#EditCity").fadeIn(200);
      }, 0);
    },
    closeEditPopup() {
      $("#EditCity").fadeOut(200);
      setTimeout(() => {
        this.showEdit = false;
      }, 1000);
    },
    selectCountry2(Country) {
      this.Country2 = Country;
      this.isCountrySelected2 = true;
      $(".showCountries2").css(
        "border",
        "1.5px solid rgba(180, 180, 180, 0.507)"
      );
      this.dropdown2 = false;
    },
    async editCity() {
      $("#EAdd_City_input").click(function () {
        window.onkeydown = function () {
          $("#EAdd_City_input").css("borderBottom", "3px solid #472bff");
          $(".EAdd_City_input_label").text("");
        };
      });

      if (this.ECityName == "") {
        $("#EAdd_City_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".EAdd_City_input_label").text("Please enter city name.");
        return;
      }
      if (!this.isCountrySelected2) {
        $(".showCountries2").css("border", "2px solid rgb(250,82,82)");
        return;
      }

      await axios
        .patch(
          ApiUrl.url + "/api/Cities/Edit",
          {
            id: this.ECityId,
            newName: this.ECityName,
            newCountryID: this.Country2._id,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.msg == "City Updated") {
            this.showEdit = false;
            $("#msg").text("City updated");
            $(".Message1").fadeIn(200);
            setTimeout(() => {
              $(".Message1").fadeOut(400);
            }, 3000);
            this.loadCities();
          }
        });
    },
    async deleteCity(cityId) {
      await axios
        .delete(ApiUrl.url + `/api/Cities/Delete/${cityId}`, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data.msg == "City Deleted") {
            $("#msg").text("City deleted !");
            $(".Message1").fadeIn(200);
            setTimeout(() => {
              $(".Message1").fadeOut(400);
            }, 3000);
            this.loadCities();
          }
        });
    },
    closeMsg() {
      $(".Message1").fadeOut(200);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  height: 80px;
}
.manage {
  display: flex;
  justify-content: left;
  align-items: center;
  h2 {
    margin: 3rem;
  }
}
.addBtn {
  width: 120px;
  height: 40px;
  background-color: #472bff;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: #331ec0;
    cursor: pointer;
  }
}
.addCountry {
  display: flex;
  justify-content: left;
  margin: 3rem;
  margin-top: 0rem;
  margin-left: 2rem;
}
.input {
  padding: 1.3rem;
}
::placeholder {
  font-weight: 700;
}
.add_btn {
  width: 365px;
  height: 40px;
  border: none;
  background: none;
  outline: none;
  border-radius: 10px;
  background-color: #472bff;
  font-weight: 700;
  font-size: 14.5px;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #331ec0;
  }
}
#AddCity,
#EditCity {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 5000;
  transform: translate(-50%, -50%);
  display: none;
}
.dispage {
  width: 100%;
  height: 100vh;
  background-color: #1f283398;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddC {
  width: 600px;
  height: 450px;
  background: #1f2833;
  border-radius: 25px;
}
.EditC {
  width: 600px;
  height: 550px;
  background: #1f2833;
  border-radius: 25px;
}
.close {
  text-align: right;
  width: 100%;
  height: 50px;
}
.exit_button {
  margin: 1rem;
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  color: #eeeeee;
  font-size: 24px;
  transition: all 300ms ease-in-out;
}
.exit_button:hover {
  cursor: pointer;
  color: #472bff;
}
.form {
  display: flex;
  justify-content: center;
  width: 100%;
}
.input {
  padding: 20px;
}
.Add_City_input_label,
.EAdd_City_input_label {
  color: rgb(250, 82, 82);
}
::placeholder {
  color: #eeeeee;
}
.in {
  text-align: left;
}
.name-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  font-size: 15px;
  color: #eeeeee;
  border-bottom: 3px solid #472bff;
}

.submit {
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #472bff;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.submit:hover {
  cursor: pointer;
  background-color: #3721c5;
}
#success_msg {
  text-align: center;
}
.success-msg-1 {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
.country_form {
  width: 335px;
}
.showCountries,
.showCountries2 {
  width: 335px;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}
.remove_bottom_border {
  border-bottom: none;
}
.countries_list,
.countries_list2 {
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  width: 355px;
  height: 90px;
  overflow: auto;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}
.country,
.country2 {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #eeeeee;
    background-color: #472bff;
  }
}
.country:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.country2:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
/*********************** Display Categories ***********************/
.displayCategories {
  display: flex;
  justify-content: center;
  padding: 2.5rem;
}
.categories {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
}
.category {
  width: 300px;
  height: 100px;
  transition: all 300ms ease-in-out;
}
.category_icon {
  width: 100%;
  height: calc(100% - 3px);
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 3px solid#472bff;
  transition: all 300ms ease-in-out;
}
.c_n {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 100%;
}
.category_info {
  background-color: rgba(232, 232, 232, 0.637);
  width: 100%;
  height: 100px;
  opacity: 0;
  transition: all 300ms ease-in-out;
  transform: translateY(-100px);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category:hover {
  .category_info {
    opacity: 1;
  }
}
.view_category_btn {
  margin: 1rem;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 100px;
  background-color: #472bff;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: #472bff;
  }
}
@media screen and (max-width: 1305px) {
  .categories {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 975px) {
  .categories {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 645px) {
  .categories {
    grid-template-columns: auto;
  }
}
/*********************** Display Categories ***********************/
/*********************** Message box ***********************/
.Message1 {
  width: 400px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  top: 85%;
  left: 2%;
  border-left: 10px solid #472bff;
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.message_contaier {
  width: 100%;
  height: 100%;
  display: flex;
}
.MessContent {
  display: flex;
  align-items: center;
  width: 350px;
  p {
    margin: 1rem;
  }
}
.closeMsg {
  display: flex;
  align-items: flex-start;
  padding-top: 0;
  padding: 10px;
}
.closeBtn {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  i {
    color: #1f2833;
    font-size: 22px;
    transition: all 300ms ease-in-out;
  }
}
.closeBtn:hover {
  cursor: pointer;
  i {
    color: #472bff;
  }
}
/*********************** Message box ***********************/
</style>
