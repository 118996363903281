<template>
  <div>
    <div class="nav">
      <AdminNav />
    </div>
    <div>
      <h2>Settings</h2>
      <!--===================== Update Password =====================-->
      <div class="updatePassword">
        <h2>Update Password</h2>
        <form @submit.prevent="updatePassword()" method="post">
          <!--===================== Old Password Input =====================-->
          <div class="input">
            <input
              type="password"
              placeholder="Old Password"
              class="text-input"
              v-model="oldPassword"
              id="oldPassword_input"
            />
            <br />
            <label
              for="oldPassword_input"
              class="oldpassword_input_label"
            ></label>
          </div>
          <!--===================== Old Password Input =====================-->

          <!--===================== New Password Input =====================-->
          <div class="input">
            <input
              type="password"
              placeholder="New Password"
              class="text-input"
              v-model="newPassword"
              id="newPassword_input"
            />
            <br />
            <label
              for="newPassword_input"
              class="newPassword_input_label"
            ></label>
          </div>
          <!--===================== New Password Input =====================-->

          <!--===================== Confirm Password Input =====================-->
          <div class="input">
            <input
              type="password"
              placeholder="Confirm Password"
              class="text-input"
              v-model="confirmPassword"
              id="confirmPassword_input"
            />
            <br />
            <label
              for="confirmPassword_input"
              class="confirmPassword_input_label"
            ></label>
          </div>
          <!--===================== Confirm Password Input =====================-->

          <!--===================== Submit =====================-->
          <div class="input">
            <button class="submit">Update</button>
          </div>
          <!--===================== Submit =====================-->
        </form>
      </div>
      <!--===================== Update Password =====================-->
    </div>
  </div>
</template>

<script>
import AdminNav from "@/components/AdminNav.vue";
import jquery from "jquery";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
let _ = jquery;
export default {
  components: {
    AdminNav,
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  },
  methods: {
    async updatePassword() {
      _("#oldPassword_input").click(function () {
        window.onkeydown = function () {
          _("#oldPassword_input").css("borderBottom", "3px solid #472bff");
          _(".oldpassword_input_label").text("");
        };
      });

      _("#newPassword_input").click(function () {
        window.onkeydown = function () {
          _("#newPassword_input").css("borderBottom", "3px solid #472bff");
          _(".newPassword_input_label").text("");
        };
      });

      _("#confirmPassword_input").click(function () {
        window.onkeydown = function () {
          _("#confirmPassword_input").css("borderBottom", "3px solid #472bff");
          _(".confirmPassword_input_label").text("");
        };
      });

      if (
        this.oldPassword == "" &&
        this.newPassword == "" &&
        this.confirmPassword == ""
      ) {
        _("#oldPassword_input").css("borderBottom", "3px solid rgb(250,82,82)");
        _(".oldpassword_input_label").text("Please Enter The old Password");

        _("#newPassword_input").css("borderBottom", "3px solid rgb(250,82,82)");
        _(".newPassword_input_label").text("Please Enter The new Password");

        _("#confirmPassword_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        _(".confirmPassword_input_label").text(
          "Please Enter The confirm Password"
        );

        return;
      }

      if (this.oldPassword == "") {
        _("#oldPassword_input").css("borderBottom", "3px solid rgb(250,82,82)");
        _(".oldpassword_input_label").text("Please Enter The old Password");
        return;
      }

      if (this.newPassword == "") {
        _("#newPassword_input").css("borderBottom", "3px solid rgb(250,82,82)");
        _(".newPassword_input_label").text("Please Enter The new Password");
        return;
      }
      if (this.confirmPassword == "") {
        _("#confirmPassword_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        _(".confirmPassword_input_label").text(
          "Please Enter The confirm Password"
        );
        return;
      }

      if (this.newPassword != this.confirmPassword) {
        _("#newPassword_input").css("borderBottom", "3px solid rgb(250,82,82)");
        _(".newPassword_input_label").text("Password don't match");
        return;
      }

      await axios
        .post(
          ApiUrl.url + "/Admin/updatePassword",
          { oldPassword: this.oldPassword, newPassword: this.newPassword },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Password Updated") {
            location.reload();
            return;
          }
          if (res.data.msg == "Wrong Password") {
            _("#oldPassword_input").css(
              "borderBottom",
              "3px solid rgb(250,82,82)"
            );
            _(".oldpassword_input_label").text("Wrong Password");
            return;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  height: 80px;
}
/************************ Update Password ************************/
.updatePassword {
  margin-top: 3.5rem;
}
.input {
  padding: 1rem;
}
.text-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  font-size: 15px;
  border-bottom: 3px solid #472bff;
}
.oldpassword_input_label,
.newPassword_input_label,
.confirmPassword_input_label {
  color: rgb(250, 82, 82);
}
.text-input::placeholder {
  font-weight: 700;
}
.submit {
  width: 365px;
  height: 45px;
  border-radius: 5px;
  background: none;
  border: none;
  outline: none;
  background-color: #472bff;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c7;
  }
}

/************************ Update Password ************************/
</style>
