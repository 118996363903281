<template>
  <div class="contactus">
    <div class="a">
      <!--============== Header ==============-->
      <NavBar />
      <!--============== Contact information ==============-->
      <div class="cont">
        <div class="contact_info">
          <div class="cInfo">
            <div class="centerC">
              <h1 class="icon"><i class="fa-regular fa-envelope"></i></h1>
              <h2 class="ctitle">{{ $t("About.email") }}</h2>
              <h3 class="currentEmail">email@gmail.com</h3>
              <p class="content">{{ $t("About.eDescription") }}</p>
            </div>
          </div>
          <div class="cInfo">
            <div class="centerC">
              <h1 class="icon"><i class="fa-brands fa-instagram"></i></h1>
              <h2 class="ctitle">{{ $t("About.social") }}</h2>
              <h3 class="currentEmail">
                <a href="#" class="instagram_link">ala-wayn</a>
              </h3>
              <p class="content">{{ $t("About.eSocial") }}</p>
            </div>
          </div>
          <div class="cInfo">
            <div class="centerC">
              <h1 class="icon"><i class="fa-regular fa-paper-plane"></i></h1>
              <h2 class="ctitle">{{ $t("About.Message") }}</h2>
              <h3 class="currentEmail">
                <router-link :to="{ name: 'Message' }" class="instagram_link">{{
                  $t("About.contact")
                }}</router-link>
              </h3>
              <p class="content">{{ $t("About.eMessage") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <FooterSide />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import FooterSide from "../components/FooterSide.vue";
import ApiUrl from "@/ApiUrl";
export default {
  data() {
    return {
      isLoggedIn: false,
      isAdminLoggedIn: false,
    };
  },
  components: {
    NavBar,
    FooterSide,
  },
  async mounted() {
    this.isLoggedIn = await this.$store.getters.isLoggedIn;
    this.isAdminLoggedIn = await this.$store.getters.isAdmin;
    let page = document.getElementsByClassName("contactus")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
    }
    await axios
      .post(ApiUrl.url + "/Community/Views", {
        Location: "Contact",
        placeId: "",
      })
      .then(() => {})
      .catch(() => {});
  },
  methods: {
    openNav() {
      let bar = document.getElementById("head");
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
        bar.classList.add("border");
      } else {
        nav.classList.remove("show");
        bar.classList.remove("border");
      }
      window.onresize = function () {
        if (window.innerWidth >= 1057) {
          nav.classList.remove("show");
          bar.classList.remove("border");
        }
      };
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-weight: 700;
}
.contactus {
  display: flex;
  min-height: 100vh;
  direction: rtl;
  flex-direction: column;
}
.a {
  flex: 1;
}
.footer {
  margin-top: auto;
}
header {
  z-index: 3000;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  height: 80px;
  margin-top: 2rem;
  background-color: #1f2833;
  border-radius: 360px;
  color: #eeeeee;
  box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.1);
}
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
}
.title {
  font-size: 20px;
  margin: 1rem;
}
.list {
  width: 100%;
}
.nav-item {
  display: inline;
  margin: 2rem;
}
.nav-link {
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  &:hover {
    color: #472bff;
  }
}
.logout_link {
  background: none;
  border: none;
  outline: none;
  font-weight: 800;
  font-size: 16px;
  color: #eeeeee;
  transition: all 0.35s ease-out;
  &:hover {
    cursor: pointer;
    color: #472bff;
  }
}
.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
.line1,
.line3 {
  width: 30px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.line2 {
  width: 25px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.show_nav:hover {
  cursor: pointer;
}
.mobile-nav {
  ul {
    display: none;
  }
  ul li {
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #472bff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  ul {
    display: block;
  }
  ul li {
    text-align: center;
    opacity: 1;
  }
  ul li a {
    display: block;
  }
  ul li button {
    text-align: center;
    display: block;
  }
}

.border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media screen and (max-width: 1057px) {
  header {
    width: 900px;
  }
  .list li {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (max-width: 932px) {
  header {
    width: 700px;
  }
}
@media screen and (max-width: 736px) {
  header {
    width: 500px;
  }
}
@media screen and (min-width: 1057px) {
  header {
    border-radius: 360px;
  }
  .mobile-nav {
    display: none;
  }
}
.mb {
  display: flex;
  justify-content: center;
  margin-top: 0rem;
}
/******************* Contact information *******************/
.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12rem;
}
.contact_info {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 40px;
}
.cInfo {
  width: 350px;
  height: 300px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  border-bottom: 5px solid #472bff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  margin: 1rem;
  margin-top: 0rem;
  i {
    font-size: 3.7rem;
    color: #472bff;
  }
}
.ctitle {
  font-weight: bold;
}
.currentEmail {
  margin: 1rem;
  font-weight: bold;
}
.content {
  font-weight: bold;
  color: #bbbbbb;
}
.instagram_link {
  &:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: 1150px) {
  .contact_info {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 745px) {
  .contact_info {
    grid-template-columns: auto;
  }
}
</style>
