import { render, staticRenderFns } from "./AddPlace.vue?vue&type=template&id=dff8ef7c&scoped=true&"
import script from "./AddPlace.vue?vue&type=script&lang=js&"
export * from "./AddPlace.vue?vue&type=script&lang=js&"
import style0 from "./AddPlace.vue?vue&type=style&index=0&id=dff8ef7c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dff8ef7c",
  null
  
)

export default component.exports