<template>
  <!--=============== Navbar ===============-->
  <div class="navbar">
    <div class="left">
      <img src="../assets/logo.svg" />
      <span>Ala-wayn</span>
    </div>
    <div class="right">
      <ul>
        <li class="link">
          <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
        </li>
        <li class="link">
          <router-link :to="{ name: 'ManageCountries' }"
            ><i class="fa-solid fa-earth-americas"></i> Countires</router-link
          >
        </li>
        <li class="link">
          <router-link :to="{ name: 'ManageCities' }"
            ><i class="fa-solid fa-earth-americas"></i> Cities</router-link
          >
        </li>
        <li class="link">
          <router-link :to="{ name: 'ManageCategories' }"
            ><i class="fa-solid fa-tags"></i> Categories</router-link
          >
        </li>
        <li class="link">
          <router-link :to="{ name: 'ManagePlaces' }"
            ><i class="fa-solid fa-location-pin"></i> Places</router-link
          >
        </li>
        <li class="link">
          <router-link :to="{ name: 'ManageTags' }"
            ><i class="fa-solid fa-tags"></i> Tags</router-link
          >
        </li>
        <li class="link">
          <router-link :to="{ name: 'ManageUsers' }"
            ><i class="fa-regular fa-user"></i> Users</router-link
          >
        </li>
        <li class="link">
          <router-link :to="{ name: 'ManageReviews' }">
            <i class="fa-regular fa-comment"></i> Reviews</router-link
          >
        </li>
        <li class="link">
          <router-link :to="{ name: 'ManagePlans' }">
            <i class="fa-solid fa-map"></i> Plans</router-link
          >
        </li>
        <li class="link">
          <router-link :to="{ name: 'Settings' }"
            ><i class="fa-solid fa-gears"></i> Settings</router-link
          >
        </li>
        <li class="link">
          <button class="logout_link" v-on:click="Logout()">Logout</button>
        </li>
      </ul>
    </div>
  </div>
  <!--=============== Navbar ===============-->
</template>

<script>
export default {
  methods: {
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>

<style lang="scss" scoped>
/************************ Navbar ************************/
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: #1f2833;
}
.left {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  img {
    width: 80px;
    height: 80px;
    margin: 20px;
  }
  span {
    font-weight: bold;
    color: #eeeeee;
    font-size: 18px;
  }
}
.right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  ul {
    li {
      display: inline;
      margin: 10px;
      a {
        color: #eeeeee;
        font-weight: bold;
        font-size: 14px;
        margin: 10px;
        transition: all 200ms ease-in-out;
      }
      a:hover {
        color: #3721c5;
      }
    }
  }
}
.logout_link {
  background: none;
  border: none;
  outline: none;
  color: #eeeeee;
  font-weight: bold;
  font-size: 14px;
  transition: all 200ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #3721c5;
  }
}
/************************ Navbar ************************/
</style>
