<template>
  <div class="s">
    <div>
      <header id="head">
        <div class="container">
          <div class="logo">
            <img src="../assets/logo.svg" />
            <span class="title">{{ $t("HomePage.Navbar.Title") }}</span>
          </div>
          <div class="links">
            <ul class="list">
              <li class="nav-item">
                <router-link :to="`/${$i18n.locale}`" class="nav-link">{{
                  $t("HomePage.Navbar.Link1")
                }}</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Places' }" class="nav-link">{{
                  $t("HomePage.Navbar.Link3")
                }}</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'AddNewPlace' }" class="nav-link">{{
                  $t("HomePage.Navbar.Link4")
                }}</router-link>
              </li>
              <li v-if="isAdminLoggedIn" class="nav-item">
                <router-link :to="{ name: 'Dashboard' }" class="nav-link">{{
                  $t("HomePage.Navbar.Link5")
                }}</router-link>
              </li>
              <li v-if="isLoggedIn && !isAdminLoggedIn" class="nav-item">
                <router-link :to="{ name: 'Profile' }" class="nav-link">{{
                  $t("HomePage.Navbar.Link7")
                }}</router-link>
              </li>
              <li v-if="isLoggedIn" class="nav-item">
                <button class="logout_link" v-on:click="Logout()">
                  {{ $t("HomePage.Navbar.Link6") }}
                </button>
              </li>
              <li v-if="!isLoggedIn" class="nav-item">
                <router-link :to="{ name: 'Login' }" class="nav-link">
                  {{ $t("HomePage.Navbar.Link8") }}
                </router-link>
              </li>
              <li v-if="!isLoggedIn" class="nav-item">
                <router-link :to="{ name: 'Signup' }" class="nav-link">
                  {{ $t("HomePage.Navbar.Link9") }}
                </router-link>
              </li>
              <li v-if="$i18n.locale == 'en'" class="nav-item">
                <span v-on:click="click('ar')" to="" class="nav-link">
                  العربية
                </span>
              </li>
              <li v-if="$i18n.locale == 'ar'" class="nav-item">
                <span v-on:click="click('en')" to="" class="nav-link">
                  en
                </span>
              </li>
            </ul>
            <button class="show_nav" v-on:click="openNav()">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line3"></div>
            </button>
          </div>
        </div>
        <div class="mobile-nav">
          <ul>
            <li class="nav-item">
              <router-link to="/" class="nav-link">{{
                $t("HomePage.Navbar.Link1")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Places' }" class="nav-link">{{
                $t("HomePage.Navbar.Link3")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'AddNewPlace' }" class="nav-link">{{
                $t("HomePage.Navbar.Link4")
              }}</router-link>
            </li>
            <li v-if="isAdminLoggedIn" class="nav-item">
              <router-link :to="{ name: 'Dashboard' }" class="nav-link">{{
                $t("HomePage.Navbar.Link5")
              }}</router-link>
            </li>
            <li v-if="isLoggedIn && !isAdminLoggedIn" class="nav-item">
              <router-link :to="{ name: 'Profile' }" class="nav-link">{{
                $t("HomePage.Navbar.Link7")
              }}</router-link>
            </li>
            <li v-if="isLoggedIn" class="nav-item mb">
              <button class="logout_link" v-on:click="Logout()">
                {{ $t("HomePage.Navbar.Link6") }}
              </button>
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
              <router-link :to="{ name: 'Login' }" class="nav-link">
                {{ $t("HomePage.Navbar.Link8") }}
              </router-link>
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
              <router-link :to="{ name: 'Signup' }" class="nav-link">
                {{ $t("HomePage.Navbar.Link9") }}
              </router-link>
            </li>
            <li v-if="$i18n.locale == 'en'" class="nav-item">
              <span v-on:click="click('ar')" to="" class="nav-link">
                العربية
              </span>
            </li>
            <li v-if="$i18n.locale == 'ar'" class="nav-item">
              <span v-on:click="click('en')" to="" class="nav-link"> en </span>
            </li>
          </ul>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      isLoggedIn: false,
      isAdminLoggedIn: false,
    };
  },
  async mounted() {
    this.isLoggedIn = await this.$store.getters.isLoggedIn;
    this.isAdminLoggedIn = await this.$store.getters.isAdmin;
    let page = document.getElementsByClassName("s")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
    }
  },
  methods: {
    openNav() {
      let bar = document.getElementById("head");
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
        bar.classList.add("border");
      } else {
        nav.classList.remove("show");
        bar.classList.remove("border");
      }
      window.onresize = function () {
        if (window.innerWidth >= 1057) {
          nav.classList.remove("show");
          bar.classList.remove("border");
        }
      };
    },
    click(data) {
      let str = this.$route.fullPath;
      let link = "/" + data + str.slice(3);
      this.$router.push(link);
      location.reload();
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>

<style scoped lang="scss">
$background1: #1f2833;
$text1: #66fcf1;
$text2: #45a29e;
$text3: #c5c6c7;
* {
  font-weight: 700;
}
.s {
  display: flex;
  justify-content: center;
  align-items: center;
}
/******************* Header *******************/
header {
  z-index: 3000;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 1120px;
  height: 80px;
  margin-top: 2rem;
  background-color: #1f2833;
  border-radius: 360px;
  color: #eeeeee;
  box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.1);
}
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
}
.title {
  font-size: 20px;
  margin: 1rem;
}
.list {
  width: 100%;
}
.nav-item {
  display: inline;
  margin: 2rem;
}
.nav-link {
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  &:hover {
    color: #472bff;
  }
}
.logout_link {
  background: none;
  border: none;
  outline: none;
  font-weight: 800;
  font-size: 16px;
  color: #eeeeee;
  transition: all 0.35s ease-out;
  &:hover {
    cursor: pointer;
    color: #472bff;
  }
}
.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
.line1,
.line3 {
  width: 30px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.line2 {
  width: 25px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.show_nav:hover {
  cursor: pointer;
}
.mobile-nav {
  ul {
    display: none;
  }
  ul li {
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #472bff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  ul {
    display: block;
  }
  ul li {
    text-align: center;
    opacity: 1;
  }
  ul li a {
    display: block;
  }
  ul li button {
    text-align: center;
    display: block;
  }
}
.border {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media screen and (max-width: 1057px) {
  header {
    width: 900px;
  }
  .list li {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (max-width: 932px) {
  header {
    width: 700px;
  }
}
@media screen and (max-width: 736px) {
  header {
    width: 500px;
  }
}
@media screen and (min-width: 1057px) {
  header {
    border-radius: 360px;
  }
  .mobile-nav {
    display: none;
  }
}
@media screen and (max-width: 510px) {
  header {
    width: 350px;
  }
}
.mb {
  display: flex;
  justify-content: center;
  margin-top: 0rem;
}
</style>
