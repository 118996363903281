<template>
  <div>
    <!--=============== Navbar ===============-->
    <AdminNav />
    <!--=============== Navbar ===============-->
    <!--=============== Stat boxes ===============-->
    <div class="boxes">
      <h3 class="boxes_title">Statistics</h3>
      <div class="cont">
        <div class="wrapper">
          <div class="container">
            <i class="fa-solid fa-tags icon"></i>
            <span class="num">{{ NumberOfCategories }}</span>
            <span class="text">Categories</span>
          </div>
          <div class="container">
            <i class="fa-solid fa-location-pin icon"></i>
            <span class="num">{{ NumberOfPlaces }}</span>
            <span class="text">Places</span>
          </div>
          <div class="container">
            <i class="fa-regular fa-user icon"></i>
            <span class="num">{{ NumberOfUsers }}</span>
            <span class="text">Users</span>
          </div>
          <div class="container">
            <i class="fa-regular fa-eye icon"></i>
            <span class="num">{{ Views }}</span>
            <span class="text">Views</span>
          </div>
          <div class="container">
            <i class="fa-solid fa-map icon"></i>
            <span class="num">{{ Plans }}</span>
            <span class="text">Plans</span>
          </div>
          <div class="container">
            <i class="fa-regular fa-comment icon"></i>
            <span class="num">{{ Comments }}</span>
            <span class="text">Comments</span>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Stat boxes ===============-->

    <!--=============== Views and Messages tables ===============-->
    <div class="displayViews">
      <div>
        <h2 class="views_table_title">Views</h2>
        <div class="table">
          <div class="first_row">
            <div class="location">Location</div>
            <div class="placeName">Place Name</div>
            <div class="number">Views</div>
          </div>
          <div class="row" v-for="view in v" v-bind:key="view._id">
            <div class="location">{{ view.Location }}</div>
            <div class="placeName">
              <span v-if="view.Place.length != 0">
                {{ view.Place[0].Name }}
              </span>
            </div>
            <div class="number">{{ view.Number }}</div>
          </div>
        </div>
      </div>
      <div>
        <h2 class="views_table_title">Messages</h2>
        <div class="messages_table">
          <div class="first_row">
            <div class="Name">Name</div>
            <div class="Email">Email</div>
            <div class="Date">Date</div>
            <div class="View">View</div>
            <div class="Delete">Delete</div>
          </div>
          <div class="row" v-for="message in Messages" v-bind:key="message._id">
            <div class="Name">{{ message.Name }}</div>
            <div class="Email">{{ message.Email }}</div>
            <div class="Date">{{ message.date.split("T")[0] }}</div>
            <div class="View">
              <button
                class="view_btn"
                v-on:click="showMessagePopup(message.Content)"
              >
                <i class="fa-regular fa-eye"></i>
              </button>
            </div>
            <div class="Delete">
              <button class="del_btn" v-on:click="deleteMessage(message._id)">
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Views and Messages tables ===============-->

    <!--=============== Messages Popup ===============-->
    <div id="MessagePopup" v-if="showMessage">
      <div class="dispage">
        <div class="message">
          <div class="close">
            <button class="exit_button" v-on:click="closeMessagePopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <p>{{ Message }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Messages Popup ===============-->
  </div>
</template>

<script>
import axios from "axios";
import ApiUrl from "@/ApiUrl";
import AdminNav from "@/components/AdminNav.vue";
import jquery from "jquery";
let $ = jquery;
export default {
  name: "AdminDashboard",
  data() {
    return {
      NumberOfCategories: 0,
      NumberOfPlaces: 0,
      NumberOfUsers: 0,
      Views: 0,
      Plans: 0,
      Comments: 0,
      v: [],
      splaces: [],
      Messages: [],
      showMessage: false,
      Message: "",
    };
  },
  components: {
    AdminNav,
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    await axios
      .get(ApiUrl.url + "/Admin/Dashboard", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.NumberOfCategories = res.data.NumberofCategories;
        this.NumberOfPlaces = res.data.NumberofPlaces;
        this.NumberOfUsers = res.data.NumberofUsers;
        this.Views = res.data.Views;
        this.Plans = res.data.Plans;
        this.Comments = res.data.Comments;
      });
    await axios
      .get(ApiUrl.url + "/Admin/getSPlace", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.splaces = res.data;
      });

    await axios
      .get(ApiUrl.url + "/Admin/Views", {
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.v = res.data;
      });

    await axios
      .get(ApiUrl.url + "/api/Messages/Get", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.Messages = res.data;
      });
  },
  methods: {
    showMessagePopup(data) {
      this.Message = data;
      this.showMessage = true;
      setTimeout(() => {
        $("#MessagePopup").fadeIn(200);
      }, 0);
    },
    closeMessagePopup() {
      $("#MessagePopup").fadeOut(200);
      setTimeout(() => {
        this.showMessage = false;
      }, 1000);
    },
    async deleteMessage(id) {
      await axios
        .delete(ApiUrl.url + `/api/Messages/Delete/${id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Message Deleted") {
            for (let i = 0; i < this.Messages.length; i++) {
              if (id == this.Messages[i]._id) {
                this.Messages.splice(i, 1);
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/************************ Status ************************/
.boxes {
  text-align: left;
  margin-top: 45px;
  h3 {
    margin-left: 2rem;
    margin-bottom: 2rem;
    font-size: 28px;
    font-weight: 600;
  }
}
.cont {
  display: flex;
  justify-content: center;
}
.wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 2.2rem;
}
.container {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em 0;
  font-size: 16px;
  border-radius: 0.5em;
  background-color: #1f2833;
  border-bottom: 10px solid #472bff;
}
.icon {
  color: #472bff;
  font-size: 2.5em;
  text-align: center;
}
span.num {
  color: #ffffff;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 3em;
}
span.text {
  color: #e0e0e0;
  font-size: 1em;
  text-align: center;
  pad: 0.7em 0;
  font-weight: 600;
  line-height: 0;
  font-size: 22px;
}
/************************ Status ************************/
/************************ Views Table ************************/
.displayViews {
  margin-top: 4.5rem;
  text-align: left;
  padding-bottom: 4rem;
  display: flex;
}
.views_table_title {
  margin-left: 2rem;
  margin-bottom: 2rem;
  font-size: 28px;
  font-weight: 600;
}
.table {
  margin-left: 2rem;
  width: 700px;
  background-color: #1f2833;
  height: 400px;
  overflow-y: auto;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}
.first_row {
  height: 80px;
  display: flex;
  .location,
  .placeName,
  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #fff;
    border-right: 2px solid #bbb;
    border-bottom: 1px solid #bbb;
  }
  .location {
    width: 25%;
  }
  .placeName {
    width: 55%;
  }
  .number {
    width: 20%;
    border-right: none;
  }
}
.row {
  height: 80px;
  display: flex;
  .location,
  .placeName,
  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    border-right: 2px solid #bbb;
    border-bottom: 1px solid #bbb;
  }
  .location {
    color: #bbb;
    width: 25%;
  }
  .placeName {
    color: #bbb;
    width: 55%;
  }
  .number {
    color: #472bff;
    width: 20%;
    border-right: none;
  }
}
/************************ Views Table ************************/
/************************ Messages Table ************************/
.messages_table {
  margin-left: 2rem;
  width: 900px;
  background-color: #1f2833;
  height: 400px;
  overflow-y: auto;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  .first_row {
    display: flex;
    .Name,
    .Email,
    .Date,
    .View,
    .Delete {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      color: #fff;
      border-right: 2px solid #bbb;
      border-bottom: 1px solid #bbb;
    }
    .Name {
      width: 25%;
    }
    .Email {
      width: 35%;
    }
    .Date {
      width: 20%;
    }
    .View {
      width: 10%;
    }
    .Delete {
      width: 10%;
      border-right: 0;
    }
  }
  .row {
    height: 80px;
    display: flex;
    .Name,
    .Email,
    .Date,
    .View,
    .Delete {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      color: #bbb;
      border-right: 2px solid #bbb;
      border-bottom: 1px solid #bbb;
    }
    .Name {
      width: 25%;
    }
    .Email {
      width: 35%;
    }
    .Date {
      width: 20%;
    }
    .View {
      width: 10%;
    }
    .Delete {
      width: 10%;
      border-right: 0;
    }
  }
}
.view_btn,
.del_btn {
  outline: none;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 10px;
  background-color: #472bff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3822c4;
  }
}
/************************ Messages Table ************************/
/************************ Message Popup ************************/
#MessagePopup {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 5000;
  transform: translate(-50%, -50%);
  display: none;
}
.dispage {
  width: 100%;
  height: 100vh;
  background-color: #1f283398;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message {
  width: 600px;
  height: auto;
  background: #1f2833;
  border-radius: 25px;
}
.close {
  text-align: right;
  width: 100%;
  height: 50px;
}
.exit_button {
  margin: 1rem;
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  color: #eeeeee;
  font-size: 24px;
  transition: all 300ms ease-in-out;
}
.exit_button:hover {
  cursor: pointer;
  color: #472bff;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  text-align: center;
  overflow-wrap: break-word;
}
.form p {
  width: 480px;
  color: #fff;
}
/************************ Message Popup ************************/
</style>
