import Vue from "vue";
import ApiUrl from "./ApiUrl";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import i18n from "./i18n";

Vue.config.productionTip = false;
Vue.use(VueMeta);

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = "ar";
  }
  // set the current language for i18n.
  i18n.locale = language;
  next();
});

new Vue({
  ApiUrl,
  router,
  store,
  i18n,
  VueMeta,
  render: (h) => h(App),
}).$mount("#app");
