<template>
  <div class="p">
    <div class="a">
      <!--=============== Navbar ===============-->
      <div class="nav">
        <header>
          <div class="Icon">
            <div class="logo">
              <img src="../assets/logo.svg" />
            </div>
            <div class="title">
              <span>{{ $t("HomePage.Navbar.Title") }}</span>
            </div>
          </div>
          <div class="Links">
            <ul>
              <li>
                <router-link to="/">{{
                  $t("HomePage.Navbar.Link1")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Places' }">{{
                  $t("HomePage.Navbar.Link3")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'AddNewPlace' }">{{
                  $t("HomePage.Navbar.Link4")
                }}</router-link>
              </li>
              <li v-if="isAdminLoggedIn">
                <router-link :to="{ name: 'Dashboard' }">{{
                  $t("HomePage.Navbar.Link5")
                }}</router-link>
              </li>
              <li v-if="isLoggedIn && !isAdminLoggedIn">
                <router-link :to="{ name: 'Profile' }">{{
                  $t("HomePage.Navbar.Link7")
                }}</router-link>
              </li>
              <li v-if="isLoggedIn">
                <button class="logout_link" v-on:click="Logout()">
                  {{ $t("HomePage.Navbar.Link6") }}
                </button>
              </li>
              <li v-if="!isLoggedIn">
                <router-link :to="{ name: 'Login' }">
                  {{ $t("HomePage.Navbar.Link8") }}
                </router-link>
              </li>
              <li v-if="!isLoggedIn">
                <router-link :to="{ name: 'Signup' }" class="sign_up">
                  {{ $t("HomePage.Navbar.Link9") }}
                </router-link>
              </li>
            </ul>
            <button class="show_nav" v-on:click="openNav()">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line3"></div>
            </button>
          </div>
        </header>
      </div>
      <div class="mobile-nav">
        <ul>
          <li>
            <router-link to="/">{{ $t("HomePage.Navbar.Link1") }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Places' }">{{
              $t("HomePage.Navbar.Link3")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AddNewPlace' }">{{
              $t("HomePage.Navbar.Link4")
            }}</router-link>
          </li>
          <li v-if="isAdminLoggedIn">
            <router-link :to="{ name: 'Dashboard' }">{{
              $t("HomePage.Navbar.Link5")
            }}</router-link>
          </li>
          <li v-if="isLoggedIn && !isAdminLoggedIn">
            <router-link :to="{ name: 'Profile' }">{{
              $t("HomePage.Navbar.Link7")
            }}</router-link>
          </li>
          <li v-if="isLoggedIn">
            <button class="logout_link" v-on:click="Logout()">
              {{ $t("HomePage.Navbar.Link6") }}
            </button>
          </li>
          <li v-if="!isLoggedIn">
            <router-link :to="{ name: 'Login' }">
              {{ $t("HomePage.Navbar.Link8") }}
            </router-link>
          </li>
          <li v-if="!isLoggedIn">
            <router-link :to="{ name: 'Signup' }" class="sign_up">
              {{ $t("HomePage.Navbar.Link9") }}
            </router-link>
          </li>
        </ul>
      </div>
      <!--=============== Navbar ===============-->

      <!--=============== Account Information ===============-->
      <div class="info">
        <div class="account">
          <div class="fullName">
            <h3 class="info_title">{{ $t("Profile.Name") }}</h3>
            <h3 class="data">
              {{ User.FullName }}
              <button class="edit_btn" v-on:click="editFullNamePopup()">
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
            </h3>
          </div>
          <div class="email">
            <h3 class="info_title">{{ $t("Profile.email") }}</h3>
            <h3 class="data">
              {{ User.Email }}
              <button class="edit_btn">
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
            </h3>
          </div>
          <div class="date">
            <h3 class="info_title">{{ $t("Profile.date") }}</h3>
            <h3 class="data">{{ User.date.split("T")[0] }}</h3>
          </div>
          <div class="updatePassword">
            <p v-on:click="editPasswordPopup()">
              {{ $t("Profile.updatePassword") }}
            </p>
          </div>
          <div class="deleteAccount">
            <button class="delBtn" v-on:click="openDeletePopup()">
              {{ $t("Profile.delete") }}
            </button>
          </div>
        </div>
      </div>
      <!--=============== Account Information ===============-->

      <!--=============== Plans  ===============-->
      <div class="plans">
        <h2>{{ $t("Profile.title2") }} {{ User.FullName }}</h2>
        <div class="pl">
          <div class="plan" v-for="plan in plans" v-bind:key="plan._id">
            <div class="planName_view">
              <div class="name">
                <h3>{{ plan.PlanName }}</h3>
              </div>
              <div class="view">
                <button class="edit_btn" v-on:click="viewPlan(plan._id)">
                  <i class="fa-regular fa-eye"></i>
                </button>
              </div>
            </div>
            <div>
              {{ plan.date.split("T")[0] }}
            </div>
          </div>
          <div v-if="plans.length == 0" class="no_plans">
            {{ $t("Profile.noOutings") }}
          </div>
        </div>
      </div>
      <!--=============== Plans  ===============-->

      <!--=============== Edit popup ===============-->
      <div id="EditPopup" v-if="showPopup">
        <div class="dispage">
          <div class="edit">
            <div class="close">
              <button class="exit_button" v-on:click="closeEditPopup()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="form" v-if="editFullName">
              <div>
                <h2 class="form-title">{{ $t("Profile.updateFullName") }}</h2>

                <div class="input">
                  <div class="in">
                    <input
                      type="text"
                      class="text-input"
                      v-model="newFullName"
                      id="newFullName"
                      :placeholder="$t('Profile.FullName')"
                    />
                    <div class="err" id="newFullName_err"></div>
                  </div>
                </div>
                <div class="input">
                  <button class="btn" v-on:click="updateName()">
                    {{ $t("Profile.update") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="form" v-if="editEmail"></div>
            <div class="form" v-if="editPassword">
              <div>
                <h2 class="form-title">{{ $t("Profile.updatePassword") }}</h2>

                <div class="input">
                  <div class="in">
                    <input
                      type="password"
                      class="text-input"
                      id="oldPassword"
                      v-model="oldPassword"
                      :placeholder="$t('Profile.oldPassword')"
                    />
                    <div class="err" id="oldPassword_err"></div>
                  </div>
                </div>
                <div class="input">
                  <div class="in">
                    <input
                      type="password"
                      class="text-input"
                      id="newPassword"
                      v-model="newPassword"
                      :placeholder="$t('Profile.newPassword')"
                    />
                    <div class="err" id="newPassword_err"></div>
                  </div>
                </div>
                <div class="input">
                  <div class="in">
                    <input
                      type="password"
                      class="text-input"
                      id="confirmPassword"
                      v-model="confirmPassword"
                      :placeholder="$t('Profile.confirmPassword')"
                    />
                    <div class="err" id="confirmPassword_err"></div>
                  </div>
                </div>
                <div class="input">
                  <button class="btn" v-on:click="updatePassword()">
                    {{ $t("Profile.update") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--=============== Edit popup ===============-->
      <!--=============== Delete popup ===============-->
      <div id="deletePopup" v-if="showDeletePopup">
        <div class="dispage">
          <div class="edit">
            <div class="close">
              <button class="exit_button" v-on:click="closeDeletePopup()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="del_pop_content">
              <h2>{{ $t("Profile.deleteTitle") }}</h2>
              <div class="btns">
                <button class="yes" v-on:click="deleteUser()">
                  {{ $t("Profile.yes") }}
                </button>
                <button class="no" v-on:click="closeDeletePopup()">
                  {{ $t("Profile.no") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--=============== Delete popup ===============-->
    </div>
    <!--=============== Footer ===============-->
    <div class="footer">
      <FooterSide />
    </div>
    <!--=============== Footer ===============-->
  </div>
</template>

<script>
import FooterSide from "../components/FooterSide.vue";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
import jquery from "jquery";
let $ = jquery;
export default {
  name: "ProfileView",
  components: {
    FooterSide,
  },
  data() {
    return {
      isLoggedIn: false,
      isAdminLoggedIn: false,
      User: {
        date: "_",
      },
      showPopup: false,
      editFullName: false,
      editEmail: false,
      editPassword: false,
      newFullName: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      plans: [],
      showDeletePopup: false,
    };
  },
  async mounted() {
    this.isLoggedIn = await this.$store.getters.isLoggedIn;
    this.isAdminLoggedIn = await this.$store.getters.isAdmin;
    this.$store.dispatch("isUserLoggedIn");
    await axios
      .get(ApiUrl.url + "/api/User/Get", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.User = res.data;
      });
    let page = document.getElementsByClassName("p")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
    }
    await axios
      .post(ApiUrl.url + "/Community/Views", {
        Location: "Profile",
        placeId: "",
      })
      .then(() => {})
      .catch(() => {});
    await axios
      .get(ApiUrl.url + "/api/User/Get/Plans", {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.plans = res.data;
      });
  },
  methods: {
    openNav() {
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
      } else {
        nav.classList.remove("show");
      }
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
    closeEditPopup() {
      $("#EditPopup").fadeOut(200);
      setTimeout(() => {
        this.showPopup = false;
        this.editFullName = false;
        this.editEmail = false;
        this.editPassword = false;
      }, 1000);
    },
    openDeletePopup() {
      this.showDeletePopup = true;
      setTimeout(() => {
        $("#deletePopup").fadeIn(200);
      }, 0);
    },
    closeDeletePopup() {
      $("#deletePopup").fadeOut(200);
      setTimeout(() => {
        this.showDeletePopup = false;
      }, 1000);
    },
    editFullNamePopup() {
      this.showPopup = true;
      this.editFullName = true;
      this.newFullName = this.User.FullName;
      setTimeout(() => {
        $("#EditPopup").fadeIn(200);
      }, 0);
    },
    async updateName() {
      $("#newFullName").click(function () {
        window.onkeydown = function () {
          $("#newFullName").css("borderBottom", "2px solid #472bff");
          $("#newFullName_err").text("");
        };
      });
      if (this.newFullName.trim().length == 0 || this.newFullName == "") {
        $("#newFullName").css("borderBottom", "2px solid rgb(250,82,82)");
        $("#newFullName_err").text(this.$i18n.t("Profile.err1"));
        return;
      }
      await axios
        .patch(
          ApiUrl.url + "/api/User/Update/FullName",
          { newFullName: this.newFullName },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            location.reload();
          }
        });
    },
    editPasswordPopup() {
      this.showPopup = true;
      this.editPassword = true;
      setTimeout(() => {
        $("#EditPopup").fadeIn(200);
      }, 0);
    },
    async updatePassword() {
      $("#oldPassword").click(function () {
        window.onkeydown = function () {
          $("#oldPassword").css("borderBottom", "2px solid #472bff");
          $("#oldPassword_err").text("");
        };
      });

      $("#newPassword").click(function () {
        window.onkeydown = function () {
          $("#newPassword").css("borderBottom", "2px solid #472bff");
          $("#newPassword_err").text("");
        };
      });

      $("#confirmPassword").click(function () {
        window.onkeydown = function () {
          $("#confirmPassword").css("borderBottom", "2px solid #472bff");
          $("#confirmPassword_err").text("");
        };
      });

      if (
        (this.oldPassword.trim().length == 0 || this.oldPassword == "") &&
        (this.newPassword.trim().length == 0 || this.newPassword == "") &&
        (this.confirmPassword.trim().length == 0 || this.confirmPassword == "")
      ) {
        $("#oldPassword").css("borderBottom", "2px solid rgb(250,82,82)");
        $("#oldPassword_err").text(this.$i18n.t("Profile.err2"));

        $("#newPassword").css("borderBottom", "2px solid rgb(250,82,82)");
        $("#newPassword_err").text(this.$i18n.t("Profile.err3"));

        $("#confirmPassword").css("borderBottom", "2px solid rgb(250,82,82)");
        $("#confirmPassword_err").text(this.$i18n.t("Profile.err4"));
        return;
      }

      if (this.oldPassword.trim().length == 0 || this.oldPassword == "") {
        $("#oldPassword").css("borderBottom", "2px solid rgb(250,82,82)");
        $("#oldPassword_err").text(this.$i18n.t("Profile.err2"));

        return;
      }

      if (this.newPassword.trim().length == 0 || this.newPassword == "") {
        $("#newPassword").css("borderBottom", "2px solid rgb(250,82,82)");
        $("#newPassword_err").text(this.$i18n.t("Profile.err3"));
        return;
      }

      if (
        this.confirmPassword.trim().length == 0 ||
        this.confirmPassword == ""
      ) {
        $("#confirmPassword").css("borderBottom", "2px solid rgb(250,82,82)");
        $("#confirmPassword_err").text(this.$i18n.t("Profile.err4"));
        return;
      }

      if (this.newPassword != this.confirmPassword) {
        $("#newPassword").css("borderBottom", "2px solid rgb(250,82,82)");
        $("#newPassword_err").text(this.$i18n.t("Profile.err6"));
        return;
      }

      await axios
        .patch(
          ApiUrl.url + "/api/User/Update/Password",
          { oldPassword: this.oldPassword, newPassword: this.newPassword },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.err == "Password is Wrong") {
            $("#oldPassword").css("borderBottom", "2px solid rgb(250,82,82)");
            $("#oldPassword_err").text(this.$i18n.t("Profile.err5"));
            return;
          }

          if (res.data.msg == "Password updated") {
            location.reload();
          }
        });
    },
    viewPlan(id) {
      this.$router.push(`/${this.$i18n.locale}/Plan/${id}`);
    },
    async deleteUser() {
      await axios
        .delete(ApiUrl.url + "/Community/Delete", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "User Deleted") {
            localStorage.clear();
            this.$router.push({ name: "home" });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.p {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  direction: rtl;
}
.nav {
  height: 80px;
  z-index: 3000;
}
.a {
  flex: 1;
}
.footer {
  margin-top: auto;
}
/************************ Header ************************/
header {
  width: 100%;
  height: 80px;
  background: #1f2833;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  z-index: 3000;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}
.Icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.logo {
  img {
    width: 80px;
    height: 80px;
  }
}
.title span {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
ul {
  list-style-type: none;
}
.Links ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  li a {
    font-weight: 800;
    margin: 2rem;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #472bff;
    }
  }
}
.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
.line1,
.line3 {
  width: 30px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.line2 {
  width: 25px;
  height: 2px;
  background-color: #eeeeee;
  transition: all 200ms ease-in-out;
}
.logout_link {
  background: none;
  border: none;
  outline: none;
  font-weight: 800;
  font-size: 16px;
  margin: 2rem;
  color: #eeeeee;
  transition: all 0.35s ease-out;
  &:hover {
    cursor: pointer;
    color: #472bff;
  }
}
.show_nav:hover {
  cursor: pointer;
}
.mobile-nav {
  ul {
    display: none;
  }
  ul li {
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #472bff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  width: 100%;
  ul {
    display: block;
  }
  ul li {
    opacity: 1;
  }
  ul li a {
    display: block;
  }
}
@media screen and (max-width: 880px) {
  .Links ul {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .show {
    display: none;
  }
}
/************************ Header ************************/

/************************ Info ************************/
.info {
  margin-top: 4rem;
  margin-right: 2rem;
  text-align: right;
}
.fullName,
.email,
.date,
.updatePassword,
.deleteAccount {
  margin: 1rem;
  display: flex;
  align-items: center;
}
.info_title {
  width: 130px;
  font-weight: bold;
}
.data {
  display: flex;
  align-items: center;
}
.edit_btn {
  outline: none;
  border: none;
  background: none;
  margin-right: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: #472bff;
    font-size: 18px;
  }
  &:hover {
    cursor: pointer;
  }
}
.updatePassword {
  p {
    color: #472bff;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.delBtn {
  width: 270px;
  height: 40px;
  background: none;
  border: none;
  color: #fff;
  outline: none;
  font-weight: bold;
  background-color: rgb(250, 82, 82);
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: rgb(205, 65, 65);
  }
}
/************************ Info ************************/
/************************ Edit Popup ************************/
#EditPopup,
#deletePopup {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 5000;
  transform: translate(-50%, -50%);
  // display: none;
}
.dispage {
  width: 100%;
  height: 100vh;
  background-color: #1f283398;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit {
  width: 600px;
  height: auto;
  background: #1f2833;
  border-radius: 15px;
}
.close {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 40px;
}
.exit_button {
  margin: 1rem;
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  color: #eeeeee;
  font-size: 24px;
  transition: all 300ms ease-in-out;
}
.exit_button:hover {
  cursor: pointer;
  color: #472bff;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.del_pop_content {
  color: #fff;
  font-weight: bold;
  padding: 2rem;
}
.btns {
  margin-top: 1rem;
}
.yes,
.no {
  background: none;
  outline: none;
  border: none;
  width: 70px;
  height: 40px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  color: #fff;
  font-weight: bold;
  margin: 0.5rem;
  border-radius: 5px;
}
.yes {
  background-color: #67da44;
  &:hover {
    background-color: #4cae2e;
  }
}
.no {
  background-color: rgb(250, 82, 82);
  &:hover {
    background-color: rgb(205, 65, 65);
  }
}
/************************ Edit Popup ************************/
.form-title {
  font-weight: 600;
  color: #eee;
}
.input {
  display: flex;
  padding: 1rem;
  padding-top: 2rem;
}
.in {
  display: block;
}
.text-input {
  outline: none;
  border: none;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  background: none;
  transition: all 300ms ease-in-out;
  color: #eee;
  width: 300px;
  border-bottom: 2px solid #472bff;
}
.text-input::placeholder {
  color: #eee;
}
.err {
  color: rgb(250, 82, 82);
  font-weight: 600;
  padding-top: 0.5rem;
}
.btn {
  outline: none;
  background: none;
  border: none;
  background-color: #472bff;
  width: 320px;

  height: 40px;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  color: #eee;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    background-color: #3520b9;
  }
}
.plans {
  margin-top: 3rem;
  margin-right: 2rem;
}
.plans h2 {
  font-weight: bold;
  text-align: right;
  margin: 1rem;
}
.pl {
  text-align: right;
}
.plan {
  margin: 1rem;
  font-weight: bold;
}
.planName_view {
  display: flex;
  align-items: center;
}
.name,
.view {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name h3 {
  font-weight: bold;
}
.no_plans {
  font-weight: bold;
  margin-right: 1rem;
}
</style>
