<template>
  <div class="page">
    <div class="a">
      <div class="nav">
        <!--============== Header ==============-->
        <NavBar />
      </div>

      <div class="msg_form">
        <div class="message" v-if="!sent">
          <div class="input">
            <div class="input-label">{{ $t("MessageUs.Name") }}</div>
            <div>
              <input
                type="text"
                class="text-input"
                id="name_input"
                v-model="Name"
                :placeholder="$t('MessageUs.Name')"
              />
              <div class="err" id="name_err"></div>
            </div>
          </div>
          <div class="input">
            <div class="input-label">{{ $t("MessageUs.Email") }}</div>
            <div>
              <input
                type="email"
                class="text-input"
                id="email_input"
                v-model="Email"
                :placeholder="$t('MessageUs.Email')"
              />
              <div class="err" id="email_err"></div>
            </div>
          </div>
          <div class="input">
            <div class="input-label">{{ $t("MessageUs.Content") }}</div>
            <div>
              <input
                type="text"
                class="text-input"
                id="message_input"
                v-model="Message"
                :placeholder="$t('MessageUs.Content')"
              />
              <div class="err" id="message_err"></div>
            </div>
          </div>
          <div>
            <button class="sendBtn" v-on:click="sendMessage()">
              {{ $t("MessageUs.Sent") }}
            </button>
          </div>
        </div>
      </div>
      <div class="thx" v-if="sent">
        <h2>{{ $t("MessageUs.thx") }}</h2>
      </div>
    </div>
    <div class="footer">
      <FooterSide />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterSide from "@/components/FooterSide.vue";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      isLoggedIn: false,
      isAdminLoggedIn: false,
      Name: "",
      Email: "",
      Message: "",
      sent: false,
    };
  },
  components: {
    NavBar,
    FooterSide,
  },
  async mounted() {
    this.isLoggedIn = await this.$store.getters.isLoggedIn;
    this.isAdminLoggedIn = await this.$store.getters.isAdmin;
    let page = document.getElementsByClassName("page")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
    }
    await axios
      .post(ApiUrl.url + "/Community/Views", {
        Location: "Message",
        placeId: "",
      })
      .then(() => {})
      .catch(() => {});
  },
  methods: {
    openNav() {
      let bar = document.getElementById("head");
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
        bar.classList.add("border");
      } else {
        nav.classList.remove("show");
        bar.classList.remove("border");
      }
      window.onresize = function () {
        if (window.innerWidth >= 1057) {
          nav.classList.remove("show");
          bar.classList.remove("border");
        }
      };
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
    async sendMessage() {
      $("#name_input").click(function () {
        window.onkeydown = function () {
          $("#name_input").css("border", "1.2px solid #bbb");
          $("#name_err").text("");
        };
      });

      $("#email_input").click(function () {
        window.onkeydown = function () {
          $("#email_input").css("border", "1.2px solid #bbb");
          $("#email_err").text("");
        };
      });

      $("#message_input").click(function () {
        window.onkeydown = function () {
          $("#message_input").css("border", "1.2px solid #bbb");
          $("#message_err").text("");
        };
      });

      if (this.Name == "" && this.Email == "" && this.Message == "") {
        $("#name_input").css("border", "1.2px solid rgb(250,82,82)");
        $("#name_err").text(this.$i18n.t("MessageUs.err1"));

        $("#email_input").css("border", "1.2px solid rgb(250,82,82)");
        $("#email_err").text(this.$i18n.t("MessageUs.err2"));

        $("#message_input").css("border", "1.2px solid rgb(250,82,82)");
        $("#message_err").text(this.$i18n.t("MessageUs.err3"));
        return;
      }

      if (this.Name == "") {
        $("#name_input").css("border", "1.2px solid rgb(250,82,82)");
        $("#name_err").text(this.$i18n.t("MessageUs.err1"));
        return;
      }
      if (this.Email == "") {
        $("#email_input").css("border", "1.2px solid rgb(250,82,82)");
        $("#email_err").text(this.$i18n.t("MessageUs.err2"));
        return;
      }
      if (this.Message == "") {
        $("#message_input").css("border", "1.2px solid rgb(250,82,82)");
        $("#message_err").text(this.$i18n.t("MessageUs.err3"));
        return;
      }
      await axios
        .post(ApiUrl.url + "/api/Messages/Add", {
          Name: this.Name,
          Email: this.Email,
          Content: this.Message,
        })
        .then((res) => {
          if (res.data.m == "Message Sent") {
            this.sent = true;
            setTimeout(() => {
              this.$router.push({ name: "home" });
            }, 850);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-weight: 700;
}
.page {
  direction: rtl;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.a {
  flex: 1;
}
.footer {
  margin-top: auto;
}
/******************* Header *******************/
.nav {
  height: 200px;
}
.msg_form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.input-label {
  width: 120px;
  text-align: right;
}
.text-input {
  width: 400px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border: 1.2px solid #bbbbbb;
  border-radius: 5px;
}
.sendBtn {
  outline: none;
  border: none;
  background: none;
  width: 542px;
  height: 45px;
  font-size: 16px;
  color: #fff;
  background-color: #472bff;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #331fb9;
  }
}
.err {
  color: rgb(250, 82, 82);
}
@media screen and (max-width: 540px) {
  .text-input {
    width: 300px;
  }
  .sendBtn {
    width: 457px;
  }
}
@media screen and (max-width: 450px) {
  .input {
    display: block;
    width: 300px;
  }
  .input-label {
    margin: 0.5rem;
  }
  .sendBtn {
    width: 322px;
  }
}
</style>
