<template>
  <div>
    <div class="nav">
      <AdminNav />
    </div>
    <div class="manage">
      <h2>Manage Tags</h2>
    </div>
    <!--=============== Add and Search ===============-->
    <div class="intro">
      <div>
        <button class="add_btn" v-on:click="showAddPopup()">Add Tag</button>
      </div>
      <div class="search_place">
        <input
          type="text"
          placeholder="Search"
          class="search_input"
          name="search"
          @keyup.enter="SearchTag()"
          v-model="search"
          autocomplete="off"
        />
        <button class="search_btn" v-on:click="SearchTag()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
    <!--=============== Add and Search ===============-->
    <!--=============== Display Tags ===============-->
    <div class="DisplayTags">
      <div class="wrapper">
        <div
          v-for="tag in Tags"
          :key="tag._id"
          :id="`tag-${tag._id}`"
          class="tag"
        >
          <div class="buttons">
            <button
              v-on:click="showEditPopup(tag._id, tag.NameEN, tag.NameAR)"
              class="edit_btn"
            >
              <i class="fa-regular fa-pen-to-square"></i>
            </button>
            <button v-on:click="delTag(tag._id)" class="del_btn">
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
          <div class="tag_content">
            {{ tag.NameEN }}
          </div>
        </div>
      </div>
    </div>
    <!--=============== Display Tags ===============-->
    <!--=============== Add Tag Popup ===============-->
    <div id="AddTag" v-if="showAdd">
      <div class="dispage">
        <div class="AddC">
          <div class="close">
            <button class="exit_button" v-on:click="closeAddPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <form @submit.prevent="addTag()" method="post">
              <h3 style="color: #eee">Add Tag</h3>
              <!--============= Tag english Name  =============-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="TagNameEN"
                    class="name-input"
                    id="tagname_en_input"
                    placeholder="Enter an english Tag Name"
                  />
                  <br />
                  <label for="name" class="tag_name_en_input_label"></label>
                </div>
              </div>
              <!--============= Tag arabic Name  =============-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="TagNameAR"
                    class="name-input"
                    id="tagname_ar_input"
                    placeholder="Enter an arabic Tag Name"
                  />
                  <br />
                  <label for="name" class="tag_name_ar_input_label"></label>
                </div>
              </div>
              <div class="input">
                <button class="submit">Add a Tag</button>
              </div>
              <div class="input" id="success_msg">
                <label for="input" class="success-msg-1"></label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Add Tag Popup ===============-->
    <!--=============== Edit Tag Popup ===============-->
    <div id="EditTag" v-if="showEdit">
      <div class="dispage">
        <div class="AddC">
          <div class="close">
            <button class="exit_button" v-on:click="closeEditPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <form @submit.prevent="editTag()" method="post">
              <h3 style="color: #eee">Edit Tag</h3>
              <!--================= ETag english Name =================-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="ETagNameEN"
                    class="name-input"
                    id="Etagname_en_input"
                    placeholder="Enter an English Tag Name"
                  />
                  <br />
                  <label for="name" class="Etag_name_en_input_label"></label>
                </div>
              </div>
              <!--================= ETag arabic Name =================-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="ETagNameAR"
                    class="name-input"
                    id="Etagname_ar_input"
                    placeholder="Enter an arabic Tag Name"
                  />
                  <br />
                  <label for="name" class="Etag_name_ar_input_label"></label>
                </div>
              </div>
              <div class="input">
                <button class="submit">Edit a Tag</button>
              </div>
              <div class="input" id="success_msg">
                <label for="input" class="success-msg-2"></label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Edit Tag Popup ===============-->
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import AdminNav from "@/components/AdminNav.vue";
import axios from "axios";
import jquery from "jquery";
let $ = jquery;
export default {
  components: {
    AdminNav,
  },
  data() {
    return {
      search: "",
      Tags: [],
      showAdd: false,
      TagNameAR: "",
      TagNameEN: "",
      showEdit: false,
      eId: "",
      ETagNameEN: "",
      ETagNameAR: "",
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    this.loadTags();
  },
  methods: {
    async loadTags() {
      await axios
        .get(ApiUrl.url + "/api/Tags/Get", {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          this.Tags = res.data;
        });
    },
    showAddPopup() {
      this.showAdd = true;
    },
    closeAddPopup() {
      this.showAdd = false;
    },
    showEditPopup(id, TagNameEN, TagNameAR) {
      this.showEdit = true;
      this.eId = id;
      this.ETagNameEN = TagNameEN;
      this.ETagNameAR = TagNameAR;
    },
    closeEditPopup() {
      this.showEdit = false;
    },
    async SearchTag() {
      if (this.search == "") {
        this.loadTags();
        return;
      } else {
        await axios
          .post(
            ApiUrl.url + "/api/Tags/Search",
            { query: this.search },
            {
              headers: {
                token: localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.Tags = res.data;
          });
      }
    },
    async addTag() {
      $("#tagname_en_input").click(function () {
        window.onkeydown = function () {
          $("#tagname_en_input").css("borderBottom", "3px solid #472bff");
          $(".tag_name_en_input_label").text("");
        };
      });

      $("#tagname_ar_input").click(function () {
        window.onkeydown = function () {
          $("#tagname_ar_input").css("borderBottom", "3px solid #472bff");
          $(".tag_name_ar_input_label").text("");
        };
      });

      if (this.TagNameEN == "" && this.TagNameAR == "") {
        $("#tagname_en_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".tag_name_en_input_label").text("Please Enter an english Tag Name");

        $("#tagname_ar_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".tag_name_ar_input_label").text("Please Enter an arabic Tag Name");

        return;
      }

      if (this.TagNameEN == "") {
        $("#tagname_en_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".tag_name_en_input_label").text("Please Enter an english Tag Name");
        return;
      }

      if (this.TagNameAR == "") {
        $("#tagname_ar_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".tag_name_ar_input_label").text("Please Enter an arabic Tag Name");
        return;
      }

      await axios
        .post(
          ApiUrl.url + "/api/Tags/Add",
          { NameAR: this.TagNameAR, NameEN: this.TagNameEN },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Tag Created") {
            $(".success-msg-1").text("Tag Created");
            this.loadTags();
            this.TagName = "";
          }
        });
    },
    async editTag() {
      $("#Etagname_en_input").click(function () {
        window.onkeydown = function () {
          $("#Etagname_en_input").css("borderBottom", "3px solid #472bff");
          $(".Etag_name_en_input_label").text("");
        };
      });

      $("#Etagname_ar_input").click(function () {
        window.onkeydown = function () {
          $("#Etagname_ar_input").css("borderBottom", "3px solid #472bff");
          $(".Etag_name_ar_input_label").text("");
        };
      });

      if (this.ETagNameEN == "" && this.ETagNameAR == "") {
        $("#Etagname_en_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Etag_name_en_input_label").text("Please Enter an English Tag Name");

        $("#Etagname_ar_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Etag_name_ar_input_label").text("Please Enter an arabic Tag Name");
        return;
      }

      if (this.ETagNameEN == "") {
        $("#Etagname_en_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Etag_name_en_input_label").text("Please Enter an English Tag Name");
        return;
      }

      if (this.ETagNameAR == "") {
        $("#Etagname_ar_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Etag_name_ar_input_label").text("Please Enter an arabic Tag Name");
        return;
      }

      await axios
        .patch(
          ApiUrl.url + "/api/Tags/Edit",
          {
            id: this.eId,
            newNameAR: this.ETagNameAR,
            newNameEN: this.ETagNameEN,
          },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then((res) => {
          if (res.data.msg == "Tag Updated") {
            $(".success-msg-2").text("Tag Name Updated");
            this.loadTags();
          }
        });
    },
    async delTag(id) {
      await axios
        .delete(ApiUrl.url + `/api/Tags/Delete/${id}`, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Tag Deleted") {
            this.loadTags();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  height: 80px;
}
.manage {
  display: flex;
  justify-content: left;
  align-items: center;
  h2 {
    margin: 3rem;
    margin-top: 2rem;
    margin-bottom: 0rem;
  }
}
/************************ Add and Search ************************/
.intro {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
}
.add_btn {
  width: 120px;
  height: 40px;
  background-color: #472bff;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  margin-right: 1rem;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: #331ec0;
    cursor: pointer;
  }
}
.search_place {
  display: flex;
  align-items: center;
}
.search_input {
  width: 365px;
  height: 40px;
  border: none;
  background: none;
  outline: none;
  padding-left: 10px;
  color: #1f2833;
  font-weight: 700;
  background-color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}
.search_input:focus {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}
.search_btn {
  width: 40px;
  height: 40px;
  margin: 1rem;
  background-color: #472bff;
  border: none;
  outline: none;
  border-radius: 10px;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
.search_input::placeholder {
  color: #1f2833;
}

/************************ Add and Search ************************/
/************************ Display Tags ************************/
.DisplayTags {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.wrapper {
  display: grid;
  gap: 40px;
  grid-template-columns: auto auto auto auto auto auto;
}
.tag {
  display: inline;
  padding: 1rem;
  color: #fff;
  border-radius: 10px;
  overflow-wrap: break-word;
  background-color: #472bff;
  display: flex;
  align-items: center;
  transition: all 300ms ease-in-out;
}
.tag_content {
  display: flex;
  align-items: center;
}
.buttons {
  transform: translateY(-50px);
  opacity: 0;
  transition: all 300ms ease-in-out;
  button {
    border: none;
    background: none;
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: #472bff;
    color: #fff;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #eee;
      color: #472bff;
    }
  }
}
.edit_btn {
  margin-right: 10px;
}
.tag:hover {
  transform: scale(1.07);
  .buttons {
    opacity: 1;
  }
}
/************************ Display Tags ************************/
/************************ Add Tag Popup ************************/
#AddTag,
#EditTag {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 3500;
  transform: translate(-50%, -50%);
}
.dispage {
  width: 100%;
  height: 100vh;
  background-color: #1f283398;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddC {
  width: 500px;
  height: 400px;
  background: #1f2833;
  border-radius: 25px;
}
.close {
  text-align: right;
  width: 100%;
  height: 50px;
}
.exit_button {
  margin: 1rem;
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  color: #eeeeee;
  font-size: 24px;
  transition: all 300ms ease-in-out;
}
.exit_button:hover {
  cursor: pointer;
  color: #472bff;
}
.form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.input {
  padding: 20px;
  div {
    text-align: left;
  }
}
.tag_name_en_input_label,
.tag_name_ar_input_label,
.Etag_name_en_input_label,
.Etag_name_ar_input_label {
  color: rgb(250, 82, 82);
}
.name-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  font-size: 15px;
  color: #eeeeee;
  border-bottom: 3px solid #472bff;
}
.submit {
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #472bff;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.submit:hover {
  cursor: pointer;
  background-color: #3721c5;
}
.success-msg-1,
.success-msg-2 {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
/************************ Add Tag Popup ************************/
</style>
