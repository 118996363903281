<template>
  <div class="p">
    <!--=============== Navbar ===============-->
    <div class="nav">
      <NavBar />
    </div>
    <!--=============== Navbar ===============-->
    <!--=============== Add a new place ===============-->
    <div class="box">
      <div class="container">
        <div class="terms">
          <div>
            <h2>{{ $t("RequestNewPlace.Terms") }}</h2>
            <div>
              <ul>
                <li>{{ $t("RequestNewPlace.rule1") }}.</li>
                <li>{{ $t("RequestNewPlace.rule2") }}.</li>
                <li>{{ $t("RequestNewPlace.rule3") }}.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="addplace">
          <div v-if="!success">
            <form class="form" @submit.prevent="Add()" method="post">
              <h3>{{ $t("RequestNewPlace.formTitle") }}</h3>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="Name"
                    autocomplete="off"
                    class="name-input"
                    v-model="email"
                    id="email"
                    :placeholder="$t('RequestNewPlace.Email')"
                  />
                  <br />
                  <label for="email" class="error_msg_1"></label>
                </div>
              </div>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="Name"
                    autocomplete="off"
                    class="name-input"
                    v-model="PlaceName"
                    id="Place_name"
                    :placeholder="$t('RequestNewPlace.PlaceName')"
                  />
                  <br />
                  <label for="Place_name" class="error_msg_2"></label>
                </div>
              </div>
              <div class="input">
                <h4>{{ $t("RequestNewPlace.max") }}</h4>
                <div class="choose_file">
                  <label for="choose_file">
                    <input
                      type="file"
                      name="Images"
                      multiple
                      ref="file"
                      @change="onFileSelected"
                      id="choose_file"
                    />
                    <span class="file_err">{{
                      $t("RequestNewPlace.upload")
                    }}</span>
                  </label>
                </div>
              </div>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="WorkHours"
                    autocomplete="off"
                    class="name-input"
                    v-model="WorkHours"
                    id="workHours_input"
                    :placeholder="$t('RequestNewPlace.workHours')"
                  />
                  <br />
                  <label for="workHours_input" class="error_msg_3"></label>
                </div>
              </div>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="Address"
                    autocomplete="off"
                    class="name-input"
                    v-model="Address"
                    id="address_input"
                    :placeholder="$t('RequestNewPlace.Address')"
                  />
                  <br />
                  <label for="address_input" class="error_msg_4"></label>
                </div>
              </div>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="Description"
                    autocomplete="off"
                    class="name-input"
                    v-model="Description"
                    id="description_input"
                    :placeholder="$t('RequestNewPlace.Description')"
                  />
                  <br />
                  <label for="description_input" class="error_msg_5"></label>
                </div>
              </div>
              <div class="input">
                <button class="submit">
                  {{ $t("RequestNewPlace.submit") }}
                </button>
              </div>
              <div class="input">
                <label id="success_msg_1" style="color: #eee"></label>
              </div>
            </form>
          </div>
          <div v-if="success">
            <h3>{{ $t("RequestNewPlace.Thx") }}</h3>
            <p>
              {{ $t("RequestNewPlace.message") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Add a new place ===============-->
    <!--=============== Footer ===============-->
    <div class="footer">
      <FooterSide />
    </div>
    <!--=============== Footer ===============-->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterSide from "@/components/FooterSide.vue";
import axios from "axios";
import JQuery from "jquery";
import ApiUrl from "@/ApiUrl";
let $ = JQuery;
export default {
  components: {
    NavBar,
    FooterSide,
  },
  data() {
    return {
      email: "",
      PlaceName: "",
      PlaceImages: [],
      WorkHours: "",
      Address: "",
      Description: "",
      success: false,
    };
  },
  async mounted() {
    let page = document.getElementsByClassName("p")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
    }
    await axios
      .post(ApiUrl.url + "/Community/Views", {
        Location: "AddNewPlace",
        placeId: "",
      })
      .then(() => {})
      .catch(() => {});
  },
  methods: {
    onFileSelected() {
      this.PlaceImages = this.$refs.file.files;
    },
    async Add() {
      $("#email").click(function () {
        window.onkeyup = function () {
          $(".error_msg_1").text("");
          $("#email").css("borderBottom", "3px solid #472bff");
        };
      });
      $("#Place_name").click(function () {
        window.onkeyup = function () {
          $(".error_msg_2").text("");
          $("#Place_name").css("borderBottom", "3px solid #472bff");
        };
      });
      $("#workHours_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_3").text("");
          $("#workHours_input").css("borderBottom", "3px solid #472bff");
        };
      });
      $("#address_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_4").text("");
          $("#address_input").css("borderBottom", "3px solid #472bff");
        };
      });
      $("#description_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_5").text("");
          $("#description_input").css("borderBottom", "3px solid #472bff");
        };
      });
      $(".file_err").click(function () {
        $(".choose_file label").css("border", "2px dashed #472bff");
      });
      if (
        this.email == "" &&
        this.PlaceName == "" &&
        this.WorkHours == "" &&
        this.Address == "" &&
        this.PlaceImages.length == 0
      ) {
        $("#email").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_1").text(this.$i18n.t("RequestNewPlace.err1"));

        $("#Place_name").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_2").text(this.$i18n.t("RequestNewPlace.err2"));

        $(".file_err").text(this.$i18n.t("RequestNewPlace.err5"));
        $(".choose_file label").css("border", "2px dashed rgb(250,82,82)");

        $("#workHours_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_3").text(this.$i18n.t("RequestNewPlace.err3"));

        $("#address_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_4").text(this.$i18n.t("RequestNewPlace.err4"));

        return;
      }
      if (this.email == "") {
        $("#email").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_1").text(this.$i18n.t("RequestNewPlace.err1"));
        return;
      }
      if (this.PlaceName == "") {
        $("#Place_name").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_2").text(this.$i18n.t("RequestNewPlace.err2"));
        return;
      }
      if (this.PlaceImages.length == 0) {
        $(".file_err").text(this.$i18n.t("RequestNewPlace.err5"));
        $(".choose_file label").css("border", "2px dashed rgb(250,82,82)");
        return;
      }
      if (this.WorkHours == "") {
        $("#workHours_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_3").text(this.$i18n.t("RequestNewPlace.err3"));
        return;
      }
      if (this.Address == "") {
        $("#address_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_4").text(this.$i18n.t("RequestNewPlace.err4"));
        return;
      }
      if (this.PlaceImages.length > 5) {
        $(".file_err").text("More than 5 photos");
        $(".choose_file label").css("border", "2px dashed rgb(250,82,82)");
        return;
      }
      const fd = new FormData();
      fd.append("email", this.email);
      fd.append("Name", this.PlaceName);
      fd.append("WorkHours", this.WorkHours);
      for (let i = 0; i < this.PlaceImages.length; i++) {
        fd.append("Images", this.PlaceImages[i]);
      }
      fd.append("Address", this.Address);
      fd.append("Description", this.Description);
      await axios
        .post(ApiUrl.url + "/Community/addNPlace", fd)
        .then((res) => {
          if (res.data.msg == "Request Submited") {
            $(".success_msg_1").text("Your Request is Submited");
            this.success = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: #1f2833;
  font-weight: 700;
}
.p {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  direction: rtl;
}
.nav {
  height: 200px;
  z-index: 3000;
}
.footer {
  margin-top: auto;
}
/********************* Add a new place *********************/
.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .terms {
    display: flex;
    align-items: center;
    width: 400px;
    height: 100%;
    text-align: justify;
    ul {
      margin-left: 2rem;
    }
  }
}
.addplace {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.form {
  width: 100%;
}
.input {
  padding: 20px;
  div {
    text-align: left;
  }
}
.name-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  font-size: 15px;
  color: #1f2833;
  border-bottom: 3px solid #472bff;
}
.error_msg,
.error_msg_1,
.error_msg_2,
.error_msg_3,
.error_msg_4,
.error_msg_5 {
  color: rgb(250, 82, 82);
}
.choose_file label {
  display: block;
  border: 2px dashed #472bff;
  padding: 15px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.choose_file #choose_file {
  outline: none;
  opacity: 0;
  width: 0;
}
.choose_file span {
  font-size: 14px;
  color: #472bff;
}
.choose_file label:hover span {
  text-decoration: underline;
}
.submit {
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #472bff;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.submit:hover {
  cursor: pointer;
  background-color: #3721c5;
}
@media screen and (max-width: 1000px) {
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container {
    // flex-direction: column;
    display: block;
  }
  .addplace {
    margin-top: 2rem;
  }
  .footer {
    margin-top: auto;
  }
}
@media screen and (max-width: 500px) {
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addplace {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .name-input {
    width: 265px;
  }
  .submit {
    width: 265px;
  }
}
/********************* Add a new place *********************/
</style>
