<template>
  <div>
    <!--=============== Navbar ===============-->
    <div class="navbar">
      <div class="left">
        <img src="../../assets/logo.png" width="40" height="40" />
        <span>Company Name</span>
      </div>
      <div class="right">
        <ul>
          <li class="link">
            <router-link to="/Admin">Dashboard</router-link>
          </li>
          <li class="link">
            <router-link to="/Admin/ManageCategories"
              ><i class="fa-solid fa-tags"></i> Categories</router-link
            >
          </li>
          <li class="link">
            <router-link to="/Admin/ManagePlaces"
              ><i class="fa-solid fa-location-pin"></i> Places</router-link
            >
          </li>
          <li class="link">
            <router-link to=""
              ><i class="fa-regular fa-user"></i> Users</router-link
            >
          </li>
          <li class="link">
            <button class="logout_link" v-on:click="Logout()">Logout</button>
          </li>
        </ul>
      </div>
    </div>
    <!--=============== Navbar ===============-->
    <!--=============== Image Slider ===============-->
    <div class="slider">
      <div
        class="slide"
        v-for="image in PlaceImages"
        v-bind:key="image.filename"
      >
        <img :src="`${url}/${image.filename}`" />
      </div>
    </div>
    <!--=============== Image Slider ===============-->
    <!--=============== Add Place ===============-->
    <div id="AddPlace">
      <div class="AddP">
        <div class="title-add">
          <h3>Add Place</h3>
        </div>
        <div class="add-form">
          <div class="part-one">
            <div>
              <div class="input">
                <label class="title">Name</label>
                <div>
                  <input
                    type="text"
                    name="Name"
                    autocomplete="off"
                    class="name-input"
                    v-model="PlaceName"
                    id="Place_name"
                    placeholder="Enter a Place Name"
                  />
                  <br />
                  <label for="Place_name" class="error_msg_1"></label>
                </div>
              </div>
              <div class="input">
                <label class="title">Work Hours</label>
                <div>
                  <input
                    type="text"
                    name="WorkHours"
                    autocomplete="off"
                    class="name-input"
                    v-model="WorkHours"
                    id="workHours_input"
                    placeholder="Enter Work Hours"
                  />
                  <br />
                  <label for="workHours_input" class="error_msg_2"></label>
                </div>
              </div>
              <div class="input">
                <label class="title">Address</label>
                <div>
                  <input
                    type="text"
                    name="Address"
                    autocomplete="off"
                    class="name-input"
                    v-model="Address"
                    id="address_input"
                    placeholder="Enter Address"
                  />
                  <br />
                  <label for="address_input" class="error_msg_3"></label>
                </div>
              </div>
              <div class="input">
                <label class="title">Description</label>
                <div>
                  <input
                    type="text"
                    name="Description"
                    autocomplete="off"
                    class="name-input"
                    v-model="Description"
                    id="description_input"
                    placeholder="Enter Description"
                  />
                  <br />
                  <label for="description_input" class="error_msg_4"></label>
                </div>
              </div>
              <div class="input">
                <label class="title">tags</label>
                <div>
                  <input
                    type="text"
                    name="tags"
                    autocomplete="off"
                    class="name-input"
                    v-model="tags"
                    id="tags_input"
                    placeholder="Enter Tags"
                  />
                  <br />
                  <label for="tags_input" class="error_msg_5"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="part-two">
            <div>
              <div class="input">
                <label class="title">Price Range</label>
                <div>
                  <input
                    type="text"
                    name="PriceRange"
                    autocomplete="off"
                    class="name-input"
                    v-model="PriceRange"
                    id="PriceRange_input"
                    placeholder="Choose Price Range (Low, Mid, High)"
                  />
                  <br />
                  <label for="PriceRange_input" class="error_msg_6"></label>
                </div>
              </div>
              <div class="input">
                <h4 class="selected_item">
                  Choose a Category: {{ CategoryName }}
                  <br />
                  <label class="error_msg_7"></label>
                </h4>
                <div class="drop">
                  <div class="menu">
                    <div
                      class="item"
                      v-for="category in Categories"
                      v-bind:key="category._id"
                      v-on:click="
                        getSelectedCategory(category._id, category.Name)
                      "
                    >
                      {{ category.Name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="input buttons">
                <button class="submit" v-on:click="accept()">
                  <i class="fa-solid fa-check"></i>
                </button>
                <button class="submit">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="input">
                <label id="success_msg_1" style="color: #eee"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--=============== Add Place ===============-->
  </div>
</template>

<script>
import ApiUrl from "@/ApiUrl";
import axios from "axios";
import JQuery from "jquery";
let $ = JQuery;
export default {
  data() {
    return {
      Categories: [],
      idsplace: "",
      CategoryId: "",
      CategoryName: "",
      PlaceName: "",
      PlaceImages: [],
      WorkHours: "",
      Address: "",
      Description: "",
      tags: "",
      PriceRange: "",
      url: "",
    };
  },
  async mounted() {
    this.$store.dispatch("isAdminLoggedIn");
    this.url = ApiUrl.url;
    await axios
      .get(ApiUrl.url + `/Admin/getNPlace/${this.$route.params.id}`, {
        headers: { token: localStorage.getItem("token") },
      })
      .then((res) => {
        this.idsplace = res.data._id;
        this.PlaceName = res.data.Name;
        this.PlaceImages = res.data.Images;
        this.WorkHours = res.data.WorkHours;
        this.Address = res.data.Address;
        this.Description = res.data.Description;
      })
      .catch((err) => {
        if (err) {
          location.replace("/");
        }
      });
    await this.$store.dispatch("getCategories").then(() => {
      this.Categories = this.$store.getters.getCategories;
    });
  },
  methods: {
    getSelectedCategory(CategoryId, CategoryName) {
      this.CategoryId = CategoryId;
      this.CategoryName = CategoryName;
    },
    async accept() {
      $("#Place_name").click(function () {
        window.onkeyup = function () {
          $(".error_msg_1").text("");
          $("#Place_name").css("borderBottom", "3px solid #472bff");
        };
      });
      $("#workHours_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_2").text("");
          $("#workHours_input").css("borderBottom", "3px solid #472bff");
        };
      });
      $("#address_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_3").text("");
          $("#address_input").css("borderBottom", "3px solid #472bff");
        };
      });
      $("#description_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_4").text("");
          $("#description_input").css("borderBottom", "3px solid #472bff");
        };
      });
      $("#tags_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_5").text("");
          $("#tags_input").css("borderBottom", "3px solid #472bff");
        };
      });
      $("#PriceRange_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_6").text("");
          $("#PriceRange_input").css("borderBottom", "3px solid #472bff");
        };
      });
      $(".item").click(function () {
        $(".error_msg_7").text("");
      });
      if (
        this.PlaceName == "" &&
        this.WorkHours == "" &&
        this.tags == "" &&
        this.PriceRange == "" &&
        this.Address == "" &&
        this.Description == "" &&
        this.CategoryId == ""
      ) {
        $("#Place_name").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_1").text("Please Enter a Name");

        $("#workHours_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_2").text("Please Enter Working hours");

        $("#address_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_3").text("Please Enter an Address");

        $("#description_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_4").text("Please Enter a description");

        $("#tags_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_5").text("Please Enter a multiple tags");

        $("#PriceRange_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_6").text("Please Enter Price Range");

        $(".error_msg_7").text("Please Choose a Category");
        return;
      }
      if (this.PlaceName == "") {
        $("#Place_name").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_1").text("Please Enter a Name");
        return;
      }
      if (this.WorkHours == "") {
        $("#workHours_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_2").text("Please Enter Working hours");
        return;
      }
      if (this.Address == "") {
        $("#address_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_3").text("Please Enter an Address");
        return;
      }
      if (this.Description == "") {
        $("#description_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_4").text("Please Enter a description");
        return;
      }
      if (this.tags == "") {
        $("#tags_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_5").text("Please Enter a multiple tags");
        return;
      }
      if (this.PriceRange == "") {
        $("#PriceRange_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_6").text("Please Enter Price Range");
        return;
      }
      if (this.CategoryId == "") {
        $(".error_msg_7").text("Please Choose a Category");
        return;
      }

      const images = JSON.stringify(this.PlaceImages);
      const fd = new FormData();
      fd.append("Name", this.PlaceName);
      fd.append("WorkHours", this.WorkHours);
      fd.append("Address", this.Address);
      fd.append("Description", this.Description);
      fd.append("tags", this.tags);
      fd.append("Images", images);
      fd.append("PriceRange", this.PriceRange);
      fd.append("CategoryId", this.CategoryId);
      fd.append("CategoryName", this.CategoryName);
      await axios
        .post(
          ApiUrl.url + "/Admin/AcceptNPlace",
          {
            idsplace: this.idsplace,
            Name: this.PlaceName,
            WorkHours: this.WorkHours,
            Description: this.Description,
            Address: this.Address,
            Images: images,
            tags: this.tags,
            PriceRange: this.PriceRange,
            CategoryId: this.CategoryId,
            CategoryName: this.CategoryName,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          console.log(res);
        });
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>

<style lang="scss" scoped>
/************************ Navbar ************************/
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: #1f2833;
}
.left {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  img {
    width: 40px;
    height: 40px;
    margin: 20px;
  }
  span {
    font-weight: bold;
    color: #eeeeee;
    font-size: 18px;
  }
}
.right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  ul {
    li {
      display: inline;
      margin: 30px;
      a {
        color: #eeeeee;
        font-weight: bold;
        font-size: 14px;
        margin: 20px;
        transition: all 200ms ease-in-out;
      }
      a:hover {
        color: #3721c5;
      }
    }
  }
}
.logout_link {
  background: none;
  border: none;
  outline: none;
  color: #eeeeee;
  font-weight: bold;
  font-size: 14px;
  transition: all 200ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #3721c5;
  }
}
/************************ Navbar ************************/
/************************ Image Slider ************************/
.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  .slide {
    width: 400px;
    height: 400px;
    margin: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
/************************ Image Slider ************************/
/************************ Add Place ************************/
#AddPlace {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.AddP {
  // width: 850px;
  // height: 900px;
  border-radius: 25px;
}
.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  form {
    display: flex;
    align-items: center;
    .part-one,
    .part-two {
      display: flex;
      align-items: center;
    }
  }
}
.input {
  padding: 20px;
  text-align: left;
  .title {
    color: #1f2833;
    font-weight: 700;
  }
  div {
    text-align: left;
  }
}
.name-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  font-size: 15px;
  color: #1f2833;
  font-weight: bold;
  border-bottom: 3px solid #472bff;
}
::placeholder {
  color: #1f2833;
  font-weight: 700;
}
.error_msg,
.error_msg_1,
.error_msg_2,
.error_msg_3,
.error_msg_4,
.error_msg_5,
.error_msg_6,
.error_msg_7 {
  color: rgb(250, 82, 82);
}
.selected_item {
  text-align: left;
  color: #1f2833;
}
.drop {
  width: 365px;
  height: 100px;
  color: #1f2833;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    width: 100%;
    height: 40px;
    font-weight: bold;
    transition: all 300ms ease-in-out;
  }
  .item:hover {
    cursor: pointer;
    color: #472bff;
  }
}
.buttons {
  text-align: center;
}
.submit {
  width: 50px;
  height: 50px;
  font-size: 15px;
  margin-left: 2rem;
  outline: none;
  border: none;
  border-radius: 15px;
  background-color: #472bff;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.submit:hover {
  cursor: pointer;
  background-color: #3721c5;
}
/************************ Add Place ************************/
</style>
