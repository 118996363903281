<template>
  <div>
    <div class="nav">
      <AdminNav />
    </div>
    <!--=============== Display Images ===============-->

    <!--=============== Display Images ===============-->
    <!--=============== Place Information ===============-->
    <div class="add-form">
      <form method="post" @submit.prevent="AddPlace()">
        <div class="part-one">
          <div>
            <!--================= Place Name =================-->
            <div class="input">
              <div>
                <input
                  type="text"
                  name="Name"
                  autocomplete="off"
                  class="name-input"
                  v-model="PlaceName"
                  id="Place_name"
                  placeholder="Enter a Place Name"
                />
                <br />
                <label for="Place_name" class="error_msg_1"></label>
              </div>
            </div>
            <!--================= Place Name =================-->

            <!--================= Place Images =================-->
            <div class="input">
              <div class="choose_file">
                <label for="choose_file">
                  <input
                    type="file"
                    name="Images"
                    multiple
                    ref="file"
                    @change="onFileSelected"
                    id="choose_file"
                  />
                  <span class="file_err">Choose Files</span>
                </label>
              </div>
            </div>
            <!--================= Place Images =================-->

            <!--================= Address =================-->
            <div class="input">
              <div>
                <input
                  type="text"
                  name="Address"
                  autocomplete="off"
                  class="name-input"
                  v-model="Address"
                  id="address_input"
                  placeholder="Enter Address"
                />
                <br />
                <label for="address_input" class="error_msg_3"></label>
              </div>
            </div>
            <!--================= Address =================-->

            <!--================= Description =================-->
            <div class="input">
              <div>
                <input
                  type="text"
                  name="Description"
                  autocomplete="off"
                  class="name-input"
                  v-model="Description"
                  id="description_input"
                  placeholder="Enter Description"
                />
                <br />
                <label for="description_input" class="error_msg_4"></label>
              </div>
            </div>
            <!--================= Description =================-->

            <!--================= Location =================-->
            <div class="input">
              <input
                type="text"
                name="location"
                autocomplete="off"
                class="name-input"
                placeholder="Location Link"
                id="location_input"
                v-model="location"
              />
              <br />
              <label for="location_input" class="location_input_label"></label>
            </div>
            <!--================= Location =================-->
          </div>
        </div>
        <div class="part-two">
          <div>
            <!--================= Tags =================-->
            <div class="input">
              <div>
                <input
                  type="text"
                  name="tags"
                  autocomplete="off"
                  class="name-input"
                  v-model="tags"
                  @keyup="searchTag()"
                  id="tags_input"
                  placeholder="Enter Tags"
                />
                <br />
                <label for="tags_input" class="error_msg_5"></label>
                <ul class="list_tags">
                  <li
                    v-for="tag in Tags"
                    v-bind:key="tag._id"
                    v-on:click="selectTag(tag.NameEN, tag.NameAR)"
                  >
                    {{ tag.NameEN }}
                  </li>
                </ul>
              </div>
              <div class="tags">
                <div class="tag" v-for="tag in SelectTags" :key="tag.NameEN">
                  {{ tag.NameEN }}
                  <div class="remove_tag_btn">
                    <i
                      class="fa-solid fa-xmark"
                      v-on:click="deSelectTag(tag.NameEN)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <!--================= Tags =================-->

            <!--================= Price Range =================-->
            <div class="input">
              <div>
                <input
                  type="text"
                  name="PriceRange"
                  autocomplete="off"
                  class="name-input"
                  v-model="PriceRange"
                  id="PriceRange_input"
                  placeholder="Choose Price Range (Low, Mid, High)"
                />
                <br />
                <label for="PriceRange_input" class="error_msg_6"></label>
              </div>
            </div>
            <!--================= Price Range =================-->

            <!--================= Category =================-->
            <div class="input">
              <div class="showCities" v-on:click="openDropdown()">
                Select Category: {{ CategoryName }}
              </div>
              <div class="cities_list" v-if="dropdown">
                <div
                  class="city"
                  v-for="category in Categories"
                  v-bind:key="category._id"
                  v-on:click="
                    getSelectedCategory(category._id, category.NameEN)
                  "
                >
                  {{ category.NameEN }}
                </div>
              </div>
            </div>
            <!--================= Category =================-->
          </div>
        </div>
      </form>
    </div>
    <!--=============== Place Information ===============-->
    <!--=============== Manage Working Hours ===============-->

    <div>
      <div>
        <h2>Select Working Days</h2>
        <div class="a">
          <div class="days">
            <div class="day" id="sunday" v-on:click="selectDays(1, 'sunday')">
              Sunday
            </div>
            <div class="day" id="monday" v-on:click="selectDays(2, 'monday')">
              Monday
            </div>
            <div class="day" id="tuesday" v-on:click="selectDays(3, 'tuesday')">
              Tuesday
            </div>
            <div
              class="day"
              id="wednesday"
              v-on:click="selectDays(4, 'wednesday')"
            >
              Wednesday
            </div>
            <div
              class="day"
              id="thursday"
              v-on:click="selectDays(5, 'thursday')"
            >
              Thursday
            </div>
            <div class="day" id="friday" v-on:click="selectDays(6, 'friday')">
              Friday
            </div>
            <div
              class="day"
              id="saturday"
              v-on:click="selectDays(7, 'saturday')"
            >
              Saturday
            </div>
          </div>
        </div>
        <div class="manageHours">
          <div v-if="Day1">
            <h3>Sunday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day1F"
                    id="day1_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day1_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(1, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(1, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day1T"
                    id="day1_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day1_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(1, 'am')">am</div>
                    <div v-on:click="changeToTimeType(1, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day2">
            <h3>Monday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day2F"
                    id="day2_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day2_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(2, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(2, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day2T"
                    id="day2_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day2_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(2, 'am')">am</div>
                    <div v-on:click="changeToTimeType(2, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day3">
            <h3>Tuesday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day3F"
                    id="day3_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day3_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(3, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(3, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day3T"
                    id="day3_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day3_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(3, 'am')">am</div>
                    <div v-on:click="changeToTimeType(3, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day4">
            <h3>Wednesday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day4F"
                    id="day4_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day4_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(4, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(4, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day4T"
                    id="day4_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day4_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(4, 'am')">am</div>
                    <div v-on:click="changeToTimeType(4, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day5">
            <h3>Thursday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day5F"
                    id="day5_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day5_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(5, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(5, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day5T"
                    id="day5_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day5_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(5, 'am')">am</div>
                    <div v-on:click="changeToTimeType(5, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day6">
            <h3>Friday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day6F"
                    id="day6_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day6_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(6, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(6, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day6T"
                    id="day6_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day6_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(6, 'am')">am</div>
                    <div v-on:click="changeToTimeType(6, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="Day7">
            <h3>Saturday</h3>
            <div class="time_input">
              <div class="b">
                <div>
                  <label>From : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day7F"
                    id="day7_from"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">{{ Day7_from_timeType }}</button>
                  <div class="dropdown-content">
                    <div v-on:click="changeFromTimeType(7, 'am')">am</div>
                    <div v-on:click="changeFromTimeType(7, 'pm')">pm</div>
                  </div>
                </div>
              </div>
              <div class="b">
                <div>
                  <label>To : </label>
                  <input
                    type="number"
                    class="time"
                    v-model="day7T"
                    id="day7_to"
                  />
                </div>
                <div class="dropdown">
                  <button class="dropbtn">
                    {{ Day7_to_timeType }}
                  </button>
                  <div class="dropdown-content">
                    <div v-on:click="changeToTimeType(7, 'am')">am</div>
                    <div v-on:click="changeToTimeType(7, 'pm')">pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-on:click="AddPlace()" class="submit">Add Place</button>
    <!--=============== Manage Working Hours ===============-->
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
import ApiUrl from "@/ApiUrl";
import AdminNav from "@/components/AdminNav.vue";
let $ = jquery;

export default {
  components: {
    AdminNav,
  },
  data() {
    return {
      Categories: [],
      Tags: [],
      SelectTags: [],
      CategoryId: "",
      CategoryName: "",
      PlaceName: "",
      PlaceImages: [],
      WorkHours: "",
      Address: "",
      Description: "",
      location: "",
      tags: "",
      PriceRange: "",
      Day1: false,
      Day2: false,
      Day3: false,
      Day4: false,
      Day5: false,
      Day6: false,
      Day7: false,
      Day1Hours: "",
      Day2Hours: "",
      Day3Hours: "",
      Day4Hours: "",
      Day5Hours: "",
      Day6Hours: "",
      Day7Hours: "",
      day1F: "",
      day1T: "",
      Day1_from_timeType: "am",
      Day1_to_timeType: "pm",
      day2F: "",
      day2T: "",
      Day2_from_timeType: "am",
      Day2_to_timeType: "pm",
      day3F: "",
      day3T: "",
      Day3_from_timeType: "am",
      Day3_to_timeType: "pm",
      day4F: "",
      day4T: "",
      Day4_from_timeType: "am",
      Day4_to_timeType: "pm",
      day5F: "",
      day5T: "",
      Day5_from_timeType: "am",
      Day5_to_timeType: "pm",
      day6F: "",
      day6T: "",
      Day6_from_timeType: "am",
      Day6_to_timeType: "pm",
      day7F: "",
      day7T: "",
      Day7_from_timeType: "am",
      Day7_to_timeType: "pm",
      dropdown: false,
    };
  },
  async mounted() {
    this.loadCategories();
  },
  methods: {
    async loadCategories() {
      await axios
        .get(ApiUrl.url + "/api/Categories/Get", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.Categories = res.data;
        });
    },
    onFileSelected() {
      this.PlaceImages = this.$refs.file.files;
      $(".file_err").text("Choose an Image");
      $(".choose_file label").css("border", "2px dashed #472bff");
    },
    getSelectedCategory(CategoryId, CategoryName) {
      this.CategoryId = CategoryId;
      this.CategoryName = CategoryName;
      this.dropdown = false;
    },
    selectDays(DayNum, DayName) {
      if (DayNum == 1 && this.Day1 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day1 = true;
      } else if (DayNum == 1 && this.Day1 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day1 = false;
      }

      if (DayNum == 2 && this.Day2 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day2 = true;
      } else if (DayNum == 2 && this.Day2 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day2 = false;
      }

      if (DayNum == 3 && this.Day3 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day3 = true;
      } else if (DayNum == 3 && this.Day3 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day3 = false;
      }

      if (DayNum == 4 && this.Day4 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day4 = true;
      } else if (DayNum == 4 && this.Day4 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day4 = false;
      }

      if (DayNum == 5 && this.Day5 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day5 = true;
      } else if (DayNum == 5 && this.Day5 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day5 = false;
      }

      if (DayNum == 6 && this.Day6 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day6 = true;
      } else if (DayNum == 6 && this.Day6 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day6 = false;
      }

      if (DayNum == 7 && this.Day7 != true) {
        $(`#${DayName}`).css("backgroundColor", "#472bff");
        $(`#${DayName}`).css("color", "#fff");
        this.Day7 = true;
      } else if (DayNum == 7 && this.Day7 == true) {
        $(`#${DayName}`).css("backgroundColor", "#fff");
        $(`#${DayName}`).css("color", "black");
        this.Day7 = false;
      }
    },
    changeFromTimeType(DayNum, time) {
      if (DayNum == 1) {
        this.Day1_from_timeType = time;
      } else if (DayNum == 2) {
        this.Day2_from_timeType = time;
      } else if (DayNum == 3) {
        this.Day3_from_timeType = time;
      } else if (DayNum == 4) {
        this.Day4_from_timeType = time;
      } else if (DayNum == 5) {
        this.Day5_from_timeType = time;
      } else if (DayNum == 6) {
        this.Day6_from_timeType = time;
      } else if (DayNum == 7) {
        this.Day7_from_timeType = time;
      }
    },
    changeToTimeType(DayNum, time) {
      if (DayNum == 1) {
        this.Day1_to_timeType = time;
      } else if (DayNum == 2) {
        this.Day2_to_timeType = time;
      } else if (DayNum == 3) {
        this.Day3_to_timeType = time;
      } else if (DayNum == 4) {
        this.Day4_to_timeType = time;
      } else if (DayNum == 5) {
        this.Day5_to_timeType = time;
      } else if (DayNum == 6) {
        this.Day6_to_timeType = time;
      } else if (DayNum == 7) {
        this.Day7_to_timeType = time;
      }
    },
    searchTag() {
      if (this.tags == "") {
        this.Tags = [];
      } else {
        axios
          .post(
            ApiUrl.url + "/api/Tags/Search",
            { query: this.tags },
            { headers: { token: localStorage.getItem("token") } }
          )
          .then((res) => {
            this.Tags = res.data;
          });
      }
    },
    selectTag(tagNameEN, tagNameAR) {
      this.SelectTags.push({ NameEN: tagNameEN, NameAR: tagNameAR });
      for (let i = 0; i < this.Tags.length; i++) {
        if (tagNameEN == this.Tags[i].NameEN) {
          this.Tags.splice(i, 1);
        }
      }
    },
    deSelectTag(tagNameEN) {
      for (let i = 0; i < this.SelectTags.length; i++) {
        if (tagNameEN == this.SelectTags[i].NameEN) {
          this.SelectTags.splice(i, 1);
        }
      }
    },
    openDropdown() {
      if (this.dropdown == false) {
        this.dropdown = true;
      } else {
        this.dropdown = false;
      }
    },
    async AddPlace() {
      $("#Place_name").click(function () {
        window.onkeyup = function () {
          $(".error_msg_1").text("");
          $("#Place_name").css("borderBottom", "3px solid #472bff");
        };
      });

      $("#address_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_3").text("");
          $("#address_input").css("borderBottom", "3px solid #472bff");
        };
      });

      $("#description_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_4").text("");
          $("#description_input").css("borderBottom", "3px solid #472bff");
        };
      });

      $("#location_input").click(function () {
        window.onkeyup = function () {
          $(".location_input_label").text("");
          $("#location_input").css("borderBottom", "3px solid #472bff");
        };
      });

      $("#PriceRange_input").click(function () {
        window.onkeyup = function () {
          $(".error_msg_6").text("");
          $("#PriceRange_input").css("borderBottom", "3px solid #472bff");
        };
      });
      $(".item").click(function () {
        $(".error_msg_7").text("");
      });
      if (
        this.PlaceName == "" &&
        this.PriceRange == "" &&
        this.Address == "" &&
        this.PlaceImages.length == 0 &&
        this.Description == "" &&
        this.CategoryId == ""
      ) {
        $("#Place_name").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_1").text("Please Enter a Name");

        $(".file_err").text("Please Choose a file");
        $(".choose_file label").css("border", "2px dashed rgb(250,82,82)");

        $("#address_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_3").text("Please Enter an Address");

        $("#description_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_4").text("Please Enter a description");

        $("#tags_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_5").text("Please Enter a multiple tags");

        $("#PriceRange_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_6").text("Please Enter Price Range");

        $(".error_msg_7").text("Please Choose a Category");
        return;
      }

      if (this.PlaceName == "") {
        $("#Place_name").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_1").text("Please Enter a Name");
        return;
      }

      if (this.PlaceImages.length == 0) {
        $(".file_err").text("Please Choose a file");
        $(".choose_file label").css("border", "2px dashed rgb(250,82,82)");
        return;
      }

      if (this.Address == "") {
        $("#address_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_3").text("Please Enter an Address");
        return;
      }

      if (this.Description == "") {
        $("#description_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_4").text("Please Enter a description");
        return;
      }

      if (this.PriceRange == "") {
        $("#PriceRange_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".error_msg_6").text("Please Enter Price Range");
        return;
      }

      if (this.CategoryId == "") {
        $(".error_msg_7").text("Please Choose a Category");
        return;
      }

      /*************** Day1 Inputs ***************/
      // Day 1 From Input
      $("#day1_from").click(function () {
        window.onkeydown = function () {
          $("#day1_from").css("border", "2px solid #472bff");
        };
      });

      // Day 1 To Input
      $("#day1_to").click(function () {
        window.onkeydown = function () {
          $("#day1_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day1 Inputs ***************/

      /*************** Day2 Inputs ***************/
      // Day 2 From Input
      $("#day2_from").click(function () {
        window.onkeydown = function () {
          $("#day2_from").css("border", "2px solid #472bff");
        };
      });

      // Day 2 To Input
      $("#day2_to").click(function () {
        window.onkeydown = function () {
          $("#day2_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day2 Inputs ***************/

      /*************** Day3 Inputs ***************/
      // Day 3 From Input
      $("#day3_from").click(function () {
        window.onkeydown = function () {
          $("#day3_from").css("border", "2px solid #472bff");
        };
      });

      // Day 3 To Input
      $("#day3_to").click(function () {
        window.onkeydown = function () {
          $("#day3_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day3 Inputs ***************/

      /*************** Day4 Inputs ***************/
      // Day 4 From Input
      $("#day4_from").click(function () {
        window.onkeydown = function () {
          $("#day4_from").css("border", "2px solid #472bff");
        };
      });

      // Day 4 To Input
      $("#day4_to").click(function () {
        window.onkeydown = function () {
          $("#day4_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day4 Inputs ***************/

      /*************** Day5 Inputs ***************/
      // Day 5 From Input
      $("#day5_from").click(function () {
        window.onkeydown = function () {
          $("#day5_from").css("border", "2px solid #472bff");
        };
      });

      // Day 5 To Input
      $("#day5_to").click(function () {
        window.onkeydown = function () {
          $("#day5_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day5 Inputs ***************/

      /*************** Day6 Inputs ***************/
      // Day 6 From Input
      $("#day6_from").click(function () {
        window.onkeydown = function () {
          $("#day6_from").css("border", "2px solid #472bff");
        };
      });

      // Day 6 To Input
      $("#day6_to").click(function () {
        window.onkeydown = function () {
          $("#day6_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day6 Inputs ***************/

      /*************** Day7 Inputs ***************/
      // Day 7 From Input
      $("#day7_from").click(function () {
        window.onkeydown = function () {
          $("#day7_from").css("border", "2px solid #472bff");
        };
      });

      // Day 7 To Input
      $("#day7_to").click(function () {
        window.onkeydown = function () {
          $("#day7_to").css("border", "2px solid #472bff");
        };
      });
      /*************** Day7 Inputs ***************/

      if (this.Day1 == true) {
        if (this.day1F == "" && this.day1T == "") {
          $("#day1_from").css("border", "2px solid rgb(250,82,82)");
          $("#day1_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day1F == "") {
          $("#day1_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day1T == "") {
          $("#day1_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day2 == true) {
        if (this.day2F == "" && this.day2T == "") {
          $("#day2_from").css("border", "2px solid rgb(250,82,82)");
          $("#day2_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day2F == "") {
          $("#day2_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day2T == "") {
          $("#day2_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day3 == true) {
        if (this.day3F == "" && this.day3T == "") {
          $("#day3_from").css("border", "2px solid rgb(250,82,82)");
          $("#day3_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day3F == "") {
          $("#day3_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day3T == "") {
          $("#day3_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day4 == true) {
        if (this.day4F == "" && this.day4T == "") {
          $("#day4_from").css("border", "2px solid rgb(250,82,82)");
          $("#day4_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day4F == "") {
          $("#day4_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day4T == "") {
          $("#day4_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day5 == true) {
        if (this.day5F == "" && this.day5T == "") {
          $("#day5_from").css("border", "2px solid rgb(250,82,82)");
          $("#day5_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day5F == "") {
          $("#day5_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day5T == "") {
          $("#day5_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day6 == true) {
        if (this.day6F == "" && this.day6T == "") {
          $("#day6_from").css("border", "2px solid rgb(250,82,82)");
          $("#day6_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day6F == "") {
          $("#day6_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day6T == "") {
          $("#day6_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      if (this.Day7 == true) {
        if (this.day7F == "" && this.day7T == "") {
          $("#day7_from").css("border", "2px solid rgb(250,82,82)");
          $("#day7_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day7F == "") {
          $("#day7_from").css("border", "2px solid rgb(250,82,82)");
          return;
        }
        if (this.day7T == "") {
          $("#day7_to").css("border", "2px solid rgb(250,82,82)");
          return;
        }
      }

      const fd = new FormData();
      fd.append("Name", this.PlaceName);
      fd.append("Address", this.Address);
      for (let i = 0; i < this.PlaceImages.length; i++) {
        fd.append("Images", this.PlaceImages[i]);
      }
      fd.append("Description", this.Description);
      fd.append("location", this.location);
      fd.append("tags", JSON.stringify(this.SelectTags));
      fd.append("PriceRange", this.PriceRange);
      fd.append("CategoryId", this.CategoryId);
      fd.append("CategoryName", this.CategoryName);
      fd.append("Day1", this.Day1);
      fd.append("Day2", this.Day2);
      fd.append("Day3", this.Day3);
      fd.append("Day4", this.Day4);
      fd.append("Day5", this.Day5);
      fd.append("Day6", this.Day6);
      fd.append("Day7", this.Day7);

      // Sent Day 1
      if (this.Day1) {
        fd.append(
          "Day1Hours",
          this.day1F +
            " " +
            this.Day1_from_timeType +
            " - " +
            this.day1T +
            " " +
            this.Day1_to_timeType
        );
      } else {
        fd.append("Day1Hours", "");
      }

      // Sent Day 2
      if (this.Day2) {
        fd.append(
          "Day2Hours",
          this.day2F +
            " " +
            this.Day2_from_timeType +
            " - " +
            this.day2T +
            " " +
            this.Day2_to_timeType
        );
      } else {
        fd.append("Day2Hours", "");
      }

      // Sent Day 3
      if (this.Day3) {
        fd.append(
          "Day3Hours",
          this.day3F +
            " " +
            this.Day3_from_timeType +
            " - " +
            this.day3T +
            " " +
            this.Day3_to_timeType
        );
      } else {
        fd.append("Day3Hours", "");
      }

      // Sent Day 4
      if (this.Day4) {
        fd.append(
          "Day4Hours",
          this.day4F +
            " " +
            this.Day4_from_timeType +
            " - " +
            this.day4T +
            " " +
            this.Day4_to_timeType
        );
      } else {
        fd.append("Day4Hours", "");
      }

      // Sent Day 5
      if (this.Day5) {
        fd.append(
          "Day5Hours",
          this.day5F +
            " " +
            this.Day5_from_timeType +
            " - " +
            this.day5T +
            " " +
            this.Day5_to_timeType
        );
      } else {
        fd.append("Day5Hours", "");
      }

      // Sent Day 6
      if (this.Day6) {
        fd.append(
          "Day6Hours",
          this.day6F +
            " " +
            this.Day6_from_timeType +
            " - " +
            this.day6T +
            " " +
            this.Day6_to_timeType
        );
      } else {
        fd.append("Day6Hours", "");
      }

      // Sent Day 7
      if (this.Day7) {
        fd.append(
          "Day7Hours",
          this.day7F +
            " " +
            this.Day7_from_timeType +
            " - " +
            this.day7T +
            " " +
            this.Day7_to_timeType
        );
      } else {
        fd.append("Day7Hours", "");
      }
      await axios
        .post(ApiUrl.url + "/api/Places/Add", fd, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.msg == "Place Created") {
            location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  height: 80px;
}

/************************ Place Information ************************/
.add-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 800px;
  form {
    display: flex;
    .part-one,
    .part-two {
      display: flex;
    }
  }
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 650px;
}
.input {
  padding: 20px;
  div {
    text-align: left;
  }
}
.name-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  font-size: 15px;
  color: #1f2833;
  font-weight: 600;
  border-bottom: 3px solid #472bff;
}
.list_tags {
  width: 365px;
  height: auto;
  list-style: none;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  li {
    padding: 20px;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      color: #fff;
      background-color: #472bff;
    }
  }
}
.tags {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  .tag {
    margin: 5px;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #472bff;
    border-radius: 5px;
    .remove_tag_btn {
      border: none;
      background: none;
      outline: none;
      color: #fff;
      font-size: 18px;
      transition: all 300ms ease-in-out;
      &:hover {
        cursor: pointer;
        color: rgb(250, 82, 82);
      }
    }
  }
}
::placeholder {
  color: #1f2833;
  font-weight: 700;
}
.error_msg,
.error_msg_1,
.error_msg_2,
.error_msg_3,
.error_msg_4,
.error_msg_5,
.error_msg_6,
.error_msg_7,
.location_input_label {
  color: rgb(250, 82, 82);
}

.showCities {
  width: 335px;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
  &:hover {
    cursor: pointer;
  }
}
.remove_bottom_border {
  border-bottom: none;
}
.cities_list {
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  width: 355px;
  height: 90px;
  overflow: auto;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}
.city {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #eeeeee;
    background-color: #472bff;
  }
}
.city:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.selected_item {
  text-align: left;
  color: #1f2833;
}

.choose_file label {
  display: block;
  border: 2px dashed #472bff;
  padding: 15px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.choose_file #choose_file {
  outline: none;
  opacity: 0;
  width: 0;
}
.choose_file span {
  font-size: 14px;
  color: #472bff;
}
.choose_file label:hover span {
  text-decoration: underline;
}
.submit {
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #472bff;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.submit:hover {
  cursor: pointer;
  background-color: #3721c5;
}
/************************ Place Information ************************/
/************************ Manage Working Hours ************************/
.a {
  display: flex;
  justify-content: center;
}
.days {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
}
.day {
  width: 100px;
  height: 40px;
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: 600;
  transition: all 300ms ease;
}
.day:hover {
  cursor: pointer;
}
.time_input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.b {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}
.time {
  width: 70px;
  height: 40px;
  background: none;
  border: none;
  outline: none;
  background-color: #fff;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  font-size: 17px;
  border-radius: 10px;
  border: 2px solid #472bff;
  transition: all 300ms ease-in-out;
}

.dropbtn {
  background-color: #fff;
  color: #1f2833;
  width: 50px;
  height: 50px;
  font-size: 16px;
  border: none;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: all 300ms ease-in-out;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.dropdown-content div:hover {
  cursor: pointer;
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: #fff;
  background-color: #472bff;
}
/************************ Manage Working Hours ************************/
</style>
